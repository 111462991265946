import { DrawerContent, DrawerFooter, DrawerHeader, DrawerStatic, Stack } from '@components/ui';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UndoIcon from '@material-ui/icons/Undo';
import { ChangeEvent, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CacheKeys } from 'src/constants';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import useRole from 'src/hooks/useRole';
import { BidTypes } from '../types';
import { getBidsFilter, getFilter } from './filter';
import { CarsTableNextFilterData } from './types';
import { useBidsFilter } from './useBidsFilter';

export type FilterInstance = UseFormReturn<CarsTableNextFilterData>;

export const FilterGroup = withStyles({
  root: {
    borderRadius: 4,

    '&.Mui-expanded': {
      margin: 0,
    },

    '&::before': {
      content: 'none',
    },

    '& [data-subgroup-id="other"] + [data-subgroup-id="other"]': {
      marginBlockStart: -8,
    },
    '& [data-subgroup-id="offerDetails"] + [data-subgroup-id="offerDetails"]': {
      marginBlockStart: -8,
    },
    '& [data-subgroup-id="date"] + [data-subgroup-id="date"]': {
      marginBlockStart: -8,
    },
  },
})(Accordion);

type BidsTableNextFilterProps = {
  bidType: BidTypes;
};

export const BidsTableNextFilter = ({ bidType }: BidsTableNextFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const { hasRole } = useRole();
  const { reset, control, onFieldChange } = useBidsFilter();
  const filterName = bidType === BidTypes.Orders ? 'boughtTimeFilter' : 'biddingTimeFilter';
  const filter = useMemo(() => [...getBidsFilter(filterName), ...getFilter('carsRequest.')], [filterName]);

  const [expanded, setExpanded] = useLocalStorage<string[]>(CacheKeys.carsTableNextFilterExpanded, []);

  const handleExpand = (panel: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
    if (newExpanded) {
      setExpanded((prev) => [...prev, panel]);
    } else {
      setExpanded((prev) => prev.filter((p) => p !== panel));
    }
  };

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              fontWeight: 'bold',
            }}
          >
            {t('carsTableNext.carsTableNextFilter.headline')}
          </Typography>
        </Grid>
      </DrawerHeader>
      <DrawerContent>
        <Stack spacing={3}>
          {filter
            .filter((filterGroup) => !['calculation', 'damages'].includes(filterGroup.groupKey))
            .map((filterGroup) => (
              <FilterGroup
                key={filterGroup.groupKey}
                expanded={expanded.includes(filterGroup.groupKey)}
                onChange={handleExpand(filterGroup.groupKey)}
              >
                <AccordionSummary aria-controls="test-content" id="test-header" expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {t(filterGroup.label as any)}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  <Stack spacing={3}>
                    {filterGroup.filter
                      .filter(({ roles }) => roles === undefined || roles.every((role) => hasRole(role)))
                      .filter(({ onlyCarsTable }) => !onlyCarsTable)
                      .filter(({ onlyForTables }) => !onlyForTables || onlyForTables.includes(bidType))
                      .map(({ filter: Filter, label, name, subgroupId }) => (
                        <Controller
                          control={control}
                          data-subgroup-id={subgroupId}
                          key={name}
                          name={name as any}
                          render={({ field: { ref: _ref, ...field } }) => (
                            <Filter
                              control={control}
                              field={{
                                ...field,
                                label,
                                ref: () => { },
                                onChange: (newValue) => onFieldChange({ ...field, ref: _ref }, newValue),
                              }}
                            />
                          )}
                        />
                      ))}
                  </Stack>
                </AccordionDetails>
              </FilterGroup>
            ))}
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Button startIcon={<UndoIcon />} fullWidth size="small" color="secondary" variant="contained" onClick={reset}>
          {t('carsTableNext.carsTableNextFilter.buttonReset.label')}
        </Button>
      </DrawerFooter>
    </DrawerStatic>
  );
};
