import CurrencySliderInput from '@components/CurrencySliderInput';
import FilterAutocomplete from '@components/FilterAutocomplete';
import PurchaseGrossSliderInput from '@components/PurchaseGrossSliderInput';
import RegistrationDatePicker from '@components/RegistrationDatePicker';
import { useBidsWatchSummary, useWatchSummary } from '@components/cars-table-next/filter/useWatchSummary';
import { InputLabel, Select } from '@components/ui';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import GavelIcon from '@material-ui/icons/Gavel';
import { Rating } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { sortBy } from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import useRole from 'src/hooks/useRole';
import { getSourceOptions, useSources } from 'src/hooks/useSources';
import { COUNTRY_ORIGIN_FILTER_COUNTRIES, IconType, getIcon, homeBrands } from 'src/modules/data';
import { DATE_TIME_FORMAT_NO_TIME, formatDate } from 'src/modules/date-helpers';
import { BidVisibility, DateFilter, NormalizedFuel } from 'src/modules/generated/api';
import { tr } from 'src/modules/i18n-helpers';
import {
  bidSuccessStatusTypes,
  buyableStatusV2,
  colors,
  countryCodeToOptions,
  engineFuels,
  equipments,
  getBidStatusTypes,
  getBidStatusTypesExpired,
  offerTypesV2,
  promotions,
  sourceOriginFilter,
  sources,
  taxationTypes,
  transmissions,
  validityTypesV2,
} from 'src/modules/labels';
import { capitalizeThreeOrLessChars, capitalizeWords, formatModelNames } from 'src/modules/string-helpers';
import i18n from 'src/setup/i18n';
import UserRole from 'src/types/UserRoles';
import { bidStatusTypesV2, getLabel, marketingChannel, validityTypes } from '../../../modules/labels';
import { DateTimeFilterSelect } from '../legacy/DateTimeFilterSelect';
import PotentialFilter from '../legacy/PotentialFilter';
import { BidTypes } from '../types';
import { AmountSliderChip } from './AmountSliderChip';
import ArrayFilterChip from './ArrayFilterChip';
import DateFilterChip from './DateFilterChip';
import FilterChip from './FilterChip';
import PotentialFilterChip from './PotentialFilterChip';
import RadioFilterChip from './RadioFilterChip';
import { Search } from './Search';
import SearchFilterChip from './SearchFilterChip';
import SliderFilterChip from './SliderFilterChip';
import TimeRangeFilterChip from './TimeRangeFilterChip';
import { Autocomplete } from './common/Autocomplete';
import { CheckboxGroup } from './common/CheckboxGroup';
import { RangeSlider } from './common/RangeSlider';
import { inputLabelProps, muiInputLabelProps } from './constants';
import { FilterDef } from './types';
import { useBidsFilterSummary, useFilterSummary } from './useFilterSummary';

enum Subgroup {
  offerDetails = 'offerDetails',
  other = 'other',
  date = 'date',
}

const colorsToHex = {
  BG: '#FAF0E6',
  BL: '#4682B4',
  BR: '#A0522D',
  GE: '#FFD700',
  GN: '#008000',
  GO: '#FFD700',
  GR: '#808080',
  OR: '#FF8C00',
  RT: '#B22222',
  SI: '#C0C0C0',
  SW: '#000000',
  WS: '#D8D8D8',
};

const colorsWithStyle = colors.map((color) => ({
  ...color,
  options: {
    style: { color: colorsToHex[color.value as keyof typeof colorsToHex] },
  },
}));

export const sortedCountryOptions = countryCodeToOptions(COUNTRY_ORIGIN_FILTER_COUNTRIES || [], i18n.language).sort(
  (a, b) => a.label.localeCompare(b.label),
);

const sortBrands = (brands: string[]): string[] => {
  const externalBrands = brands.filter((brand) => !homeBrands.includes(brand));
  externalBrands.sort((a, b) => a.localeCompare(b));
  const availableHomeBrands = homeBrands.filter((brand) => brands.includes(brand));
  return [...availableHomeBrands, ...externalBrands];
};

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: '4px',
    },
    '& .MuiRadio-root': {
      padding: '4px',
    },
  },
  datePicker: {
    marginTop: 0,
  },
  hidden: {
    display: 'none',
  },
}));

export const getBidsFilter = (
  timeFilterName: 'biddingTimeFilter' | 'boughtTimeFilter',
): {
  groupKey: string;
  label: string;
  filter: FilterDef[];
}[] => [
    {
      groupKey: 'bids',
      label: 'carsTableNext.filter.groups.bids',
      filter: [
        {
          name: `bidVisibility`,
          label: 'bids.creator',
          filter: ({ control }) => {
            const { hasRole } = useRole();
            const { t } = useTranslation();
            const classes = useStyles();
            return (
              <FormControl component="fieldset" margin="dense" fullWidth>
                <FormLabel component="legend">{t('bids.creator')}</FormLabel>
                <Controller
                  name="bidVisibility"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup className={classes.formGroup} {...field}>
                      {[
                        { value: BidVisibility.User, label: t('bids.user') },
                        { value: BidVisibility.Dealer, label: t('bids.retailer') },
                        ...(hasRole(UserRole.ADMIN)
                          ? [{ value: BidVisibility.Country, label: t('contact.country') }]
                          : []),
                        ...(hasRole(UserRole.ADMIN) ? [{ value: BidVisibility.All, label: t('common.all') }] : []),
                      ].map(({ value: itemValue, label }) => (
                        <FormControlLabel key={itemValue} value={itemValue} control={<Radio />} label={label} />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            );
          },
          renderFilterChip: ({ field: { name, label, value } }) => (
            <RadioFilterChip name={name} label={label} value={value} />
          ),
        },
        {
          name: 'bidCreatorDealerCountry',
          label: `carsTableNext.filter.bidCreatorDealerCountry`,
          filter: ({ field: { ref, ...field } }) => {
            const classes = useStyles();
            const bidVisibility = useWatch({ name: 'bidVisibility' });
            const summaryState = useBidsWatchSummary();
            const { data: filterSummary, isLoading } = useBidsFilterSummary({ summaryState });
            const countryOptions = filterSummary?.bidCreatorDealerCountry || [];
            const labeledCountries = countryCodeToOptions(countryOptions, i18n.language).sort((a, b) =>
              a.label.localeCompare(b.label),
            );
            return (
              <Autocomplete
                inputRef={ref}
                {...field}
                multiple
                options={labeledCountries || []}
                loading={isLoading}
                className={clsx(bidVisibility !== BidVisibility.Country && classes.hidden)}
                optionIcon={(option) => getIcon(IconType.COUNTRY, option)}
                zoomAvatarImage={4}
              />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={sortedCountryOptions} />
          ),
          roles: [UserRole.ADMIN],
        },
        {
          name: 'bidCreatorDealerId',
          label: `carsTableNext.filter.bidCreatorDealerId`,
          filter: ({ field: { ref, ...field } }) => {
            const classes = useStyles();
            const bidVisibility = useWatch({ name: 'bidVisibility' });
            const summaryState = useBidsWatchSummary();
            const { data: filterSummary, isLoading } = useBidsFilterSummary({ summaryState });
            const dealerIdOptions = filterSummary?.bidCreatorDealerId ?? [];
            // transform dealerIdOptions in an Array with {label, value} format to avoid problems with the Autocomplete component
            const transformedDealerId = dealerIdOptions.map((key) => ({ label: key, value: key }));
            transformedDealerId.sort((a, b) => a.label.localeCompare(b.label));

            return (
              <Autocomplete
                inputRef={ref}
                {...field}
                multiple
                options={transformedDealerId || []}
                loading={isLoading}
                className={clsx(bidVisibility !== BidVisibility.Dealer && classes.hidden)}
              />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} />
          ),
          roles: [UserRole.ADMIN],
        },
        {
          name: 'bidCreatorUserId',
          label: `carsTableNext.filter.bidCreatorUserId`,
          filter: ({ field: { ref, ...field } }) => {
            const classes = useStyles();
            const bidVisibility = useWatch({ name: 'bidVisibility' });
            const summaryState = useBidsWatchSummary();
            const { data: filterSummary, isLoading } = useBidsFilterSummary({ summaryState });
            const userOptions = filterSummary?.bidCreatorUserId ?? {};
            // transform userOptions in an Array with {label, value} format
            const transformedUser = Object.keys(userOptions).map((key) => ({ label: userOptions[key], value: key }));
            transformedUser.sort((a, b) => a.label.localeCompare(b.label));
            return (
              <Autocomplete
                inputRef={ref}
                {...field}
                multiple
                options={transformedUser || []}
                loading={isLoading}
                className={clsx(bidVisibility !== BidVisibility.User && classes.hidden)}
              />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => {
            const summaryState = useBidsWatchSummary();
            const { data: filterSummary } = useBidsFilterSummary({ summaryState });
            const userOptions = filterSummary?.bidCreatorUserId ?? {};
            const transformedUser = Object.keys(userOptions).map((key) => ({ label: userOptions[key], value: key }));
            return <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={transformedUser} />;
          },
          roles: [UserRole.ADMIN],
        },
        {
          name: timeFilterName,
          label: `carsTableNext.filter.${timeFilterName}`,
          filter: () => {
            const classes = useStyles();
            const { t } = useTranslation();
            const { watch, getValues } = useFormContext();
            const name = `${timeFilterName}.timeType`;
            const type = watch(name);
            return (
              <FormControl component="fieldset" margin="dense" fullWidth>
                <Controller
                  name={`${timeFilterName}.timeType`}
                  render={({ field: { onChange, ...field } }) => (
                    <TextField
                      {...field}
                      onChange={onChange}
                      className={classes.datePicker}
                      label={t(`carsTableNext.export.choosePeriod-${timeFilterName}`)}
                      select
                      margin="dense"
                      variant="outlined"
                      defaultValue={DateFilter.All}
                    >
                      <MenuItem value={DateFilter.All}>{t('common.all')}</MenuItem>
                      <MenuItem value={DateFilter.ThisMonth}>{t('carsTable.thisMonth')}</MenuItem>
                      <MenuItem value={DateFilter.LastMonth}>{t('carsTable.lastMonth')}</MenuItem>
                      <MenuItem value={DateFilter.Individual}>{t('configuration.individual')}</MenuItem>
                    </TextField>
                  )}
                />

                {type === DateFilter.Individual && (
                  <>
                    <Controller
                      name={`${timeFilterName}.timeFrom`}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <KeyboardDatePicker
                          {...field}
                          className={classes.datePicker}
                          format={DATE_TIME_FORMAT_NO_TIME}
                          invalidDateMessage={t('carsTable.formError.dateTimeError')}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{t('common.from')}</InputAdornment>,
                          }}
                          inputRef={ref}
                          inputVariant="outlined"
                          margin="dense"
                          openTo="date"
                          placeholder="TT.MM.JJ"
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={`${timeFilterName}.timeTo`}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <KeyboardDatePicker
                          {...field}
                          className={classes.datePicker}
                          format={DATE_TIME_FORMAT_NO_TIME}
                          invalidDateMessage={t('carsTable.formError.dateTimeError')}
                          minDateMessage={t('carsTable.formError.minDateError')}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{t('common.to')}</InputAdornment>,
                          }}
                          inputRef={ref}
                          inputVariant="outlined"
                          margin="dense"
                          openTo="date"
                          placeholder="TT.MM.JJ"
                          onChange={(value) => {
                            onChange(value);
                          }}
                          minDate={getValues(`${timeFilterName}From`)}
                        />
                      )}
                    />
                  </>
                )}
              </FormControl>
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <TimeRangeFilterChip name={name} value={value} label={label} timeFilterName={timeFilterName} />
          ),
          subgroupId: Subgroup.date,
        },
        {
          name: `bidSuccessStates`,
          label: 'carsTableNext.filter.bidSuccessStates',
          filter: ({ field }) => (
            <FilterAutocomplete
              {...field}
              label={tr('bids.bidStatus')}
              multiple
              options={bidSuccessStatusTypes}
              disableClearable
            />
          ),
          renderFilterChip: ({ field: { name, label, value } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={bidSuccessStatusTypes} />
          ),
          onlyForTables: [BidTypes.Past],
        },
        {
          name: `showOnlyBidsWithAutomation`,
          label: 'carsTableNext.filter.bidsWithAutomation',
          filter: ({ field: { label, value, onChange } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} onChange={(event) => onChange(event.target.checked)} />}
              label={tr(label)}
            />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip label={tr(label)} name={name} value={value} tooltip={tr("carsTableNext.filter.automaticReplacementActive")} />
          ),
          onlyForTables: [BidTypes.Past, BidTypes.Pending],
          roles: [UserRole.ADMIN],
        },
      ],
    },
  ];

// FIXME: typings
export const getFilter = (
  prefix: string = '',
): {
  groupKey: string;
  label: string;
  filter: FilterDef[];
}[] => [
    {
      groupKey: 'general',
      label: 'carsTableNext.filter.groups.general',
      filter: [
        {
          name: `${prefix}search`,
          label: 'carsTableNext.filter.search.label',
          filter: ({ field: { ref, value, onChange, label, name, onBlur } }) => (
            <Search value={value} onChange={onChange} TextFieldProps={{ inputRef: ref, label, name, onBlur }} />
          ),
          renderFilterChip: ({ field: { value, name } }) => (
            <SearchFilterChip name={name} searchObj={value} prefix={prefix} />
          ),
        },
        {
          name: `${prefix}source`,
          label: 'carsTableNext.filter.source',
          filter: ({ field }) => {
            const { data: source = [] } = useSources();
            const sourceOptions = getSourceOptions(source);
            return <Autocomplete {...field} multiple options={sourceOptions} />;
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={sources} />
          ),
        },
        {
          name: `${prefix}promotions`,
          label: 'carsTableNext.filter.promotions',
          filter: ({ field: { ref, ...field } }) => {
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary, isLoading } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const promotionOptions = promotions.filter((promo) => filterSummary?.promotions?.includes(promo.value));

            return (
              <Autocomplete
                inputRef={ref}
                {...field}
                multiple
                options={promotionOptions}
                loading={isLoading}
                optionIcon={(option) => getIcon(IconType.PROMOTION, option)}
                zoomAvatarImage={4}
              />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={promotions} />
          ),
        },
        {
          name: `${prefix}marketingChannel`,
          label: 'carsTableNext.filter.marketingChannel',
          filter: ({ field: { label, value, onChange } }) => (
            <CheckboxGroup label={label} value={value} onChange={onChange} options={marketingChannel} />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={marketingChannel} />
          ),
        },
        {
          name: 'startDateFilter',
          label: 'carsTableNext.filter.dateTime',
          filter: ({ control }) => <DateTimeFilterSelect dateType="start" control={control} />,
          renderFilterChip: ({ field: { name, value } }) => <DateFilterChip dateType="start" name={name} value={value} />,
          subgroupId: Subgroup.date,
          onlyCarsTable: true,
        },
        {
          name: 'endDateFilter',
          label: 'carsTableNext.filter.dateTime',
          filter: ({ control }) => <DateTimeFilterSelect dateType="end" control={control} />,
          renderFilterChip: ({ field: { name, value } }) => <DateFilterChip dateType="end" name={name} value={value} />,
          subgroupId: Subgroup.date,
          onlyCarsTable: true,
        },
        {
          name: 'validity',
          label: 'carsTableNext.filter.validity',
          filter: ({ field: { ref, ...field } }) => (
            <Select inputRef={ref} {...field} options={validityTypesV2} InputLabelProps={muiInputLabelProps} clearable />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip name={name} label={getLabel(validityTypes, value)} tooltip={tr(label)} resetEffect="undefined" />
          ),
          subgroupId: Subgroup.offerDetails,
          roles: [UserRole.ADMIN],
          onlyCarsTable: true,
        },
        {
          name: `${prefix}offerType`,
          label: 'carsTableNext.filter.offerType',
          filter: ({ field: { ref, ...field } }) => (
            <Select inputRef={ref} {...field} options={offerTypesV2} InputLabelProps={muiInputLabelProps} clearable />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip
              name={name}
              label={getLabel(offerTypesV2, value)}
              tooltip={tr(`${label}`)}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.offerDetails,
          roles: [UserRole.ADMIN],
        },
        {
          name: 'currentBidState',
          label: 'carsTableNext.filter.currentBidState',
          filter: ({ field: { ref, ...field } }) => {
            const { getRoles } = useRole();
            return (
              <Select
                inputRef={ref}
                {...field}
                options={getBidStatusTypes(getRoles())}
                InputLabelProps={muiInputLabelProps}
                clearable
              />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`${label}`)}
              label={getLabel(bidStatusTypesV2, value)}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.offerDetails,
          onlyCarsTable: true,
        },
        {
          name: 'expiredBidState',
          label: 'carsTableNext.filter.expiredBidState',
          filter: ({ field: { ref, ...field } }) => {
            const { getRoles } = useRole();
            return (
              <Select
                inputRef={ref}
                {...field}
                options={getBidStatusTypesExpired(getRoles())}
                InputLabelProps={muiInputLabelProps}
                clearable
              />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`${label}`)}
              label={getLabel(bidStatusTypesV2, value)}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.offerDetails,
          onlyCarsTable: true,
        },
        {
          name: 'buyableStatus',
          label: 'carsTableNext.filter.buyableStatus',
          filter: ({ field: { ref, ...field } }) => (
            <Select inputRef={ref} {...field} options={buyableStatusV2} InputLabelProps={muiInputLabelProps} clearable />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`${label}`)}
              label={getLabel(buyableStatusV2, value)}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.offerDetails,
          roles: [UserRole.ADMIN],
          onlyCarsTable: true,
        },
        {
          name: `${prefix}ratingMin`,
          label: 'carsTableNext.filter.ratingMin',
          filter: ({ field: { ref, value, onChange, ...field } }) => (
            <FormControl>
              <InputLabel style={inputLabelProps.style}>
                <Trans i18nKey={field.label as any} />
              </InputLabel>
              <Rating
                innerRef={ref}
                value={parseInt(value as string, 10)}
                onChange={(_, changeValue) => onChange(changeValue ?? 0)}
                {...field}
              />
            </FormControl>
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`${label}`)}
              label={value}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.other,
          onlyCarsTable: true,
        },
        {
          name: `${prefix}anyOtherUserRatingMin`,
          label: 'carsTableNext.filter.anyOtherUserRatingMin',
          filter: ({ field: { ref, value, onChange, ...field } }) => (
            <FormControl>
              <InputLabel style={inputLabelProps.style}>
                <Trans i18nKey={field.label as any} />
              </InputLabel>
              <Rating
                innerRef={ref}
                value={parseInt(value as string, 10)}
                onChange={(_, changeValue) => onChange(changeValue ?? 0)}
                {...field}
              />
            </FormControl>
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`${label}`)}
              label={value}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.other,
          roles: [UserRole.ADMIN],
          onlyCarsTable: true,
        },
        {
          name: `${prefix}hasUserComment`,
          label: 'carsTableNext.filter.hasUserComment.label',
          filter: ({ field: { ref, value, onChange, ...field } }) => (
            <FormControl>
              <InputLabel style={inputLabelProps.style}>
                <Trans i18nKey={field.label as any} />
              </InputLabel>
              <FormControlLabel
                style={{
                  marginBlock: -9,
                }}
                control={
                  <Switch
                    inputRef={ref}
                    checked={value}
                    {...field}
                    onChange={(event) => onChange(event.target.checked)}
                  />
                }
                label={<Trans i18nKey={'carsTableNext.filter.hasUserComment.hasUserComment' as any} />}
              />
            </FormControl>
          ),
          renderFilterChip: ({ field: { name, value } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`carsTableNext.filter.hasUserComment.${name}`)}
              label={tr(`carsTableNext.filter.hasUserComment.${name}`)}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.other,
          onlyCarsTable: true,
        },
        {
          name: `${prefix}hasAnyOtherUserComment`,
          label: '',
          filter: ({ field: { ref, value, onChange, ...field } }) => (
            <FormControl>
              <FormControlLabel
                style={{
                  marginBlock: -9,
                }}
                control={
                  <Switch
                    inputRef={ref}
                    checked={value}
                    {...field}
                    onChange={(event) => onChange(event.target.checked)}
                  />
                }
                label={<Trans i18nKey={'carsTableNext.filter.hasUserComment.hasAnyOtherUserComment' as any} />}
              />
            </FormControl>
          ),
          renderFilterChip: ({ field: { name, value } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`carsTableNext.filter.hasUserComment.${name}`)}
              label={tr(`carsTableNext.filter.hasUserComment.${name}`)}
              resetEffect="undefined"
            />
          ),
          subgroupId: Subgroup.other,
          roles: [UserRole.ADMIN],
          onlyCarsTable: true,
        },
      ],
    },
    {
      groupKey: 'car',
      label: 'carsTableNext.filter.groups.car',
      filter: [
        {
          name: `${prefix}registrationDate`,
          label: 'carsTableNext.filter.registrationDate',
          filter: () => <RegistrationDatePicker prefix={prefix} />,
          renderFilterChip: ({ field: { name, value, label } }) => (
            <SliderFilterChip
              name={name}
              value={value}
              label={label}
              format={([start, end]) => `${formatDate(start, 'MM/yy')} - ${formatDate(end, 'MM/yy')}`}
              prefix={prefix}
            />
          ),
        },
        {
          name: `${prefix}mileage`,
          label: 'carsTableNext.filter.mileage',
          filter: ({ field: { ref, ...field } }) => (
            <RangeSlider innerRef={ref} {...field} step={1000} min={0} max={250_000} unit="km" />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <SliderFilterChip name={name} value={value} label={label} number unit="km" prefix={prefix} />
          ),
        },
        {
          name: `${prefix}numAuctions`,
          label: 'carsTableNext.filter.numAuctions',
          filter: ({ field: { ref, ...field } }) => (
            <RangeSlider
              innerRef={ref}
              {...field}
              step={1}
              min={0}
              max={50}
              unit={<GavelIcon style={{ color: '#656a6c' }} fontSize="small" />}
            />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <SliderFilterChip name={name} value={value} label={label} prefix={prefix} />
          ),
        },
        {
          name: `${prefix}countryOrigin`,
          label: 'carsTableNext.filter.countryOrigin',
          filter: ({ field: { ref, ...field } }) => (
            <Autocomplete
              inputRef={ref}
              {...field}
              multiple
              options={sortedCountryOptions}
              optionIcon={(option) => getIcon(IconType.COUNTRY, option)}
              zoomAvatarImage={4}
            />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={sortedCountryOptions} />
          ),
        },
        {
          name: `${prefix}originSource`,
          label: 'carsTableNext.filter.originSource',
          filter: ({ field }) => <Autocomplete {...field} multiple options={sourceOriginFilter} />,
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={sourceOriginFilter} />
          ),
        },
        {
          name: `${prefix}taxType`,
          label: 'carsTableNext.filter.taxType',
          filter: ({ field: { onChange, value, label } }) => (
            <CheckboxGroup label={label} value={value} onChange={onChange} options={taxationTypes} />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={taxationTypes} />
          ),
        },
      ],
    },
    {
      groupKey: 'brandAndModel',
      label: 'carsTableNext.filter.groups.brandAndModel',
      filter: [
        {
          name: `${prefix}normalizedBrand`,
          label: 'carsTableNext.filter.normalizedBrand',
          filter: ({ field: { ref, ...field } }) => {
            const { t } = useTranslation();
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary, isLoading } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const brandOptions = sortBrands(filterSummary?.normalizedBrand ?? []).map((value) => ({
              label: i18n.exists(`brands.${value}`)
                ? t(`brands.${value}` as any)
                : capitalizeThreeOrLessChars(capitalizeWords(value).split(' ')),
              value,
            }));
            return <Autocomplete inputRef={ref} {...field} multiple options={brandOptions} loading={isLoading} />;
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip
              name={name}
              value={value}
              tooltip={label}
              labelFunction={(entry) =>
                i18n.exists(`brands.${entry}`)
                  ? tr(`brands.${entry}` as any)
                  : capitalizeThreeOrLessChars(capitalizeWords(entry as string).split(' '))
              }
            />
          ),
        },
        {
          name: `${prefix}normalizedModel`,
          label: 'carsTableNext.filter.normalizedModel',
          filter: ({ field: { ref, ...field } }) => {
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary, isLoading } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const modelOptions = filterSummary?.normalizedModel?.map((value) => ({
              label: formatModelNames(value),
              value,
            }));
            const sortedModelOptions = modelOptions?.sort((a, b) => a.label.localeCompare(b.label));
            return <Autocomplete inputRef={ref} {...field} multiple options={sortedModelOptions ?? []} loading={isLoading} />;
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip
              name={name}
              value={value}
              tooltip={label}
              labelFunction={(entry) => formatModelNames(entry as string)}
            />
          ),
        },
        {
          name: `${prefix}normalizedEquipmentLine`,
          label: 'carsTableNext.filter.normalizedEquipmentLine',
          filter: ({ field: { ref, ...field } }) => {
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary, isLoading } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const equipmentLineOptions = filterSummary?.normalizedEquipmentLine?.map((value) => ({
              label: capitalizeThreeOrLessChars(capitalizeWords(value).split(' ')),
              value,
            }));
            const sortedEquipmentLineOptions = sortBy(equipmentLineOptions, 'label');
            return (
              <Autocomplete inputRef={ref} {...field} multiple options={sortedEquipmentLineOptions ?? []} loading={isLoading} />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} formatFunction={formatModelNames} />
          ),
        },
      ],
    },
    {
      groupKey: 'engine',
      label: 'carsTableNext.filter.groups.engine',
      filter: [
        {
          name: `${prefix}powerKw`,
          label: 'carsTableNext.filter.powerKw',
          filter: ({ field: { ref, ...field } }) => (
            <RangeSlider innerRef={ref} {...field} step={10} min={0} max={500} unit="kW" />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <SliderFilterChip name={name} label={label} value={value} number unit="KW" prefix={prefix} />
          ),
        },
        {
          name: `${prefix}normalizedFuel`,
          label: 'carsTableNext.filter.normalizedFuel',
          filter: ({ field: { ref, ...field } }) => {
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary, isLoading } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const options = engineFuels.filter((fuelOption) =>
              filterSummary?.normalizedFuel?.includes(fuelOption.value as NormalizedFuel),
            );
            return <Autocomplete inputRef={ref} {...field} multiple options={options} loading={isLoading} />;
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={engineFuels} />
          ),
        },
        {
          name: `${prefix}normalizedTransmission`,
          label: 'carsTableNext.filter.normalizedTransmission',
          filter: ({ field: { ref, ...field } }) => (
            <Select inputRef={ref} {...field} options={transmissions} InputLabelProps={muiInputLabelProps} clearable />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <FilterChip
              name={name}
              key={`${name}-${value}`}
              tooltip={tr(`${label}`)}
              label={getLabel(transmissions, value)}
              resetEffect="undefined"
            />
          ),
        },
        {
          name: `${prefix}normalizedVersion`,
          label: 'carsTableNext.filter.normalizedVersion',
          filter: ({ field: { ref, ...field } }) => {
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary, isLoading } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const versionOptions = filterSummary?.normalizedVersion?.map((value) => ({ label: value, value }));
            const sortedVersionOptions = versionOptions?.sort((a, b) => a.label.localeCompare(b.label));

            return <Autocomplete inputRef={ref} {...field} multiple options={sortedVersionOptions ?? []} loading={isLoading} />;
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} />
          ),
        },
      ],
    },
    {
      groupKey: 'equipment',
      label: 'carsTableNext.filter.groups.equipment',
      filter: [
        {
          name: `${prefix}equipments`,
          label: 'carsTableNext.filter.equipments',
          filter: ({ field: { ref, ...field } }) => (
            <Autocomplete
              inputRef={ref}
              {...field}
              multiple
              options={equipments}
              optionIcon={(option) => getIcon(IconType.EQUIPMENT, option)}
              zoomAvatarImage={4}
            />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={equipments} />
          ),
        },
        {
          name: `${prefix}equipmentCodes`,
          label: 'carsTableNext.filter.equipmentCodes',
          filter: ({ field: { ref, ...field } }) => {
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary, isLoading } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const equipmentOptions = filterSummary?.equipmentCodeText?.map(({ code, text }) => ({
              value: code || '',
              label: `${code} - ${text}`,
            }));

            return (
              <Autocomplete inputRef={ref} {...field} multiple options={equipmentOptions ?? []} loading={isLoading} />
            );
          },
          renderFilterChip: ({ field: { name, value, label } }) => {
            const summaryState = prefix ? useBidsWatchSummary() : useWatchSummary();
            const { data: filterSummary } = prefix
              ? useBidsFilterSummary({ summaryState })
              : useFilterSummary({ summaryState });
            const equipmentCodes = filterSummary?.equipmentCodeText?.map(({ code, text }) => ({
              value: code || '',
              label: `${code} - ${text}`,
            }));
            return (
              <>
                {[...value].map((entry: string) => (
                  <FilterChip
                    name={name}
                    key={`${name}-${entry}`}
                    tooltip={tr(`${label}`)}
                    label={
                      equipmentCodes?.find(({ value: codeValue }) => codeValue === entry)?.label || (entry as string)
                    }
                    resetEffect="remove"
                    value={entry}
                  />
                ))}
              </>
            );
          },
        },
        {
          name: `${prefix}colors`,
          label: 'carsTableNext.filter.colors',
          filter: ({ field: { onChange, value, label } }) => (
            <CheckboxGroup label={label} value={value} onChange={onChange} options={colorsWithStyle} columns={2} />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <ArrayFilterChip name={name} value={value} tooltip={label} labelObj={colors} />
          ),
        },
      ],
    },
    {
      groupKey: 'damages',
      label: 'carsTableNext.filter.groups.damages',
      filter: [
        {
          name: `${prefix}priceDamage`,
          label: 'carsTableNext.filter.priceDamage',
          filter: ({ field: { ref: _ref, ...field } }) => {
            const { t } = useTranslation();
            return <CurrencySliderInput {...field} label={t('car.damage')} step={100} min={0} max={25_000} />;
          },
          renderFilterChip: ({ field: { name, value, label } }) => (
            <AmountSliderChip name={name} value={value} label={label} />
          ),
          onlyCarsTable: true,
        },
      ],
    },
    {
      groupKey: 'environment',
      label: 'carsTableNext.filter.groups.environment',
      filter: [
        {
          name: `${prefix}engineCo2Nedc`,
          label: 'carsTableNext.filter.engineCo2Nedc',
          filter: ({ field: { ref, ...field } }) => (
            <RangeSlider innerRef={ref} {...field} step={5} min={0} max={400} unit="g/km" />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <SliderFilterChip name={name} value={value} label={label} prefix={prefix} />
          ),
        },
        {
          name: `${prefix}engineCo2Wltp`,
          label: 'carsTableNext.filter.engineCo2Wltp',
          filter: ({ field: { ref, ...field } }) => (
            <RangeSlider innerRef={ref} {...field} step={5} min={0} max={400} unit="g/km" />
          ),
          renderFilterChip: ({ field: { name, value, label } }) => (
            <SliderFilterChip name={name} value={value} label={label} prefix={prefix} />
          ),
        },
      ],
    },
    {
      groupKey: 'calculation',
      label: 'carsTableNext.filter.groups.calculation',
      filter: [
        {
          name: `${prefix}potentialFilter`,
          label: '',
          filter: () => <PotentialFilter />,
          renderFilterChip: ({ field: { name, value } }) => <PotentialFilterChip name={name} value={value} />,
          onlyCarsTable: true,
        },
        {
          name: `${prefix}purchasePriceGross`,
          label: 'carsTableNext.filter.purchasePriceGross',
          filter: () => <PurchaseGrossSliderInput />,
          renderFilterChip: ({ field: { name, value, label } }) => (
            <AmountSliderChip name={name} value={value} label={label} />
          ),
          onlyCarsTable: true,
        },
      ],
    },
  ];
