/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AcceptTermsConditionsRequest
 */
export interface AcceptTermsConditionsRequest {
  /**
   *
   * @type {TermsConditionsType}
   * @memberof AcceptTermsConditionsRequest
   */
  termsType: TermsConditionsType;
  /**
   *
   * @type {number}
   * @memberof AcceptTermsConditionsRequest
   */
  version: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AuthenticationProvider {
  PartnerNet = 'PARTNER_NET',
  Grp = 'GRP',
  Unknown = 'UNKNOWN',
}

/**
 *
 * @export
 * @interface BidAutomationStatusResponseDTO
 */
export interface BidAutomationStatusResponseDTO {
  /**
   *
   * @type {SourceType}
   * @memberof BidAutomationStatusResponseDTO
   */
  source?: SourceType;
  /**
   *
   * @type {string}
   * @memberof BidAutomationStatusResponseDTO
   */
  carId?: string;
  /**
   *
   * @type {Array<IndividualBidDTO>}
   * @memberof BidAutomationStatusResponseDTO
   */
  bids: Array<IndividualBidDTO>;
  /**
   *
   * @type {SetBidRequestDTO}
   * @memberof BidAutomationStatusResponseDTO
   */
  bidRequest: SetBidRequestDTO;
  /**
   *
   * @type {boolean}
   * @memberof BidAutomationStatusResponseDTO
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof BidAutomationStatusResponseDTO
   */
  error?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BidAutomationType {
  Repeat = 'REPEAT',
}

/**
 *
 * @export
 * @interface BidDTO
 */
export interface BidDTO {
  /**
   *
   * @type {string}
   * @memberof BidDTO
   */
  biddingTime?: string;
  /**
   *
   * @type {Array<IndividualBidDTO>}
   * @memberof BidDTO
   */
  bids?: Array<IndividualBidDTO>;
  /**
   *
   * @type {string}
   * @memberof BidDTO
   */
  boughtTime?: string;
  /**
   *
   * @type {Array<DetailCarDTO>}
   * @memberof BidDTO
   */
  cars?: Array<DetailCarDTO>;
  /**
   *
   * @type {string}
   * @memberof BidDTO
   */
  created?: string;
  /**
   *
   * @type {DeliveryAddressDTO}
   * @memberof BidDTO
   */
  deliveryAddress?: DeliveryAddressDTO;
  /**
   *
   * @type {string}
   * @memberof BidDTO
   */
  deliveryAddressChangeUntil?: string;
  /**
   *
   * @type {CarDetailing}
   * @memberof BidDTO
   */
  detailing?: CarDetailing;
  /**
   *
   * @type {DeliveryAddressDTO}
   * @memberof BidDTO
   */
  detailingAddress?: DeliveryAddressDTO;
  /**
   *
   * @type {string}
   * @memberof BidDTO
   */
  errorMessage?: string;
  /**
   *
   * @type {string}
   * @memberof BidDTO
   */
  id?: string;
  /**
   *
   * @type {OfferType}
   * @memberof BidDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {string}
   * @memberof BidDTO
   */
  sentTime?: string;
  /**
   *
   * @type {BidSuccessStatus}
   * @memberof BidDTO
   */
  successStatus?: BidSuccessStatus;
}
/**
 *
 * @export
 * @interface BidDataTablesOutputListBidDTO
 */
export interface BidDataTablesOutputListBidDTO {
  /**
   *
   * @type {Array<ListBidDTO>}
   * @memberof BidDataTablesOutputListBidDTO
   */
  data?: Array<ListBidDTO>;
  /**
   *
   * @type {number}
   * @memberof BidDataTablesOutputListBidDTO
   */
  draw?: number;
  /**
   *
   * @type {string}
   * @memberof BidDataTablesOutputListBidDTO
   */
  error?: string;
  /**
   *
   * @type {number}
   * @memberof BidDataTablesOutputListBidDTO
   */
  recordsFiltered?: number;
  /**
   *
   * @type {number}
   * @memberof BidDataTablesOutputListBidDTO
   */
  recordsTotal?: number;
  /**
   *
   * @type {Array<FacetedSearchResult>}
   * @memberof BidDataTablesOutputListBidDTO
   */
  facetedSearchResult?: Array<FacetedSearchResult>;
  /**
   *
   * @type {number}
   * @memberof BidDataTablesOutputListBidDTO
   */
  carsRecordsTotal?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BidFilterStatus {
  All = 'ALL',
  Bidden = 'BIDDEN',
  AnybodyBidden = 'ANYBODY_BIDDEN',
  NotBidden = 'NOT_BIDDEN',
  NobodyBidden = 'NOBODY_BIDDEN',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BidRequestCarStatus {
  Ok = 'OK',
  CarUnavailable = 'CAR_UNAVAILABLE',
  Error = 'ERROR',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BidSuccessReason {
  FixedBidPresent = 'FIXED_BID_PRESENT',
  MinDistance = 'MIN_DISTANCE',
  NotHighest = 'NOT_HIGHEST',
  NotWithinFirstThreeBids = 'NOT_WITHIN_FIRST_THREE_BIDS',
  ServerError = 'SERVER_ERROR',
  Unconfirmed = 'UNCONFIRMED',
  UnsupportedPlatform = 'UNSUPPORTED_PLATFORM',
  Valid = 'VALID',
  CarOffline = 'CAR_OFFLINE',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BidSuccessStatus {
  Error = 'ERROR',
  Outbid = 'OUTBID',
  Highest = 'HIGHEST',
  Allocate = 'ALLOCATE',
  Bought = 'BOUGHT',
  Sent = 'SENT',
  NotSent = 'NOT_SENT',
  Scheduled = 'SCHEDULED',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BidType {
  Bid = 'BID',
  Fixed = 'FIXED',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum BidVisibility {
  All = 'ALL',
  Country = 'COUNTRY',
  Dealer = 'DEALER',
  User = 'USER',
}

/**
 *
 * @export
 * @interface BiddingDeliveryAddressSelectionDTO
 */
export interface BiddingDeliveryAddressSelectionDTO {
  /**
   *
   * @type {Set<DeliveryAddressDTO>}
   * @memberof BiddingDeliveryAddressSelectionDTO
   */
  deliveryAddresses?: Set<DeliveryAddressDTO>;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof BiddingDeliveryAddressSelectionDTO
   */
  selectedAddressByKVPS?: { [key: string]: Array<string> };
}
/**
 *
 * @export
 * @interface BidsAndCarsRequestDTO
 */
export interface BidsAndCarsRequestDTO {
  /**
   *
   * @type {Array<Column>}
   * @memberof BidsAndCarsRequestDTO
   */
  columns?: Array<Column>;
  /**
   *
   * @type {number}
   * @memberof BidsAndCarsRequestDTO
   */
  draw?: number;
  /**
   *
   * @type {number}
   * @memberof BidsAndCarsRequestDTO
   */
  length?: number;
  /**
   *
   * @type {Array<Order>}
   * @memberof BidsAndCarsRequestDTO
   */
  order?: Array<Order>;
  /**
   *
   * @type {Search}
   * @memberof BidsAndCarsRequestDTO
   */
  search?: Search;
  /**
   *
   * @type {number}
   * @memberof BidsAndCarsRequestDTO
   */
  start?: number;
  /**
   *
   * @type {OnlineCarsRequestDTO}
   * @memberof BidsAndCarsRequestDTO
   */
  carsRequest?: OnlineCarsRequestDTO;
  /**
   *
   * @type {Array<BidSuccessStatus>}
   * @memberof BidsAndCarsRequestDTO
   */
  bidSuccessStates?: Array<BidSuccessStatus>;
  /**
   *
   * @type {BidVisibility}
   * @memberof BidsAndCarsRequestDTO
   */
  bidVisibility?: BidVisibility;
  /**
   *
   * @type {Array<CountryCode>}
   * @memberof BidsAndCarsRequestDTO
   */
  bidCreatorDealerCountry?: Array<CountryCode>;
  /**
   *
   * @type {Array<string>}
   * @memberof BidsAndCarsRequestDTO
   */
  bidCreatorDealerId?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BidsAndCarsRequestDTO
   */
  bidCreatorUserId?: Array<string>;
  /**
   *
   * @type {DateTimeFilterDTO}
   * @memberof BidsAndCarsRequestDTO
   */
  biddingTimeFilter?: DateTimeFilterDTO;
  /**
   *
   * @type {DateTimeFilterDTO}
   * @memberof BidsAndCarsRequestDTO
   */
  boughtTimeFilter?: DateTimeFilterDTO;
  /**
   *
   * @type {DateTimeFilterDTO}
   * @memberof BidsAndCarsRequestDTO
   */
  sentTimeFilter?: DateTimeFilterDTO;
  /**
   *
   * @type {boolean}
   * @memberof BidsAndCarsRequestDTO
   */
  showOnlyBidsWithAutomation?: boolean;
  /**
   *
   * @type {{ [key: string]: Column; }}
   * @memberof BidsAndCarsRequestDTO
   */
  columnsAsMap?: { [key: string]: Column };
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BuyableStatus {
  Buyable = 'BUYABLE',
  NonBuyable = 'NON_BUYABLE',
  All = 'ALL',
}

/**
 *
 * @export
 * @interface CSVBidRequestDTO
 */
export interface CSVBidRequestDTO {
  /**
   *
   * @type {number}
   * @memberof CSVBidRequestDTO
   */
  bid1?: number;
  /**
   *
   * @type {number}
   * @memberof CSVBidRequestDTO
   */
  bid2?: number;
  /**
   *
   * @type {number}
   * @memberof CSVBidRequestDTO
   */
  bid3?: number;
  /**
   *
   * @type {BidType}
   * @memberof CSVBidRequestDTO
   */
  bidType?: BidType;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  biddingTime?: string;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  carId?: string;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  carId2?: string;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  packageId?: string;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  packageId2?: string;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  kvps?: string;
  /**
   *
   * @type {SourceType}
   * @memberof CSVBidRequestDTO
   */
  source?: SourceType;
  /**
   *
   * @type {BidRequestCarStatus}
   * @memberof CSVBidRequestDTO
   */
  status?: BidRequestCarStatus;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  errorMessage?: string;
  /**
   *
   * @type {string}
   * @memberof CSVBidRequestDTO
   */
  vin?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CarDetailing {
  DetailingPackage = 'DETAILING_PACKAGE',
  DetailingPackageDiscounted = 'DETAILING_PACKAGE_DISCOUNTED',
  NoDetailing = 'NO_DETAILING',
  RepairPackage = 'REPAIR_PACKAGE',
  ServicePackage = 'SERVICE_PACKAGE',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Co2ApproximationMethod {
  Augmented = 'AUGMENTED',
  SameModelcode = 'SAME_MODELCODE',
  SameModelcodeAndFirstRegistrationYear = 'SAME_MODELCODE_AND_FIRST_REGISTRATION_YEAR',
  SameModelcodeAndModelyear = 'SAME_MODELCODE_AND_MODELYEAR',
  Techdat = 'TECHDAT',
}

/**
 *
 * @export
 * @interface Column
 */
export interface Column {
  /**
   *
   * @type {string}
   * @memberof Column
   */
  data: string;
  /**
   *
   * @type {string}
   * @memberof Column
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof Column
   */
  orderable: boolean;
  /**
   *
   * @type {Search}
   * @memberof Column
   */
  search: Search;
  /**
   *
   * @type {boolean}
   * @memberof Column
   */
  searchable: boolean;
  /**
   *
   * @type {string}
   * @memberof Column
   */
  searchValue?: string;
}
/**
 *
 * @export
 * @interface ConditionalFormattingDTO
 */
export interface ConditionalFormattingDTO {
  /**
   *
   * @type {Array<ConditionalFormattingDetailDTO>}
   * @memberof ConditionalFormattingDTO
   */
  conditions?: Array<ConditionalFormattingDetailDTO>;
  /**
   *
   * @type {ConditionalFormattingType}
   * @memberof ConditionalFormattingDTO
   */
  field?: ConditionalFormattingType;
}
/**
 *
 * @export
 * @interface ConditionalFormattingDetailDTO
 */
export interface ConditionalFormattingDetailDTO {
  /**
   *
   * @type {string}
   * @memberof ConditionalFormattingDetailDTO
   */
  backgroundColor?: string;
  /**
   *
   * @type {string}
   * @memberof ConditionalFormattingDetailDTO
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ConditionalFormattingDetailDTO
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof ConditionalFormattingDetailDTO
   */
  eq?: string;
  /**
   *
   * @type {number}
   * @memberof ConditionalFormattingDetailDTO
   */
  gt?: number;
  /**
   *
   * @type {number}
   * @memberof ConditionalFormattingDetailDTO
   */
  gte?: number;
  /**
   *
   * @type {number}
   * @memberof ConditionalFormattingDetailDTO
   */
  lt?: number;
  /**
   *
   * @type {number}
   * @memberof ConditionalFormattingDetailDTO
   */
  lte?: number;
  /**
   *
   * @type {ConditionalFormattingScope}
   * @memberof ConditionalFormattingDetailDTO
   */
  scope?: ConditionalFormattingScope;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ConditionalFormattingScope {
  Row = 'ROW',
  Cell = 'CELL',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ConditionalFormattingType {
  Authentication = 'AUTHENTICATION',
  Civil = 'CIVIL',
  Co2 = 'CO2',
  Co2Nefz = 'CO2_NEFZ',
  Co2Wltp = 'CO2_WLTP',
  DamageType = 'DAMAGE_TYPE',
  DateOnlineTimeInMinutes = 'DATE_ONLINE_TIME_IN_MINUTES',
  Eco = 'ECO',
  Ecomalus = 'ECOMALUS',
  Excise = 'EXCISE',
  FirstRegistration = 'FIRST_REGISTRATION',
  Mileage = 'MILEAGE',
  Model = 'MODEL',
  ModelTechdat = 'MODEL_TECHDAT',
  NormalizedFuel = 'NORMALIZED_FUEL',
  NormalizedFullModelName = 'NORMALIZED_FULL_MODEL_NAME',
  Nova = 'NOVA',
  NumAuctions = 'NUM_AUCTIONS',
  PotentialBase = 'POTENTIAL_BASE',
  PotentialInternetPriceAverageAbsolute = 'POTENTIAL_INTERNET_PRICE_AVERAGE_ABSOLUTE',
  PotentialInternetPriceAveragePercent = 'POTENTIAL_INTERNET_PRICE_AVERAGE_PERCENT',
  PotentialInternetPriceMinAbsolute = 'POTENTIAL_INTERNET_PRICE_MIN_ABSOLUTE',
  PotentialInternetPriceMinAverageAbsolute = 'POTENTIAL_INTERNET_PRICE_MIN_AVERAGE_ABSOLUTE',
  PotentialInternetPriceMinAveragePercent = 'POTENTIAL_INTERNET_PRICE_MIN_AVERAGE_PERCENT',
  PotentialInternetPriceMinPercent = 'POTENTIAL_INTERNET_PRICE_MIN_PERCENT',
  PotentialRequiredDiscount = 'POTENTIAL_REQUIRED_DISCOUNT',
  PotentialRequiredDiscountPercent = 'POTENTIAL_REQUIRED_DISCOUNT_PERCENT',
  PriceDamage = 'PRICE_DAMAGE',
  Promotions = 'PROMOTIONS',
  PropertyAcquisition = 'PROPERTY_ACQUISITION',
  PurchaseGross = 'PURCHASE_GROSS',
  PurchaseNetRepaired = 'PURCHASE_NET_REPAIRED',
  PurchaseNetUnrepaired = 'PURCHASE_NET_UNREPAIRED',
  Rating = 'RATING',
  RegistrationAbsolute = 'REGISTRATION_ABSOLUTE',
  RegistrationRelative = 'REGISTRATION_RELATIVE',
  TaxType = 'TAX_TYPE',
  Transcription = 'TRANSCRIPTION',
  ValuationMarketPrice = 'VALUATION_MARKET_PRICE',
  Vat = 'VAT',
}

/**
 *
 * @export
 * @interface ContributionMarginAndCostsDTO
 */
export interface ContributionMarginAndCostsDTO {
  /**
   *
   * @type {number}
   * @memberof ContributionMarginAndCostsDTO
   */
  contributionMarginPercent?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ContributionMarginAndCostsDTO
   */
  costs?: MonetaryAmount;
}
/**
 *
 * @export
 * @interface CostsDTO
 */
export interface CostsDTO {
  /**
   *
   * @type {number}
   * @memberof CostsDTO
   */
  contributionMarginPercent?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof CostsDTO
   */
  costs?: MonetaryAmount;
  /**
   *
   * @type {{ [key: string]: GlobalCostsDTO; }}
   * @memberof CostsDTO
   */
  global?: { [key: string]: GlobalCostsDTO };
  /**
   *
   * @type {number}
   * @memberof CostsDTO
   */
  repairQuotePercent?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CountryCode {
  Undefined = 'UNDEFINED',
  Ac = 'AC',
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bu = 'BU',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cp = 'CP',
  Cr = 'CR',
  Cs = 'CS',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dg = 'DG',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ea = 'EA',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Eu = 'EU',
  Ez = 'EZ',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Fx = 'FX',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Ic = 'IC',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nt = 'NT',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sf = 'SF',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Su = 'SU',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Ta = 'TA',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tp = 'TP',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Uk = 'UK',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xi = 'XI',
  Xu = 'XU',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Yu = 'YU',
  Za = 'ZA',
  Zm = 'ZM',
  Zr = 'ZR',
  Zw = 'ZW',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum CurrencyCode {
  Undefined = 'UNDEFINED',
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Std = 'STD',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uss = 'USS',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DamageType {
  Hail = 'HAIL',
  Dirt = 'DIRT',
  Accident = 'ACCIDENT',
  MissingWheelSet = 'MISSING_WHEEL_SET',
  RepairedDamage = 'REPAIRED_DAMAGE',
  Refurbished = 'REFURBISHED',
  HighDamage = 'HIGH_DAMAGE',
}

/**
 *
 * @export
 * @interface DataTablesOutputListCarDTO
 */
export interface DataTablesOutputListCarDTO {
  /**
   *
   * @type {Array<ListCarDTO>}
   * @memberof DataTablesOutputListCarDTO
   */
  data?: Array<ListCarDTO>;
  /**
   *
   * @type {number}
   * @memberof DataTablesOutputListCarDTO
   */
  draw?: number;
  /**
   *
   * @type {string}
   * @memberof DataTablesOutputListCarDTO
   */
  error?: string;
  /**
   *
   * @type {number}
   * @memberof DataTablesOutputListCarDTO
   */
  recordsFiltered?: number;
  /**
   *
   * @type {number}
   * @memberof DataTablesOutputListCarDTO
   */
  recordsTotal?: number;
  /**
   *
   * @type {Array<FacetedSearchResult>}
   * @memberof DataTablesOutputListCarDTO
   */
  facetedSearchResult?: Array<FacetedSearchResult>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum DateFilter {
  All = 'ALL',
  Individual = 'INDIVIDUAL',
  Last1Hour = 'LAST_1_HOUR',
  Last30Minutes = 'LAST_30_MINUTES',
  Last3Hour = 'LAST_3_HOUR',
  Last6Hour = 'LAST_6_HOUR',
  Next1Hour = 'NEXT_1_HOUR',
  Next30Minutes = 'NEXT_30_MINUTES',
  Next3Hour = 'NEXT_3_HOUR',
  Next6Hour = 'NEXT_6_HOUR',
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
}

/**
 *
 * @export
 * @interface DateTimeFilterDTO
 */
export interface DateTimeFilterDTO {
  /**
   *
   * @type {string}
   * @memberof DateTimeFilterDTO
   */
  timeFrom?: string;
  /**
   *
   * @type {string}
   * @memberof DateTimeFilterDTO
   */
  timeTo?: string;
  /**
   *
   * @type {DateFilter}
   * @memberof DateTimeFilterDTO
   */
  timeType?: DateFilter;
}
/**
 *
 * @export
 * @interface DealerChangeNameRequestDTO
 */
export interface DealerChangeNameRequestDTO {
  /**
   *
   * @type {string}
   * @memberof DealerChangeNameRequestDTO
   */
  name: string;
}
/**
 *
 * @export
 * @interface DealerId
 */
export interface DealerId {
  /**
   *
   * @type {string}
   * @memberof DealerId
   */
  id?: string;
  /**
   *
   * @type {CountryCode}
   * @memberof DealerId
   */
  country?: CountryCode;
}
/**
 *
 * @export
 * @interface DeletePlatformCredentialRequest
 */
export interface DeletePlatformCredentialRequest {
  /**
   *
   * @type {string}
   * @memberof DeletePlatformCredentialRequest
   */
  username?: string;
}
/**
 *
 * @export
 * @interface DeliveryAddressDTO
 */
export interface DeliveryAddressDTO {
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  contactPersonName: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  country: string;
  /**
   *
   * @type {CountryCode}
   * @memberof DeliveryAddressDTO
   */
  countryCode: CountryCode;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  faxNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeliveryAddressDTO
   */
  global?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  lastChanged?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  street: string;
  /**
   *
   * @type {DeliveryAddressType}
   * @memberof DeliveryAddressDTO
   */
  type?: DeliveryAddressType;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddressDTO
   */
  zip: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum DeliveryAddressType {
  PlatformStandard = 'PLATFORM_STANDARD',
  Custom = 'CUSTOM',
}

/**
 *
 * @export
 * @interface DetailCarDTO
 */
export interface DetailCarDTO {
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  assessmentLink?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  auctionId?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  availability?: string;
  /**
   *
   * @type {IndividualBidStateInfoDTO}
   * @memberof DetailCarDTO
   */
  bidStateInfo?: IndividualBidStateInfoDTO;
  /**
   *
   * @type {BidSuccessStatus}
   * @memberof DetailCarDTO
   */
  bidSuccessStatus?: BidSuccessStatus;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  brandTechdat?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  carId?: string;
  /**
   *
   * @type {Co2ApproximationMethod}
   * @memberof DetailCarDTO
   */
  co2ApproximationMethod?: Co2ApproximationMethod;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  color?: string;
  /**
   *
   * @type {NormalizedColor}
   * @memberof DetailCarDTO
   */
  colorId?: NormalizedColor;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  constructionYear?: number;
  /**
   *
   * @type {CountryCode}
   * @memberof DetailCarDTO
   */
  country?: CountryCode;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  countryOrigin?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  created?: string;
  /**
   *
   * @type {Array<DamageType>}
   * @memberof DetailCarDTO
   */
  damageType?: Array<DamageType>;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  dateBuyOpening?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  dateEnd?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  dateStart?: string;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  doors?: number;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  engineCo2?: number;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  engineCo2Nedc?: number;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  engineCo2Wltp?: number;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  engineFuel?: string;
  /**
   *
   * @type {UnitEntry}
   * @memberof DetailCarDTO
   */
  enginePower?: UnitEntry;
  /**
   *
   * @type {Array<string>}
   * @memberof DetailCarDTO
   */
  equipment?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof DetailCarDTO
   */
  equipmentKeys?: Array<EquipmentHighlight>;
  /**
   *
   * @type {FirstCallType}
   * @memberof DetailCarDTO
   */
  firstCallType?: FirstCallType;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  firstRegistration?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  imageLink?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DetailCarDTO
   */
  imageLinks?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  imageThumbnailLink?: string;
  /**
   *
   * @type {Array<IndividualizedCarDTO>}
   * @memberof DetailCarDTO
   */
  individualizations?: Array<IndividualizedCarDTO>;
  /**
   *
   * @type {Array<KvpsDTO>}
   * @memberof DetailCarDTO
   */
  kvps?: Array<KvpsDTO>;
  /**
   *
   * @type {Array<MarketingChannel>}
   * @memberof DetailCarDTO
   */
  marketingChannel?: Array<MarketingChannel>;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  mileage?: number;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  modelSpecification?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  modelTechdat?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  modelcode?: string;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  modelyear?: number;
  /**
   *
   * @type {SourceRegisterNormalizedDTO}
   * @memberof DetailCarDTO
   */
  normalized?: SourceRegisterNormalizedDTO;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  nova?: number;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  numAuctions?: number;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  offerId?: string;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  onlineTimeInMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  originSource?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  packageId?: string;
  /**
   *
   * @type {SourceRegisterPotentialDTO}
   * @memberof DetailCarDTO
   */
  potential?: SourceRegisterPotentialDTO;
  /**
   *
   * @type {{ [key: string]: SourceRegisterPotentialDTO; }}
   * @memberof DetailCarDTO
   */
  potentials?: { [key: string]: SourceRegisterPotentialDTO };
  /**
   *
   * @type {Array<DetailCarPreviousAuctionDTO>}
   * @memberof DetailCarDTO
   */
  previousAuctions?: Array<DetailCarPreviousAuctionDTO>;
  /**
   *
   * @type {UnitEntry}
   * @memberof DetailCarDTO
   */
  priceAuction?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof DetailCarDTO
   */
  priceBid?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof DetailCarDTO
   */
  priceDamage?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof DetailCarDTO
   */
  priceFixed?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof DetailCarDTO
   */
  priceOrientation?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof DetailCarDTO
   */
  pricePackage?: UnitEntry;
  /**
   *
   * @type {Array<string>}
   * @memberof DetailCarDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  rating?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarDTO
   */
  recommendedRetailPrice?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarDTO
   */
  recommendedRetailPriceDeductionAbsolute?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  recommendedRetailPriceDeductionRelative?: number;
  /**
   *
   * @type {number}
   * @memberof DetailCarDTO
   */
  seats?: number;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  shape?: string;
  /**
   *
   * @type {SourceType}
   * @memberof DetailCarDTO
   */
  source?: SourceType;
  /**
   *
   * @type {Array<SourceType>}
   * @memberof DetailCarDTO
   */
  virtualSources?: Array<SourceType>;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  statusReportLink?: string;
  /**
   *
   * @type {TaxContainer}
   * @memberof DetailCarDTO
   */
  tax?: TaxContainer;
  /**
   *
   * @type {TaxType}
   * @memberof DetailCarDTO
   */
  taxType?: TaxType;
  /**
   *
   * @type {{ [key: string]: TaxContainer; }}
   * @memberof DetailCarDTO
   */
  taxes?: { [key: string]: TaxContainer };
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  transmission?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  userComment?: string;
  /**
   *
   * @type {ValidityType}
   * @memberof DetailCarDTO
   */
  validity?: ValidityType;
  /**
   *
   * @type {SourceRegisterValuationDTO}
   * @memberof DetailCarDTO
   */
  valuation?: SourceRegisterValuationDTO;
  /**
   *
   * @type {{ [key: string]: SourceRegisterValuationDTO; }}
   * @memberof DetailCarDTO
   */
  valuations?: { [key: string]: SourceRegisterValuationDTO };
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarDTO
   */
  zip?: string;
  /**
   *
   * @type {BidDTO}
   * @memberof DetailCarDTO
   */
  bid?: BidDTO;
  /**
   *
   * @type {Array<SourceRegisterCarDetailDamageDTO>}
   * @memberof DetailCarDTO
   */
  damage?: Array<SourceRegisterCarDetailDamageDTO>;
  /**
   *
   * @type {Array<EquipmentCodeText>}
   * @memberof DetailCarDTO
   */
  equipmentCodeText?: Array<EquipmentCodeText>;
  /**
   *
   * @type {BiddingDeliveryAddressSelectionDTO}
   * @memberof DetailCarDTO
   */
  deliveryAddressSelection?: BiddingDeliveryAddressSelectionDTO;
  /**
   *
   * @type {boolean}
   * @memberof DetailCarDTO
   */
  imagesExportLink?: boolean;
}
/**
 *
 * @export
 * @interface DetailCarPreviousAuctionDTO
 */
export interface DetailCarPreviousAuctionDTO {
  /**
   *
   * @type {string}
   * @memberof DetailCarPreviousAuctionDTO
   */
  dateEnd?: string;
  /**
   *
   * @type {string}
   * @memberof DetailCarPreviousAuctionDTO
   */
  dateStart?: string;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  marketPrice?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  marketPriceMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  marketPriceMin?: MonetaryAmount;
  /**
   *
   * @type {Array<MarketingChannel>}
   * @memberof DetailCarPreviousAuctionDTO
   */
  marketingChannel?: Array<MarketingChannel>;
  /**
   *
   * @type {OfferType}
   * @memberof DetailCarPreviousAuctionDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  potentialMeanAbsolute?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof DetailCarPreviousAuctionDTO
   */
  potentialMeanRelative?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  potentialMinAbsolute?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  potentialMinMeanAbsolute?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof DetailCarPreviousAuctionDTO
   */
  potentialMinMeanRelative?: number;
  /**
   *
   * @type {number}
   * @memberof DetailCarPreviousAuctionDTO
   */
  potentialMinRelative?: number;
  /**
   *
   * @type {SourceRegisterPotentialDTO}
   * @memberof DetailCarPreviousAuctionDTO
   */
  price?: SourceRegisterPotentialDTO;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  priceAuction?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  priceDamage?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  priceFixed?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof DetailCarPreviousAuctionDTO
   */
  priceOrientation?: MonetaryAmount;
  /**
   *
   * @type {Array<CountryCode>}
   * @memberof DetailCarPreviousAuctionDTO
   */
  visibleForCountry?: Array<CountryCode>;
  /**
   *
   * @type {Array<string>}
   * @memberof DetailCarPreviousAuctionDTO
   */
  visibleForKvps?: Array<string>;
}
/**
 *
 * @export
 * @interface DetailedDealerDTO
 */
export interface DetailedDealerDTO {
  /**
   *
   * @type {Array<SourceType>}
   * @memberof DetailedDealerDTO
   */
  allowedSource?: Array<SourceType>;
  /**
   *
   * @type {CostsDTO}
   * @memberof DetailedDealerDTO
   */
  costs?: CostsDTO;
  /**
   *
   * @type {DealerId}
   * @memberof DetailedDealerDTO
   */
  dealer?: DealerId;
  /**
   *
   * @type {string}
   * @memberof DetailedDealerDTO
   */
  defaultDeliveryAddress?: string;
  /**
   *
   * @type {Set<DeliveryAddressDTO>}
   * @memberof DetailedDealerDTO
   */
  deliveryAddresses?: Set<DeliveryAddressDTO>;
  /**
   *
   * @type {Set<DeliveryAddressDTO>}
   * @memberof DetailedDealerDTO
   */
  deliveryAddressesForNewCredentials?: Set<DeliveryAddressDTO>;
  /**
   *
   * @type {Set<string>}
   * @memberof DetailedDealerDTO
   */
  deliveryAddressesRequiringAttention?: Set<string>;
  /**
   *
   * @type {CarDetailing}
   * @memberof DetailedDealerDTO
   */
  detailing?: CarDetailing;
  /**
   *
   * @type {boolean}
   * @memberof DetailedDealerDTO
   */
  isAllowedToLogin?: boolean;
  /**
   *
   * @type {string}
   * @memberof DetailedDealerDTO
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: PacketDTO; }}
   * @memberof DetailedDealerDTO
   */
  assignedPackets?: { [key: string]: PacketDTO };
  /**
   *
   * @type {boolean}
   * @memberof DetailedDealerDTO
   * @deprecated
   */
  shouldChangeDeliveryAddress?: boolean;
}
/**
 *
 * @export
 * @interface DetailingAddressDTO
 */
export interface DetailingAddressDTO {
  /**
   *
   * @type {DeliveryAddressDTO}
   * @memberof DetailingAddressDTO
   */
  fallback: DeliveryAddressDTO;
  /**
   *
   * @type {{ [key: string]: DetailingAddressPerSourceDTO; }}
   * @memberof DetailingAddressDTO
   */
  perSource?: { [key: string]: DetailingAddressPerSourceDTO };
}
/**
 *
 * @export
 * @interface DetailingAddressPerSourceDTO
 */
export interface DetailingAddressPerSourceDTO {
  /**
   *
   * @type {DeliveryAddressDTO}
   * @memberof DetailingAddressPerSourceDTO
   */
  fallback?: DeliveryAddressDTO;
  /**
   *
   * @type {{ [key: string]: DeliveryAddressDTO; }}
   * @memberof DetailingAddressPerSourceDTO
   */
  perCountry?: { [key: string]: DeliveryAddressDTO };
}
/**
 *
 * @export
 * @interface EquipmentCodeText
 */
export interface EquipmentCodeText {
  /**
   *
   * @type {string}
   * @memberof EquipmentCodeText
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EquipmentCodeText
   */
  text?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum EquipmentHighlight {
  Ac = 'AC',
  Acauto = 'ACAUTO',
  Ahk = 'AHK',
  Airsusp = 'AIRSUSP',
  Alu = 'ALU',
  Assi = 'ASSI',
  Aut = 'AUT',
  Awd = 'AWD',
  Business = 'BUSINESS',
  Comfort = 'COMFORT',
  Esd = 'ESD',
  Frline = 'FRLINE',
  Gra = 'GRA',
  HeatPump = 'HEAT_PUMP',
  Infotainment = 'INFOTAINMENT',
  Leder = 'LEDER',
  Navi = 'NAVI',
  Pdc = 'PDC',
  Rad2 = 'RAD2',
  Rearcam = 'REARCAM',
  Reifen2 = 'REIFEN2',
  Rline = 'RLINE',
  Shz = 'SHZ',
  Sline = 'SLINE',
  Sonder = 'SONDER',
  Sound = 'SOUND',
  Sportline = 'SPORTLINE',
  SportsPackage = 'SPORTS_PACKAGE',
  Stdhzg = 'STDHZG',
  Tel = 'TEL',
  Truckreg = 'TRUCKREG',
  WarrantyExtension = 'WARRANTY_EXTENSION',
  Winter = 'WINTER',
  Xenon = 'XENON',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ExportCarProperty {
  AssessmentLink = 'assessmentLink',
  Availability = 'availability',
  Bids = 'bids',
  Brand = 'brand',
  BrandTechdat = 'brandTechdat',
  CarId = 'carId',
  Color = 'color',
  ColorId = 'colorId',
  Country = 'country',
  CountryOrigin = 'countryOrigin',
  Created = 'created',
  DamageType = 'damageType',
  DateBuyOpening = 'dateBuyOpening',
  DateEnd = 'dateEnd',
  DateStart = 'dateStart',
  Doors = 'doors',
  EngineCo2 = 'engineCo2',
  EngineFuel = 'engineFuel',
  EnginePower = 'enginePower',
  EngineTestingProcedure = 'engineTestingProcedure',
  Equipment = 'equipment',
  EquipmentKeys = 'equipmentKeys',
  FirstRegistration = 'firstRegistration',
  Id = 'id',
  ImageLink = 'imageLink',
  ImageLinks = 'imageLinks',
  ImageThumbnailLink = 'imageThumbnailLink',
  MarketingChannel = 'marketingChannel',
  MarketPrice = 'marketPrice',
  Mileage = 'mileage',
  Model = 'model',
  Modelcode = 'modelcode',
  Modelyear = 'modelyear',
  ModelSpecification = 'modelSpecification',
  ModelTechdat = 'modelTechdat',
  Nova = 'nova',
  NumAuctions = 'numAuctions',
  OnlineTimeInMinutes = 'onlineTimeInMinutes',
  PackageId = 'packageId',
  PotentialBasePrice = 'potentialBasePrice',
  PriceBid = 'priceBid',
  PriceDamage = 'priceDamage',
  PriceFixed = 'priceFixed',
  PriceOrientation = 'priceOrientation',
  Promotions = 'promotions',
  Rating = 'rating',
  Seats = 'seats',
  Shape = 'shape',
  Source = 'source',
  TaxType = 'taxType',
  Transmission = 'transmission',
  UserComment = 'userComment',
  Validity = 'validity',
  Vin = 'vin',
  Icons = 'icons',
  Base = 'base',
  PotentialBid = 'potentialBid',
  OfferPrice = 'offerPrice',
  PurchaseNetWholesaleUnrepaired = 'purchaseNetWholesaleUnrepaired',
  PurchaseNetRetailUnrepaired = 'purchaseNetRetailUnrepaired',
  PurchaseNetRetailRepaired = 'purchaseNetRetailRepaired',
  PotentialMinAbsolute = 'potentialMinAbsolute',
  PotentialMinRelative = 'potentialMinRelative',
  PotentialMinMeanAbsolute = 'potentialMinMeanAbsolute',
  PotentialMinMeanRelative = 'potentialMinMeanRelative',
  PotentialMeanAbsolute = 'potentialMeanAbsolute',
  PotentialMeanRelative = 'potentialMeanRelative',
  PurchaseGross = 'purchaseGross',
  PurchaseGrossOld = 'purchaseGrossOld',
  PurchaseNetRepaired = 'purchaseNetRepaired',
  PurchaseNetUnrepaired = 'purchaseNetUnrepaired',
}

/**
 *
 * @export
 * @interface ExportCarsRequestDTO
 */
export interface ExportCarsRequestDTO {
  /**
   *
   * @type {Array<Column>}
   * @memberof ExportCarsRequestDTO
   */
  columns?: Array<Column>;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  draw?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  length?: number;
  /**
   *
   * @type {Array<Order>}
   * @memberof ExportCarsRequestDTO
   */
  order?: Array<Order>;
  /**
   *
   * @type {Search}
   * @memberof ExportCarsRequestDTO
   */
  search?: Search;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  anyOtherUserRatingMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  anyOtherUserRatingMin?: number;
  /**
   *
   * @type {BuyableStatus}
   * @memberof ExportCarsRequestDTO
   */
  buyableStatus?: BuyableStatus;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  carIds?: Array<string>;
  /**
   *
   * @type {Array<NormalizedColor>}
   * @memberof ExportCarsRequestDTO
   */
  colors?: Array<NormalizedColor>;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  countryOrigin?: Array<string>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof ExportCarsRequestDTO
   */
  currentBidState?: BidFilterStatus;
  /**
   *
   * @type {Array<DamageType>}
   * @memberof ExportCarsRequestDTO
   */
  damageType?: Array<DamageType>;
  /**
   *
   * @type {boolean}
   * @memberof ExportCarsRequestDTO
   */
  directOffer?: boolean;
  /**
   *
   * @type {DateFilter}
   * @memberof ExportCarsRequestDTO
   */
  endDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof ExportCarsRequestDTO
   */
  endDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof ExportCarsRequestDTO
   */
  endDateTo?: string;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   * @deprecated
   */
  engineCo2Max?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   * @deprecated
   */
  engineCo2Min?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  engineCo2NedcMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  engineCo2NedcMin?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  engineCo2WltpMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  engineCo2WltpMin?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  onlineTimeInMinutesMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  onlineTimeInMinutesMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  equipmentCodes?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof ExportCarsRequestDTO
   */
  equipments?: Array<EquipmentHighlight>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof ExportCarsRequestDTO
   */
  expiredBidState?: BidFilterStatus;
  /**
   *
   * @type {string}
   * @memberof ExportCarsRequestDTO
   */
  firstSeenFrom?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExportCarsRequestDTO
   */
  hasAnyOtherUserComment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExportCarsRequestDTO
   */
  hasUserComment?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  kvps?: Array<string>;
  /**
   *
   * @type {Array<MarketingChannel>}
   * @memberof ExportCarsRequestDTO
   */
  marketingChannel?: Array<MarketingChannel>;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  mileageMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  mileageMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  normalizedBrand?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  normalizedEquipmentLine?: Array<string>;
  /**
   *
   * @type {Array<NormalizedFuel>}
   * @memberof ExportCarsRequestDTO
   */
  normalizedFuel?: Array<NormalizedFuel>;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  normalizedModel?: Array<string>;
  /**
   *
   * @type {Array<NormalizedTransmission>}
   * @memberof ExportCarsRequestDTO
   */
  normalizedTransmission?: Array<NormalizedTransmission>;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  normalizedVersion?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  numAuctionsMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  numAuctionsMin?: number;
  /**
   *
   * @type {OfferType}
   * @memberof ExportCarsRequestDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceAverageRelativeMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinAverageRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  potentialInternetPriceMinRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  powerKwMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  powerKwMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  priceDamageMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  priceDamageMin?: MonetaryAmount;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  purchasePriceGrossMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ExportCarsRequestDTO
   */
  purchasePriceGrossMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  ratingMax?: number;
  /**
   *
   * @type {number}
   * @memberof ExportCarsRequestDTO
   */
  ratingMin?: number;
  /**
   *
   * @type {string}
   * @memberof ExportCarsRequestDTO
   */
  registrationDateMax?: string;
  /**
   *
   * @type {string}
   * @memberof ExportCarsRequestDTO
   */
  registrationDateMin?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExportCarsRequestDTO
   */
  searchExact?: boolean;
  /**
   *
   * @type {Array<SourceType>}
   * @memberof ExportCarsRequestDTO
   */
  source?: Array<SourceType>;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportCarsRequestDTO
   */
  originSource?: Array<string>;
  /**
   *
   * @type {DateFilter}
   * @memberof ExportCarsRequestDTO
   */
  startDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof ExportCarsRequestDTO
   */
  startDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof ExportCarsRequestDTO
   */
  startDateTo?: string;
  /**
   *
   * @type {CurrencyCode}
   * @memberof ExportCarsRequestDTO
   */
  targetCurrency?: CurrencyCode;
  /**
   *
   * @type {Array<TaxType>}
   * @memberof ExportCarsRequestDTO
   */
  taxType?: Array<TaxType>;
  /**
   *
   * @type {ValidityType}
   * @memberof ExportCarsRequestDTO
   */
  validity?: ValidityType;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof ExportCarsRequestDTO
   */
  valuationCountry?: ValuationCountryCode;
  /**
   *
   * @type {PriceType}
   * @memberof ExportCarsRequestDTO
   */
  valuationType?: PriceType;
  /**
   *
   * @type {Array<ExportProperty>}
   * @memberof ExportCarsRequestDTO
   */
  exportProperties?: Array<ExportProperty>;
  /**
   *
   * @type {{ [key: string]: Column; }}
   * @memberof ExportCarsRequestDTO
   */
  columnsAsMap?: { [key: string]: Column };
}
/**
 *
 * @export
 * @interface ExportProperty
 */
export interface ExportProperty {
  /**
   *
   * @type {boolean}
   * @memberof ExportProperty
   */
  exportIcon?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExportProperty
   */
  exportText?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExportProperty
   */
  label?: string;
  /**
   *
   * @type {ExportCarProperty}
   * @memberof ExportProperty
   */
  name?: ExportCarProperty;
  /**
   *
   * @type {Array<ExportPropertyKeyValue>}
   * @memberof ExportProperty
   */
  valueLabels?: Array<ExportPropertyKeyValue>;
}
/**
 *
 * @export
 * @interface ExportPropertyKeyValue
 */
export interface ExportPropertyKeyValue {
  /**
   *
   * @type {string}
   * @memberof ExportPropertyKeyValue
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof ExportPropertyKeyValue
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ExtendedUserDTO
 */
export interface ExtendedUserDTO {
  /**
   *
   * @type {DealerId}
   * @memberof ExtendedUserDTO
   */
  dealer?: DealerId;
  /**
   *
   * @type {string}
   * @memberof ExtendedUserDTO
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedUserDTO
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedUserDTO
   */
  lastName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ExtendedUserDTO
   */
  roles?: Array<string>;
  /**
   *
   * @type {UserId}
   * @memberof ExtendedUserDTO
   */
  user?: UserId;
  /**
   *
   * @type {Array<SourceType>}
   * @memberof ExtendedUserDTO
   */
  allowedSources?: Array<SourceType>;
}
/**
 *
 * @export
 * @interface ExternalSearchAgentNotificationDTO
 */
export interface ExternalSearchAgentNotificationDTO {
  /**
   *
   * @type {boolean}
   * @memberof ExternalSearchAgentNotificationDTO
   */
  active?: boolean;
  /**
   *
   * @type {NotificationChannel}
   * @memberof ExternalSearchAgentNotificationDTO
   */
  channel?: NotificationChannel;
  /**
   *
   * @type {string}
   * @memberof ExternalSearchAgentNotificationDTO
   */
  link?: string;
}
/**
 *
 * @export
 * @interface ExternalSearchAgentWithNotificationDTO
 */
export interface ExternalSearchAgentWithNotificationDTO {
  /**
   *
   * @type {string}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  expires?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  kvps?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  name?: string;
  /**
   *
   * @type {SourceType}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  source?: SourceType;
  /**
   *
   * @type {string}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  userName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  id?: string;
  /**
   *
   * @type {SearchAgentRole}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  role?: SearchAgentRole;
  /**
   *
   * @type {ExternalSearchAgentNotificationDTO}
   * @memberof ExternalSearchAgentWithNotificationDTO
   */
  notification?: ExternalSearchAgentNotificationDTO;
}
/**
 *
 * @export
 * @interface FacetedSearchResult
 */
export interface FacetedSearchResult {
  /**
   *
   * @type {string}
   * @memberof FacetedSearchResult
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof FacetedSearchResult
   */
  resultCount?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum FirstCallType {
  Own = 'OWN',
  Other = 'OTHER',
  CentralAndInternational = 'CENTRAL_AND_INTERNATIONAL',
  None = 'NONE',
}

/**
 *
 * @export
 * @interface Gallery360DegreeImagesDTO
 */
export interface Gallery360DegreeImagesDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof Gallery360DegreeImagesDTO
   */
  interior?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Gallery360DegreeImagesDTO
   */
  exterior?: Array<string>;
}
/**
 *
 * @export
 * @interface GalleryDTO
 */
export interface GalleryDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof GalleryDTO
   */
  imagesDetail?: Array<string>;
  /**
   *
   * @type {Gallery360DegreeImagesDTO}
   * @memberof GalleryDTO
   */
  images360Degree?: Gallery360DegreeImagesDTO;
  /**
   *
   * @type {Array<string>}
   * @memberof GalleryDTO
   */
  imagesCarConfigurator?: Array<string>;
}
/**
 *
 * @export
 * @interface GlobalCostsDTO
 */
export interface GlobalCostsDTO {
  /**
   *
   * @type {number}
   * @memberof GlobalCostsDTO
   */
  contributionMarginPercent?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof GlobalCostsDTO
   */
  costs?: MonetaryAmount;
  /**
   *
   * @type {{ [key: string]: ContributionMarginAndCostsDTO; }}
   * @memberof GlobalCostsDTO
   */
  costsPerCountry?: { [key: string]: ContributionMarginAndCostsDTO };
}
/**
 *
 * @export
 * @enum {string}
 */

export enum HandbookLanguage {
  En = 'EN',
  De = 'DE',
  Fr = 'FR',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum HandbookType {
  FirstSteps = 'FIRST_STEPS',
  Configuration = 'CONFIGURATION',
  SearchAgent = 'SEARCH_AGENT',
  CarView = 'CAR_VIEW',
  FeedbackTool = 'FEEDBACK_TOOL',
}

/**
 *
 * @export
 * @interface HistoricBidIndividualBid
 */
export interface HistoricBidIndividualBid {
  /**
   *
   * @type {string}
   * @memberof HistoricBidIndividualBid
   */
  kvps?: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof HistoricBidIndividualBid
   */
  bidStatusMaxAmount?: { [key: string]: number };
}
/**
 *
 * @export
 * @interface HistoricBidWithSendingDealer
 */
export interface HistoricBidWithSendingDealer {
  /**
   *
   * @type {string}
   * @memberof HistoricBidWithSendingDealer
   */
  offerId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof HistoricBidWithSendingDealer
   */
  sendingDealerKvps?: Array<string>;
  /**
   *
   * @type {Array<HistoricBidIndividualBid>}
   * @memberof HistoricBidWithSendingDealer
   */
  individualBids?: Array<HistoricBidIndividualBid>;
  /**
   *
   * @type {string}
   * @memberof HistoricBidWithSendingDealer
   */
  packageId?: string;
  /**
   *
   * @type {BidSuccessStatus}
   * @memberof HistoricBidWithSendingDealer
   */
  status?: BidSuccessStatus;
}
/**
 *
 * @export
 * @interface HistoricBidsReportDTO
 */
export interface HistoricBidsReportDTO {
  [key: string]: Array<HistoricBidWithSendingDealer> | any;

  /**
   *
   * @type {boolean}
   * @memberof HistoricBidsReportDTO
   */
  empty?: boolean;
}
/**
 *
 * @export
 * @interface IncludedCarDTO
 */
export interface IncludedCarDTO {
  /**
   *
   * @type {boolean}
   * @memberof IncludedCarDTO
   */
  awd?: boolean;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  brand?: string;
  /**
   *
   * @type {CountryCode}
   * @memberof IncludedCarDTO
   */
  country?: CountryCode;
  /**
   *
   * @type {number}
   * @memberof IncludedCarDTO
   */
  cylinderCapacity?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof IncludedCarDTO
   */
  equipment?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof IncludedCarDTO
   */
  equipmentHighlight?: Array<EquipmentHighlight>;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  equipmentLine?: string;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  firstRegistration?: string;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  firstVisibleAt?: string;
  /**
   *
   * @type {NormalizedFuel}
   * @memberof IncludedCarDTO
   */
  fuel?: NormalizedFuel;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  image?: string;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  lastVisibleAt?: string;
  /**
   *
   * @type {number}
   * @memberof IncludedCarDTO
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof IncludedCarDTO
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof IncludedCarDTO
   */
  mileage?: number;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof IncludedCarDTO
   */
  online?: boolean;
  /**
   *
   * @type {number}
   * @memberof IncludedCarDTO
   */
  powerKw?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof IncludedCarDTO
   */
  priceLast?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof IncludedCarDTO
   */
  score?: number;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  title?: string;
  /**
   *
   * @type {NormalizedTransmission}
   * @memberof IncludedCarDTO
   */
  transmission?: NormalizedTransmission;
  /**
   *
   * @type {Array<string>}
   * @memberof IncludedCarDTO
   */
  urls?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof IncludedCarDTO
   */
  zip?: string;
}
/**
 *
 * @export
 * @interface IndividualBidDTO
 */
export interface IndividualBidDTO {
  /**
   *
   * @type {MonetaryAmount}
   * @memberof IndividualBidDTO
   */
  allocatedBid?: MonetaryAmount;
  /**
   *
   * @type {UnitEntry}
   * @memberof IndividualBidDTO
   */
  bid?: UnitEntry;
  /**
   *
   * @type {BidType}
   * @memberof IndividualBidDTO
   */
  bidType?: BidType;
  /**
   *
   * @type {string}
   * @memberof IndividualBidDTO
   */
  biddingTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof IndividualBidDTO
   */
  confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof IndividualBidDTO
   */
  created?: string;
  /**
   *
   * @type {DealerId}
   * @memberof IndividualBidDTO
   */
  dealer?: DealerId;
  /**
   *
   * @type {string}
   * @memberof IndividualBidDTO
   */
  kvps?: string;
  /**
   *
   * @type {OfferType}
   * @memberof IndividualBidDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {SourceRegisterPotentialDTO}
   * @memberof IndividualBidDTO
   */
  potential?: SourceRegisterPotentialDTO;
  /**
   *
   * @type {BidSuccessReason}
   * @memberof IndividualBidDTO
   */
  reason?: BidSuccessReason;
  /**
   *
   * @type {string}
   * @memberof IndividualBidDTO
   */
  sentTime?: string;
  /**
   *
   * @type {BidSuccessStatus}
   * @memberof IndividualBidDTO
   */
  status?: BidSuccessStatus;
  /**
   *
   * @type {BidSuccessStatus}
   * @memberof IndividualBidDTO
   */
  statusBeforeDelete?: BidSuccessStatus;
  /**
   *
   * @type {UserIdAndName}
   * @memberof IndividualBidDTO
   */
  user?: UserIdAndName;
  /**
   *
   * @type {number}
   * @memberof IndividualBidDTO
   */
  delay?: number;
  /**
   *
   * @type {string}
   * @memberof IndividualBidDTO
   */
  errorMessage?: string;
  /**
   *
   * @type {DeliveryAddressDTO}
   * @memberof IndividualBidDTO
   */
  deliveryAddress?: DeliveryAddressDTO;
  /**
   *
   * @type {string}
   * @memberof IndividualBidDTO
   */
  originalBiddingTime?: string;
  /**
   *
   * @type {UserIdAndName}
   * @memberof IndividualBidDTO
   */
  overridingAdmin?: UserIdAndName;
  /**
   *
   * @type {boolean}
   * @memberof IndividualBidDTO
   */
  autoRepeatBidOnSubsequentOffer?: boolean;
  /**
   *
   * @type {BidAutomationType}
   * @memberof IndividualBidDTO
   */
  automatedBy?: BidAutomationType;
  /**
   *
   * @type {boolean}
   * @memberof IndividualBidDTO
   */
  overridingFirstCallCascade?: boolean;
}
/**
 *
 * @export
 * @interface IndividualBidStateInfoDTO
 */
export interface IndividualBidStateInfoDTO {
  /**
   *
   * @type {Array<IndividualBidDTO>}
   * @memberof IndividualBidStateInfoDTO
   */
  allHistoricBids?: Array<IndividualBidDTO>;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof IndividualBidStateInfoDTO
   */
  highestHistoricBid?: MonetaryAmount;
  /**
   *
   * @type {Array<IndividualBidDTO>}
   * @memberof IndividualBidStateInfoDTO
   */
  otherCurrentBids?: Array<IndividualBidDTO>;
  /**
   *
   * @type {Array<IndividualBidDTO>}
   * @memberof IndividualBidStateInfoDTO
   */
  ownCurrentBids?: Array<IndividualBidDTO>;
  /**
   *
   * @type {boolean}
   * @memberof IndividualBidStateInfoDTO
   */
  autoRepeatBidOnSubsequentOffer?: boolean;
}
/**
 *
 * @export
 * @interface IndividualizedCarDTO
 */
export interface IndividualizedCarDTO {
  /**
   *
   * @type {string}
   * @memberof IndividualizedCarDTO
   */
  carId?: string;
  /**
   *
   * @type {string}
   * @memberof IndividualizedCarDTO
   */
  created?: string;
  /**
   *
   * @type {DealerId}
   * @memberof IndividualizedCarDTO
   */
  dealer?: DealerId;
  /**
   *
   * @type {number}
   * @memberof IndividualizedCarDTO
   */
  engineCo2?: number;
  /**
   *
   * @type {UnitEntry}
   * @memberof IndividualizedCarDTO
   */
  priceDamage?: UnitEntry;
  /**
   *
   * @type {number}
   * @memberof IndividualizedCarDTO
   */
  rating?: number;
  /**
   *
   * @type {SourceType}
   * @memberof IndividualizedCarDTO
   */
  source?: SourceType;
  /**
   *
   * @type {UserIdAndName}
   * @memberof IndividualizedCarDTO
   */
  user?: UserIdAndName;
  /**
   *
   * @type {string}
   * @memberof IndividualizedCarDTO
   */
  userComment?: string;
}
/**
 *
 * @export
 * @interface KvpsDTO
 */
export interface KvpsDTO {
  /**
   *
   * @type {string}
   * @memberof KvpsDTO
   */
  kvps?: string;
  /**
   *
   * @type {KvpsType}
   * @memberof KvpsDTO
   */
  type?: KvpsType;
  /**
   *
   * @type {string}
   * @memberof KvpsDTO
   */
  displayName?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum KvpsType {
  Central = 'CENTRAL',
  Dealer = 'DEALER',
  Kvps = 'KVPS',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum LOTNormalizedColor {
  Beige = 'BEIGE',
  Black = 'BLACK',
  Blue = 'BLUE',
  Bronze = 'BRONZE',
  Brown = 'BROWN',
  Gold = 'GOLD',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Red = 'RED',
  Silver = 'SILVER',
  Unknown = 'UNKNOWN',
  Violet = 'VIOLET',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum LanguageCode {
  Undefined = 'undefined',
  Aa = 'aa',
  Ab = 'ab',
  Ae = 'ae',
  Af = 'af',
  Ak = 'ak',
  Am = 'am',
  An = 'an',
  Ar = 'ar',
  As = 'as',
  Av = 'av',
  Ay = 'ay',
  Az = 'az',
  Ba = 'ba',
  Be = 'be',
  Bg = 'bg',
  Bh = 'bh',
  Bi = 'bi',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ce = 'ce',
  Ch = 'ch',
  Co = 'co',
  Cr = 'cr',
  Cs = 'cs',
  Cu = 'cu',
  Cv = 'cv',
  Cy = 'cy',
  Da = 'da',
  De = 'de',
  Dv = 'dv',
  Dz = 'dz',
  Ee = 'ee',
  El = 'el',
  En = 'en',
  Eo = 'eo',
  Es = 'es',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Ff = 'ff',
  Fi = 'fi',
  Fj = 'fj',
  Fo = 'fo',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  Gn = 'gn',
  Gu = 'gu',
  Gv = 'gv',
  Ha = 'ha',
  He = 'he',
  Hi = 'hi',
  Ho = 'ho',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Hy = 'hy',
  Hz = 'hz',
  Ia = 'ia',
  Id = 'id',
  Ie = 'ie',
  Ig = 'ig',
  Ii = 'ii',
  Ik = 'ik',
  Io = 'io',
  Is = 'is',
  It = 'it',
  Iu = 'iu',
  Ja = 'ja',
  Jv = 'jv',
  Ka = 'ka',
  Kg = 'kg',
  Ki = 'ki',
  Kj = 'kj',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Kr = 'kr',
  Ks = 'ks',
  Ku = 'ku',
  Kv = 'kv',
  Kw = 'kw',
  Ky = 'ky',
  La = 'la',
  Lb = 'lb',
  Lg = 'lg',
  Li = 'li',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Mg = 'mg',
  Mh = 'mh',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Na = 'na',
  Nb = 'nb',
  Nd = 'nd',
  Ne = 'ne',
  Ng = 'ng',
  Nl = 'nl',
  Nn = 'nn',
  No = 'no',
  Nr = 'nr',
  Nv = 'nv',
  Ny = 'ny',
  Oc = 'oc',
  Oj = 'oj',
  Om = 'om',
  Or = 'or',
  Os = 'os',
  Pa = 'pa',
  Pi = 'pi',
  Pl = 'pl',
  Ps = 'ps',
  Pt = 'pt',
  Qu = 'qu',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sc = 'sc',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  Tl = 'tl',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Ts = 'ts',
  Tt = 'tt',
  Tw = 'tw',
  Ty = 'ty',
  Ug = 'ug',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Ve = 've',
  Vi = 'vi',
  Vo = 'vo',
  Wa = 'wa',
  Wo = 'wo',
  Xh = 'xh',
  Yi = 'yi',
  Yo = 'yo',
  Za = 'za',
  Zh = 'zh',
  Zu = 'zu',
}

/**
 *
 * @export
 * @interface ListBidDTO
 */
export interface ListBidDTO {
  /**
   *
   * @type {string}
   * @memberof ListBidDTO
   */
  biddingTime?: string;
  /**
   *
   * @type {Array<IndividualBidDTO>}
   * @memberof ListBidDTO
   */
  bids?: Array<IndividualBidDTO>;
  /**
   *
   * @type {string}
   * @memberof ListBidDTO
   */
  boughtTime?: string;
  /**
   *
   * @type {Array<ListCarDTO>}
   * @memberof ListBidDTO
   */
  cars?: Array<ListCarDTO>;
  /**
   *
   * @type {string}
   * @memberof ListBidDTO
   */
  created?: string;
  /**
   *
   * @type {DeliveryAddressDTO}
   * @memberof ListBidDTO
   */
  deliveryAddress?: DeliveryAddressDTO;
  /**
   *
   * @type {string}
   * @memberof ListBidDTO
   */
  deliveryAddressChangeUntil?: string;
  /**
   *
   * @type {CarDetailing}
   * @memberof ListBidDTO
   */
  detailing?: CarDetailing;
  /**
   *
   * @type {DeliveryAddressDTO}
   * @memberof ListBidDTO
   */
  detailingAddress?: DeliveryAddressDTO;
  /**
   *
   * @type {string}
   * @memberof ListBidDTO
   */
  errorMessage?: string;
  /**
   *
   * @type {string}
   * @memberof ListBidDTO
   */
  id?: string;
  /**
   *
   * @type {OfferType}
   * @memberof ListBidDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {string}
   * @memberof ListBidDTO
   */
  sentTime?: string;
  /**
   *
   * @type {BidSuccessStatus}
   * @memberof ListBidDTO
   */
  successStatus?: BidSuccessStatus;
}
/**
 *
 * @export
 * @interface ListCarDTO
 */
export interface ListCarDTO {
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  assessmentLink?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  auctionId?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  availability?: string;
  /**
   *
   * @type {IndividualBidStateInfoDTO}
   * @memberof ListCarDTO
   */
  bidStateInfo?: IndividualBidStateInfoDTO;
  /**
   *
   * @type {BidSuccessStatus}
   * @memberof ListCarDTO
   */
  bidSuccessStatus?: BidSuccessStatus;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  brandTechdat?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  carId?: string;
  /**
   *
   * @type {Co2ApproximationMethod}
   * @memberof ListCarDTO
   */
  co2ApproximationMethod?: Co2ApproximationMethod;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  color?: string;
  /**
   *
   * @type {NormalizedColor}
   * @memberof ListCarDTO
   */
  colorId?: NormalizedColor;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  constructionYear?: number;
  /**
   *
   * @type {CountryCode}
   * @memberof ListCarDTO
   */
  country?: CountryCode;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  countryOrigin?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  created?: string;
  /**
   *
   * @type {Array<DamageType>}
   * @memberof ListCarDTO
   */
  damageType?: Array<DamageType>;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  dateBuyOpening?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  dateEnd?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  dateStart?: string;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  doors?: number;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  engineCo2?: number;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  engineCo2Nedc?: number;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  engineCo2Wltp?: number;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  engineFuel?: string;
  /**
   *
   * @type {UnitEntry}
   * @memberof ListCarDTO
   */
  enginePower?: UnitEntry;
  /**
   *
   * @type {Array<string>}
   * @memberof ListCarDTO
   */
  equipment?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof ListCarDTO
   */
  equipmentKeys?: Array<EquipmentHighlight>;
  /**
   *
   * @type {FirstCallType}
   * @memberof ListCarDTO
   */
  firstCallType?: FirstCallType;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  firstRegistration?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  imageLink?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ListCarDTO
   */
  imageLinks?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  imageThumbnailLink?: string;
  /**
   *
   * @type {Array<IndividualizedCarDTO>}
   * @memberof ListCarDTO
   */
  individualizations?: Array<IndividualizedCarDTO>;
  /**
   *
   * @type {Array<KvpsDTO>}
   * @memberof ListCarDTO
   */
  kvps?: Array<KvpsDTO>;
  /**
   *
   * @type {Array<MarketingChannel>}
   * @memberof ListCarDTO
   */
  marketingChannel?: Array<MarketingChannel>;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  mileage?: number;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  modelSpecification?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  modelTechdat?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  modelcode?: string;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  modelyear?: number;
  /**
   *
   * @type {SourceRegisterNormalizedDTO}
   * @memberof ListCarDTO
   */
  normalized?: SourceRegisterNormalizedDTO;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  nova?: number;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  numAuctions?: number;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  offerId?: string;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  onlineTimeInMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  originSource?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  packageId?: string;
  /**
   *
   * @type {SourceRegisterPotentialDTO}
   * @memberof ListCarDTO
   */
  potential?: SourceRegisterPotentialDTO;
  /**
   *
   * @type {{ [key: string]: SourceRegisterPotentialDTO; }}
   * @memberof ListCarDTO
   */
  potentials?: { [key: string]: SourceRegisterPotentialDTO };
  /**
   *
   * @type {Array<DetailCarPreviousAuctionDTO>}
   * @memberof ListCarDTO
   */
  previousAuctions?: Array<DetailCarPreviousAuctionDTO>;
  /**
   *
   * @type {UnitEntry}
   * @memberof ListCarDTO
   */
  priceAuction?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof ListCarDTO
   */
  priceBid?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof ListCarDTO
   */
  priceDamage?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof ListCarDTO
   */
  priceFixed?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof ListCarDTO
   */
  priceOrientation?: UnitEntry;
  /**
   *
   * @type {UnitEntry}
   * @memberof ListCarDTO
   */
  pricePackage?: UnitEntry;
  /**
   *
   * @type {Array<string>}
   * @memberof ListCarDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  rating?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ListCarDTO
   */
  recommendedRetailPrice?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ListCarDTO
   */
  recommendedRetailPriceDeductionAbsolute?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  recommendedRetailPriceDeductionRelative?: number;
  /**
   *
   * @type {number}
   * @memberof ListCarDTO
   */
  seats?: number;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  shape?: string;
  /**
   *
   * @type {SourceType}
   * @memberof ListCarDTO
   */
  source?: SourceType;
  /**
   *
   * @type {Array<SourceType>}
   * @memberof ListCarDTO
   */
  virtualSources?: Array<SourceType>;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  statusReportLink?: string;
  /**
   *
   * @type {TaxContainer}
   * @memberof ListCarDTO
   */
  tax?: TaxContainer;
  /**
   *
   * @type {TaxType}
   * @memberof ListCarDTO
   */
  taxType?: TaxType;
  /**
   *
   * @type {{ [key: string]: TaxContainer; }}
   * @memberof ListCarDTO
   */
  taxes?: { [key: string]: TaxContainer };
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  transmission?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  userComment?: string;
  /**
   *
   * @type {ValidityType}
   * @memberof ListCarDTO
   */
  validity?: ValidityType;
  /**
   *
   * @type {SourceRegisterValuationDTO}
   * @memberof ListCarDTO
   */
  valuation?: SourceRegisterValuationDTO;
  /**
   *
   * @type {{ [key: string]: SourceRegisterValuationDTO; }}
   * @memberof ListCarDTO
   */
  valuations?: { [key: string]: SourceRegisterValuationDTO };
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof ListCarDTO
   */
  zip?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum MarketingChannel {
  Fixed = 'FIXED',
  Bid = 'BID',
  Auction = 'AUCTION',
}

/**
 *
 * @export
 * @interface MonetaryAmount
 */
export interface MonetaryAmount {
  /**
   *
   * @type {number}
   * @memberof MonetaryAmount
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof MonetaryAmount
   */
  currency?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum NormalizedColor {
  Sw = 'SW',
  Gr = 'GR',
  Bl = 'BL',
  Ws = 'WS',
  Si = 'SI',
  Rt = 'RT',
  Br = 'BR',
  Bg = 'BG',
  Ge = 'GE',
  Or = 'OR',
  Gn = 'GN',
  Go = 'GO',
  Unknown = 'UNKNOWN',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum NormalizedFuel {
  Diesel = 'DIESEL',
  DieselHybrid = 'DIESEL_HYBRID',
  Elektro = 'ELEKTRO',
  Gas = 'GAS',
  Hybrid = 'HYBRID',
  Hydrogen = 'HYDROGEN',
  Petrol = 'PETROL',
  PetrolHybrid = 'PETROL_HYBRID',
  Unknown = 'UNKNOWN',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum NormalizedTransmission {
  Automat = 'AUTOMAT',
  Manual = 'MANUAL',
  Unknown = 'UNKNOWN',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum NotificationChannel {
  EMail = 'E_MAIL',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum OfferType {
  All = 'ALL',
  Individual = 'INDIVIDUAL',
  Package = 'PACKAGE',
}

/**
 *
 * @export
 * @interface OnlineCarsRequestDTO
 */
export interface OnlineCarsRequestDTO {
  /**
   *
   * @type {Array<Column>}
   * @memberof OnlineCarsRequestDTO
   */
  columns?: Array<Column>;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  draw?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  length?: number;
  /**
   *
   * @type {Array<Order>}
   * @memberof OnlineCarsRequestDTO
   */
  order?: Array<Order>;
  /**
   *
   * @type {Search}
   * @memberof OnlineCarsRequestDTO
   */
  search?: Search;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  anyOtherUserRatingMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  anyOtherUserRatingMin?: number;
  /**
   *
   * @type {BuyableStatus}
   * @memberof OnlineCarsRequestDTO
   */
  buyableStatus?: BuyableStatus;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  carIds?: Array<string>;
  /**
   *
   * @type {Array<NormalizedColor>}
   * @memberof OnlineCarsRequestDTO
   */
  colors?: Array<NormalizedColor>;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  countryOrigin?: Array<string>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof OnlineCarsRequestDTO
   */
  currentBidState?: BidFilterStatus;
  /**
   *
   * @type {Array<DamageType>}
   * @memberof OnlineCarsRequestDTO
   */
  damageType?: Array<DamageType>;
  /**
   *
   * @type {boolean}
   * @memberof OnlineCarsRequestDTO
   */
  directOffer?: boolean;
  /**
   *
   * @type {DateFilter}
   * @memberof OnlineCarsRequestDTO
   */
  endDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof OnlineCarsRequestDTO
   */
  endDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof OnlineCarsRequestDTO
   */
  endDateTo?: string;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   * @deprecated
   */
  engineCo2Max?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   * @deprecated
   */
  engineCo2Min?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  engineCo2NedcMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  engineCo2NedcMin?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  engineCo2WltpMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  engineCo2WltpMin?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  onlineTimeInMinutesMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  onlineTimeInMinutesMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  equipmentCodes?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof OnlineCarsRequestDTO
   */
  equipments?: Array<EquipmentHighlight>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof OnlineCarsRequestDTO
   */
  expiredBidState?: BidFilterStatus;
  /**
   *
   * @type {string}
   * @memberof OnlineCarsRequestDTO
   */
  firstSeenFrom?: string;
  /**
   *
   * @type {boolean}
   * @memberof OnlineCarsRequestDTO
   */
  hasAnyOtherUserComment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OnlineCarsRequestDTO
   */
  hasUserComment?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  kvps?: Array<string>;
  /**
   *
   * @type {Array<MarketingChannel>}
   * @memberof OnlineCarsRequestDTO
   */
  marketingChannel?: Array<MarketingChannel>;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  mileageMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  mileageMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  normalizedBrand?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  normalizedEquipmentLine?: Array<string>;
  /**
   *
   * @type {Array<NormalizedFuel>}
   * @memberof OnlineCarsRequestDTO
   */
  normalizedFuel?: Array<NormalizedFuel>;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  normalizedModel?: Array<string>;
  /**
   *
   * @type {Array<NormalizedTransmission>}
   * @memberof OnlineCarsRequestDTO
   */
  normalizedTransmission?: Array<NormalizedTransmission>;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  normalizedVersion?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  numAuctionsMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  numAuctionsMin?: number;
  /**
   *
   * @type {OfferType}
   * @memberof OnlineCarsRequestDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceAverageRelativeMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinAverageRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  potentialInternetPriceMinRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  powerKwMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  powerKwMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  priceDamageMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  priceDamageMin?: MonetaryAmount;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  purchasePriceGrossMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof OnlineCarsRequestDTO
   */
  purchasePriceGrossMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  ratingMax?: number;
  /**
   *
   * @type {number}
   * @memberof OnlineCarsRequestDTO
   */
  ratingMin?: number;
  /**
   *
   * @type {string}
   * @memberof OnlineCarsRequestDTO
   */
  registrationDateMax?: string;
  /**
   *
   * @type {string}
   * @memberof OnlineCarsRequestDTO
   */
  registrationDateMin?: string;
  /**
   *
   * @type {boolean}
   * @memberof OnlineCarsRequestDTO
   */
  searchExact?: boolean;
  /**
   *
   * @type {Array<SourceType>}
   * @memberof OnlineCarsRequestDTO
   */
  source?: Array<SourceType>;
  /**
   *
   * @type {Array<string>}
   * @memberof OnlineCarsRequestDTO
   */
  originSource?: Array<string>;
  /**
   *
   * @type {DateFilter}
   * @memberof OnlineCarsRequestDTO
   */
  startDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof OnlineCarsRequestDTO
   */
  startDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof OnlineCarsRequestDTO
   */
  startDateTo?: string;
  /**
   *
   * @type {CurrencyCode}
   * @memberof OnlineCarsRequestDTO
   */
  targetCurrency?: CurrencyCode;
  /**
   *
   * @type {Array<TaxType>}
   * @memberof OnlineCarsRequestDTO
   */
  taxType?: Array<TaxType>;
  /**
   *
   * @type {ValidityType}
   * @memberof OnlineCarsRequestDTO
   */
  validity?: ValidityType;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof OnlineCarsRequestDTO
   */
  valuationCountry?: ValuationCountryCode;
  /**
   *
   * @type {PriceType}
   * @memberof OnlineCarsRequestDTO
   */
  valuationType?: PriceType;
  /**
   *
   * @type {{ [key: string]: Column; }}
   * @memberof OnlineCarsRequestDTO
   */
  columnsAsMap?: { [key: string]: Column };
}
/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {number}
   * @memberof Order
   */
  column: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  dir: string;
}
/**
 *
 * @export
 * @interface PacketCreateDTO
 */
export interface PacketCreateDTO {
  /**
   *
   * @type {string}
   * @memberof PacketCreateDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PacketCreateDTO
   */
  description?: string;
  /**
   *
   * @type {Array<PositionDTO>}
   * @memberof PacketCreateDTO
   */
  positions?: Array<PositionDTO>;
  /**
   *
   * @type {number}
   * @memberof PacketCreateDTO
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof PacketCreateDTO
   */
  clientId?: string;
  /**
   *
   * @type {CountryCode}
   * @memberof PacketCreateDTO
   */
  country: CountryCode;
  /**
   *
   * @type {SourceType}
   * @memberof PacketCreateDTO
   */
  source: SourceType;
}
/**
 *
 * @export
 * @interface PacketDTO
 */
export interface PacketDTO {
  /**
   *
   * @type {string}
   * @memberof PacketDTO
   */
  packetId: string;
  /**
   *
   * @type {string}
   * @memberof PacketDTO
   */
  clientId?: string;
  /**
   *
   * @type {string}
   * @memberof PacketDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PacketDTO
   */
  description?: string;
  /**
   *
   * @type {CountryCode}
   * @memberof PacketDTO
   */
  country: CountryCode;
  /**
   *
   * @type {SourceType}
   * @memberof PacketDTO
   */
  source: SourceType;
  /**
   *
   * @type {Array<PositionDTO>}
   * @memberof PacketDTO
   */
  positions?: Array<PositionDTO>;
  /**
   *
   * @type {number}
   * @memberof PacketDTO
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof PacketDTO
   */
  created?: string;
}
/**
 *
 * @export
 * @interface PacketUpdateDTO
 */
export interface PacketUpdateDTO {
  /**
   *
   * @type {string}
   * @memberof PacketUpdateDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PacketUpdateDTO
   */
  description?: string;
  /**
   *
   * @type {Array<PositionDTO>}
   * @memberof PacketUpdateDTO
   */
  positions?: Array<PositionDTO>;
  /**
   *
   * @type {number}
   * @memberof PacketUpdateDTO
   */
  order?: number;
}
/**
 *
 * @export
 * @interface PlatformCredentialsDTO
 */
export interface PlatformCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsDTO
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsDTO
   */
  password: string;
  /**
   *
   * @type {PlatformCredentialsMetadataDTO}
   * @memberof PlatformCredentialsDTO
   */
  metadata: PlatformCredentialsMetadataDTO;
  /**
   *
   * @type {SourceType}
   * @memberof PlatformCredentialsDTO
   */
  source: SourceType;
}
/**
 *
 * @export
 * @interface PlatformCredentialsMetadataDTO
 */
export interface PlatformCredentialsMetadataDTO {
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTO
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTO
   */
  defaultDeliveryAddressId?: string;
}
/**
 *
 * @export
 * @interface PlatformCredentialsMetadataDTOBasic
 */
export interface PlatformCredentialsMetadataDTOBasic {
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTOBasic
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTOBasic
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTOBasic
   */
  defaultDeliveryAddressId?: string;
}
/**
 *
 * @export
 * @interface PlatformCredentialsMetadataDTOExtended
 */
export interface PlatformCredentialsMetadataDTOExtended {
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTOExtended
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTOExtended
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsMetadataDTOExtended
   */
  defaultDeliveryAddressId?: string;
}
/**
 *
 * @export
 * @interface PlatformCredentialsResponseDTOBasic
 */
export interface PlatformCredentialsResponseDTOBasic {
  /**
   *
   * @type {Array<string>}
   * @memberof PlatformCredentialsResponseDTOBasic
   */
  kvps?: Array<string>;
  /**
   *
   * @type {PlatformCredentialsMetadataDTOBasic}
   * @memberof PlatformCredentialsResponseDTOBasic
   */
  metadata?: PlatformCredentialsMetadataDTOBasic;
  /**
   *
   * @type {SourceTypeBasic}
   * @memberof PlatformCredentialsResponseDTOBasic
   */
  source?: SourceTypeBasic;
  /**
   *
   * @type {UsernameStatusBasic}
   * @memberof PlatformCredentialsResponseDTOBasic
   */
  status: UsernameStatusBasic;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsResponseDTOBasic
   */
  username: string;
}
/**
 *
 * @export
 * @interface PlatformCredentialsResponseDTOExtended
 */
export interface PlatformCredentialsResponseDTOExtended {
  /**
   *
   * @type {Array<string>}
   * @memberof PlatformCredentialsResponseDTOExtended
   */
  kvps?: Array<string>;
  /**
   *
   * @type {PlatformCredentialsMetadataDTOExtended}
   * @memberof PlatformCredentialsResponseDTOExtended
   */
  metadata?: PlatformCredentialsMetadataDTOExtended;
  /**
   *
   * @type {SourceTypeExtended}
   * @memberof PlatformCredentialsResponseDTOExtended
   */
  source?: SourceTypeExtended;
  /**
   *
   * @type {UsernameStatusExtended}
   * @memberof PlatformCredentialsResponseDTOExtended
   */
  status: UsernameStatusExtended;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsResponseDTOExtended
   */
  tokenName?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformCredentialsResponseDTOExtended
   */
  username: string;
}
/**
 *
 * @export
 * @interface PositionDTO
 */
export interface PositionDTO {
  /**
   *
   * @type {string}
   * @memberof PositionDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PositionDTO
   */
  description?: string;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof PositionDTO
   */
  price?: MonetaryAmount;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PriceType {
  OfferPrice = 'OFFER_PRICE',
  PurchaseNetWholesaleUnrepaired = 'PURCHASE_NET_WHOLESALE_UNREPAIRED',
  PurchaseNetRetailUnrepaired = 'PURCHASE_NET_RETAIL_UNREPAIRED',
  PurchaseNetRetailRepaired = 'PURCHASE_NET_RETAIL_REPAIRED',
  PurchaseGross = 'PURCHASE_GROSS',
  PotentialMinAbsolute = 'POTENTIAL_MIN_ABSOLUTE',
  PotentialMinRelative = 'POTENTIAL_MIN_RELATIVE',
  PotentialMinMeanAbsolute = 'POTENTIAL_MIN_MEAN_ABSOLUTE',
  PotentialMinMeanRelative = 'POTENTIAL_MIN_MEAN_RELATIVE',
  PotentialMeanAbsolute = 'POTENTIAL_MEAN_ABSOLUTE',
  PotentialMeanRelative = 'POTENTIAL_MEAN_RELATIVE',
}

/**
 *
 * @export
 * @interface RequestCarValuationDto
 */
export interface RequestCarValuationDto {
  /**
   *
   * @type {SourceType}
   * @memberof RequestCarValuationDto
   */
  source: SourceType;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof RequestCarValuationDto
   */
  country?: ValuationCountryCode;
  /**
   *
   * @type {string}
   * @memberof RequestCarValuationDto
   */
  carId: string;
  /**
   *
   * @type {string}
   * @memberof RequestCarValuationDto
   */
  offerId: string;
}
/**
 *
 * @export
 * @interface ResendNotificationDTO
 */
export interface ResendNotificationDTO {
  /**
   *
   * @type {string}
   * @memberof ResendNotificationDTO
   */
  offerId?: string;
  /**
   *
   * @type {string}
   * @memberof ResendNotificationDTO
   */
  carId?: string;
  /**
   *
   * @type {string}
   * @memberof ResendNotificationDTO
   */
  packageId?: string;
  /**
   *
   * @type {SourceType}
   * @memberof ResendNotificationDTO
   */
  source: SourceType;
  /**
   *
   * @type {Array<ResendNotificationReceiverDTO>}
   * @memberof ResendNotificationDTO
   */
  receiverList?: Array<ResendNotificationReceiverDTO>;
}
/**
 *
 * @export
 * @interface ResendNotificationReceiverDTO
 */
export interface ResendNotificationReceiverDTO {
  /**
   *
   * @type {string}
   * @memberof ResendNotificationReceiverDTO
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof ResendNotificationReceiverDTO
   */
  admin?: boolean;
}
/**
 *
 * @export
 * @interface Search
 */
export interface Search {
  /**
   *
   * @type {boolean}
   * @memberof Search
   */
  regex: boolean;
  /**
   *
   * @type {string}
   * @memberof Search
   */
  value: string;
}
/**
 *
 * @export
 * @interface SearchAgentDTO
 */
export interface SearchAgentDTO {
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentDTO
   */
  active?: boolean;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  anyOtherUserRatingMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  anyOtherUserRatingMin?: number;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  clientId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  carIds?: Array<string>;
  /**
   *
   * @type {Array<NormalizedColor>}
   * @memberof SearchAgentDTO
   */
  colors?: Array<NormalizedColor>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  countryOrigin?: Array<string>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof SearchAgentDTO
   */
  currentBidState?: BidFilterStatus;
  /**
   *
   * @type {Array<DamageType>}
   * @memberof SearchAgentDTO
   */
  damageType?: Array<DamageType>;
  /**
   *
   * @type {DateFilter}
   * @memberof SearchAgentDTO
   */
  endDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  endDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  endDateTo?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  lastModified?: string;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   * @deprecated
   */
  engineCo2Max?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   * @deprecated
   */
  engineCo2Min?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  engineCo2NedcMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  engineCo2NedcMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  engineCo2WltpMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  engineCo2WltpMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  onlineTimeInMinutesMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  onlineTimeInMinutesMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  equipmentCodes?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof SearchAgentDTO
   */
  equipments?: Array<EquipmentHighlight>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof SearchAgentDTO
   */
  expiredBidState?: BidFilterStatus;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentDTO
   */
  favorite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentDTO
   */
  hasAnyOtherUserComment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentDTO
   */
  hasUserComment?: boolean;
  /**
   *
   * @type {Array<MarketingChannel>}
   * @memberof SearchAgentDTO
   */
  marketingChannel?: Array<MarketingChannel>;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  mileageMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  mileageMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  normalizedBrand?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  normalizedEquipmentLine?: Array<string>;
  /**
   *
   * @type {Array<NormalizedFuel>}
   * @memberof SearchAgentDTO
   */
  normalizedFuel?: Array<NormalizedFuel>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  normalizedModel?: Array<string>;
  /**
   *
   * @type {Array<NormalizedTransmission>}
   * @memberof SearchAgentDTO
   */
  normalizedTransmission?: Array<NormalizedTransmission>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  normalizedVersion?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  numAuctionsMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  numAuctionsMin?: number;
  /**
   *
   * @type {OfferType}
   * @memberof SearchAgentDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceAverageRelativeMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinAverageRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  potentialInternetPriceMinRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  powerKwMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  powerKwMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  priceDamageMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  priceDamageMin?: MonetaryAmount;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  purchasePriceGrossMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentDTO
   */
  purchasePriceGrossMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  ratingMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentDTO
   */
  ratingMin?: number;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  registrationDateMax?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  registrationDateMin?: string;
  /**
   *
   * @type {Search}
   * @memberof SearchAgentDTO
   */
  search?: Search;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  searchAgentDisplayName?: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentDTO
   */
  searchExact?: boolean;
  /**
   *
   * @type {Array<SourceType>}
   * @memberof SearchAgentDTO
   */
  source?: Array<SourceType>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentDTO
   */
  originSource?: Array<string>;
  /**
   *
   * @type {DateFilter}
   * @memberof SearchAgentDTO
   */
  startDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  startDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  startDateTo?: string;
  /**
   *
   * @type {Array<TaxType>}
   * @memberof SearchAgentDTO
   */
  taxType?: Array<TaxType>;
  /**
   *
   * @type {ValidityType}
   * @memberof SearchAgentDTO
   */
  validity?: ValidityType;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof SearchAgentDTO
   */
  valuationCountry?: ValuationCountryCode;
  /**
   *
   * @type {PriceType}
   * @memberof SearchAgentDTO
   */
  valuationType?: PriceType;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentDTO
   */
  id?: string;
}
/**
 *
 * @export
 * @interface SearchAgentRequestDTO
 */
export interface SearchAgentRequestDTO {
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentRequestDTO
   */
  active?: boolean;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  anyOtherUserRatingMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  anyOtherUserRatingMin?: number;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  clientId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  carIds?: Array<string>;
  /**
   *
   * @type {Array<NormalizedColor>}
   * @memberof SearchAgentRequestDTO
   */
  colors?: Array<NormalizedColor>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  countryOrigin?: Array<string>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof SearchAgentRequestDTO
   */
  currentBidState?: BidFilterStatus;
  /**
   *
   * @type {Array<DamageType>}
   * @memberof SearchAgentRequestDTO
   */
  damageType?: Array<DamageType>;
  /**
   *
   * @type {DateFilter}
   * @memberof SearchAgentRequestDTO
   */
  endDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  endDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  endDateTo?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  lastModified?: string;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   * @deprecated
   */
  engineCo2Max?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   * @deprecated
   */
  engineCo2Min?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  engineCo2NedcMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  engineCo2NedcMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  engineCo2WltpMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  engineCo2WltpMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  onlineTimeInMinutesMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  onlineTimeInMinutesMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  equipmentCodes?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof SearchAgentRequestDTO
   */
  equipments?: Array<EquipmentHighlight>;
  /**
   *
   * @type {BidFilterStatus}
   * @memberof SearchAgentRequestDTO
   */
  expiredBidState?: BidFilterStatus;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentRequestDTO
   */
  favorite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentRequestDTO
   */
  hasAnyOtherUserComment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentRequestDTO
   */
  hasUserComment?: boolean;
  /**
   *
   * @type {Array<MarketingChannel>}
   * @memberof SearchAgentRequestDTO
   */
  marketingChannel?: Array<MarketingChannel>;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  mileageMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  mileageMin?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  normalizedBrand?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  normalizedEquipmentLine?: Array<string>;
  /**
   *
   * @type {Array<NormalizedFuel>}
   * @memberof SearchAgentRequestDTO
   */
  normalizedFuel?: Array<NormalizedFuel>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  normalizedModel?: Array<string>;
  /**
   *
   * @type {Array<NormalizedTransmission>}
   * @memberof SearchAgentRequestDTO
   */
  normalizedTransmission?: Array<NormalizedTransmission>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  normalizedVersion?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  numAuctionsMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  numAuctionsMin?: number;
  /**
   *
   * @type {OfferType}
   * @memberof SearchAgentRequestDTO
   */
  offerType?: OfferType;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceAverageRelativeMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinAverageAbsoluteMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinAverageAbsoluteMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinAverageRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinAverageRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinRelativeMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  potentialInternetPriceMinRelativeMin?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  powerKwMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  powerKwMin?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  priceDamageMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  priceDamageMin?: MonetaryAmount;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  purchasePriceGrossMax?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SearchAgentRequestDTO
   */
  purchasePriceGrossMin?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  ratingMax?: number;
  /**
   *
   * @type {number}
   * @memberof SearchAgentRequestDTO
   */
  ratingMin?: number;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  registrationDateMax?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  registrationDateMin?: string;
  /**
   *
   * @type {Search}
   * @memberof SearchAgentRequestDTO
   */
  search?: Search;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  searchAgentDisplayName?: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchAgentRequestDTO
   */
  searchExact?: boolean;
  /**
   *
   * @type {Array<SourceType>}
   * @memberof SearchAgentRequestDTO
   */
  source?: Array<SourceType>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchAgentRequestDTO
   */
  originSource?: Array<string>;
  /**
   *
   * @type {DateFilter}
   * @memberof SearchAgentRequestDTO
   */
  startDateFilter?: DateFilter;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  startDateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  startDateTo?: string;
  /**
   *
   * @type {Array<TaxType>}
   * @memberof SearchAgentRequestDTO
   */
  taxType?: Array<TaxType>;
  /**
   *
   * @type {ValidityType}
   * @memberof SearchAgentRequestDTO
   */
  validity?: ValidityType;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof SearchAgentRequestDTO
   */
  valuationCountry?: ValuationCountryCode;
  /**
   *
   * @type {PriceType}
   * @memberof SearchAgentRequestDTO
   */
  valuationType?: PriceType;
  /**
   *
   * @type {string}
   * @memberof SearchAgentRequestDTO
   */
  version?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum SearchAgentRole {
  None = 'NONE',
  ShoppingCart = 'SHOPPING_CART',
}

/**
 *
 * @export
 * @interface SetBidRequestDTO
 */
export interface SetBidRequestDTO {
  /**
   *
   * @type {number}
   * @memberof SetBidRequestDTO
   */
  bid1?: number;
  /**
   *
   * @type {number}
   * @memberof SetBidRequestDTO
   */
  bid2?: number;
  /**
   *
   * @type {number}
   * @memberof SetBidRequestDTO
   */
  bid3?: number;
  /**
   *
   * @type {string}
   * @memberof SetBidRequestDTO
   */
  biddingTime?: string;
  /**
   *
   * @type {string}
   * @memberof SetBidRequestDTO
   */
  carId?: string;
  /**
   *
   * @type {string}
   * @memberof SetBidRequestDTO
   */
  deliveryAddress?: string;
  /**
   *
   * @type {boolean}
   * @memberof SetBidRequestDTO
   */
  fixedPrice?: boolean;
  /**
   *
   * @type {string}
   * @memberof SetBidRequestDTO
   */
  kvps?: string;
  /**
   *
   * @type {string}
   * @memberof SetBidRequestDTO
   */
  packageId?: string;
  /**
   *
   * @type {number}
   * @memberof SetBidRequestDTO
   */
  secondsBeforeEnd?: number;
  /**
   *
   * @type {SourceType}
   * @memberof SetBidRequestDTO
   */
  source: SourceType;
  /**
   *
   * @type {boolean}
   * @memberof SetBidRequestDTO
   */
  overrideFirstCallCascade?: boolean;
  /**
   *
   * @type {DealerId}
   * @memberof SetBidRequestDTO
   */
  overridingFirstCallCascadeDealer?: DealerId;
  /**
   *
   * @type {boolean}
   * @memberof SetBidRequestDTO
   */
  autoRepeatBidOnSubsequentOffer?: boolean;
}
/**
 *
 * @export
 * @interface SourceRegisterCarDetailDamageDTO
 */
export interface SourceRegisterCarDetailDamageDTO {
  /**
   *
   * @type {string}
   * @memberof SourceRegisterCarDetailDamageDTO
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterCarDetailDamageDTO
   */
  repairCost?: number;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterCarDetailDamageDTO
   */
  repairNote?: string;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterCarDetailDamageDTO
   */
  wear?: number;
}
/**
 *
 * @export
 * @interface SourceRegisterNormalizedAugmentedDTO
 */
export interface SourceRegisterNormalizedAugmentedDTO {
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  bodyType?: string;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  co2Nedc?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  co2Wltp?: number;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  consumptionNedc?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  consumptionWltp?: string;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  displacement?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  doors?: number;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  emissionClass?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  equipmentLine?: string;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  powerKw?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterNormalizedAugmentedDTO
   */
  seats?: number;
}
/**
 *
 * @export
 * @interface SourceRegisterNormalizedDTO
 */
export interface SourceRegisterNormalizedDTO {
  /**
   *
   * @type {SourceRegisterNormalizedAugmentedDTO}
   * @memberof SourceRegisterNormalizedDTO
   */
  augmented?: SourceRegisterNormalizedAugmentedDTO;
  /**
   *
   * @type {LOTNormalizedColor}
   * @memberof SourceRegisterNormalizedDTO
   */
  bodyColor?: LOTNormalizedColor;
  /**
   *
   * @type {Array<string>}
   * @memberof SourceRegisterNormalizedDTO
   */
  bodyColorDetail?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  bodyType?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  emissionClass?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SourceRegisterNormalizedDTO
   */
  equipment?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof SourceRegisterNormalizedDTO
   */
  equipmentHighlight?: Array<EquipmentHighlight>;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  equipmentLine?: string;
  /**
   *
   * @type {NormalizedFuel}
   * @memberof SourceRegisterNormalizedDTO
   */
  fuel?: NormalizedFuel;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  fullModelName?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  paintType?: string;
  /**
   *
   * @type {NormalizedTransmission}
   * @memberof SourceRegisterNormalizedDTO
   */
  transmission?: NormalizedTransmission;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterNormalizedDTO
   */
  version?: string;
}
/**
 *
 * @export
 * @interface SourceRegisterPotentialContainerDTO
 */
export interface SourceRegisterPotentialContainerDTO {
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  base?: number;
  /**
   *
   * @type {SourceRegisterPotentialDataDTO}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  carculatorAveragePotential?: SourceRegisterPotentialDataDTO;
  /**
   *
   * @type {SourceRegisterPotentialDataDTO}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  carculatorBetweeenMinAndAveragePotential?: SourceRegisterPotentialDataDTO;
  /**
   *
   * @type {SourceRegisterPotentialDataDTO}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  carculatorMinPlusSurchargePotential?: SourceRegisterPotentialDataDTO;
  /**
   *
   * @type {SourceRegisterPotentialDataDTO}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  carculatorMinPotential?: SourceRegisterPotentialDataDTO;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  country?: ValuationCountryCode;
  /**
   *
   * @type {SourceRegisterPotentialDataDTO}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  eurotaxPurchasePotential?: SourceRegisterPotentialDataDTO;
  /**
   *
   * @type {SourceRegisterPotentialDataDTO}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  eurotaxSellingPotential?: SourceRegisterPotentialDataDTO;
  /**
   *
   * @type {{ [key: string]: SourceRegisterPotentialDTO; }}
   * @memberof SourceRegisterPotentialContainerDTO
   */
  potentialPerCountry?: { [key: string]: SourceRegisterPotentialDTO };
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  purchaseGross?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  purchaseNet?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  purchaseNetRepaired?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  purchaseNetUnrepaired?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  purchaseNetWholesaleUnrepaired?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  requiredDiscount?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialContainerDTO
   * @deprecated
   */
  requiredDiscountPercent?: number;
}
/**
 *
 * @export
 * @interface SourceRegisterPotentialDTO
 */
export interface SourceRegisterPotentialDTO {
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  base?: MonetaryAmount;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof SourceRegisterPotentialDTO
   */
  country?: ValuationCountryCode;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  potentialMeanAbsolute?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialDTO
   */
  potentialMeanRelative?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  potentialMinAbsolute?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  potentialMinMeanAbsolute?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialDTO
   */
  potentialMinMeanRelative?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialDTO
   */
  potentialMinRelative?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  purchaseGross?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  purchaseNetRetailRepaired?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  purchaseNetRetailUnrepaired?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialDTO
   */
  purchaseNetWholesaleUnrepaired?: MonetaryAmount;
  /**
   *
   * @type {{ [key: string]: SourceRegisterPotentialTaxDTO; }}
   * @memberof SourceRegisterPotentialDTO
   */
  tax?: { [key: string]: SourceRegisterPotentialTaxDTO };
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof SourceRegisterPotentialDTO
   */
  taxParameter?: { [key: string]: object };
}
/**
 *
 * @export
 * @interface SourceRegisterPotentialDataDTO
 */
export interface SourceRegisterPotentialDataDTO {
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialDataDTO
   */
  absolute?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialDataDTO
   */
  percent?: number;
}
/**
 *
 * @export
 * @interface SourceRegisterPotentialTaxDTO
 */
export interface SourceRegisterPotentialTaxDTO {
  /**
   *
   * @type {string}
   * @memberof SourceRegisterPotentialTaxDTO
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterPotentialTaxDTO
   */
  percent?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterPotentialTaxDTO
   */
  total?: MonetaryAmount;
}
/**
 *
 * @export
 * @interface SourceRegisterValuationDTO
 */
export interface SourceRegisterValuationDTO {
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationDTO
   */
  averageStandingDaysCurrent?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationDTO
   */
  averageStandingDaysHistoric?: number;
  /**
   *
   * @type {Array<SourceRegisterValuationIncludedCarDTO>}
   * @memberof SourceRegisterValuationDTO
   */
  includedCars?: Array<SourceRegisterValuationIncludedCarDTO>;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationDTO
   */
  numberOfCars?: number;
  /**
   *
   * @type {SourceRegisterValuationPriceDTO}
   * @memberof SourceRegisterValuationDTO
   */
  price?: SourceRegisterValuationPriceDTO;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationDTO
   */
  similarity?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationDTO
   */
  desireFactor?: number;
  /**
   *
   * @type {boolean}
   * @memberof SourceRegisterValuationDTO
   */
  valid?: boolean;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof SourceRegisterValuationDTO
   */
  valuationCountry?: ValuationCountryCode;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationDTO
   */
  valuationDate?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationDTO
   */
  offerId?: string;
}
/**
 *
 * @export
 * @interface SourceRegisterValuationIncludedCarDTO
 */
export interface SourceRegisterValuationIncludedCarDTO {
  /**
   *
   * @type {boolean}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  awd?: boolean;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  brand?: string;
  /**
   *
   * @type {CountryCode}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  country?: CountryCode;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  displacement?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  equipment?: Array<string>;
  /**
   *
   * @type {Array<EquipmentHighlight>}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  equipmentHighlight?: Array<EquipmentHighlight>;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  equipmentLine?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  firstRegistration?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  firstVisibleAt?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  fuel?: string;
  /**
   *
   * @type {NormalizedTransmission}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  gearing?: NormalizedTransmission;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  image?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  lastVisibleAt?: string;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  mileage?: number;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  online?: boolean;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  power?: number;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  priceLast?: MonetaryAmount;
  /**
   *
   * @type {number}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  score?: number;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  title?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  urls?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof SourceRegisterValuationIncludedCarDTO
   */
  zipCode?: string;
}
/**
 *
 * @export
 * @interface SourceRegisterValuationPriceDTO
 */
export interface SourceRegisterValuationPriceDTO {
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterValuationPriceDTO
   */
  max?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterValuationPriceDTO
   */
  mean?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterValuationPriceDTO
   */
  min?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterValuationPriceDTO
   */
  q3?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof SourceRegisterValuationPriceDTO
   */
  recommended?: MonetaryAmount;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum SourceType {
  Arval = 'ARVAL',
  AudiGws = 'AUDI_GWS',
  Auto1 = 'AUTO1',
  Autobid = 'AUTOBID',
  Autorola = 'AUTOROLA',
  Okcars = 'OKCARS',
  RealFirstCallOwn = 'REAL_FIRST_CALL_OWN',
  RealFirstCallOther = 'REAL_FIRST_CALL_OTHER',
  Ucs = 'UCS',
  Vvj = 'VVJ',
  Vwfs = 'VWFS',
  VwfsItVirt = 'VWFS_IT_VIRT',
  VwfsSkVirt = 'VWFS_SK_VIRT',
  VwnGws = 'VWN_GWS',
  SpotdealsAllmobil = 'SPOTDEALS_ALLMOBIL',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum SourceTypeBasic {
  Arval = 'ARVAL',
  AudiGws = 'AUDI_GWS',
  Auto1 = 'AUTO1',
  Autobid = 'AUTOBID',
  Autorola = 'AUTOROLA',
  Okcars = 'OKCARS',
  RealFirstCallOwn = 'REAL_FIRST_CALL_OWN',
  RealFirstCallOther = 'REAL_FIRST_CALL_OTHER',
  Ucs = 'UCS',
  Vvj = 'VVJ',
  Vwfs = 'VWFS',
  VwfsItVirt = 'VWFS_IT_VIRT',
  VwfsSkVirt = 'VWFS_SK_VIRT',
  VwnGws = 'VWN_GWS',
  SpotdealsAllmobil = 'SPOTDEALS_ALLMOBIL',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum SourceTypeExtended {
  Arval = 'ARVAL',
  AudiGws = 'AUDI_GWS',
  Auto1 = 'AUTO1',
  Autobid = 'AUTOBID',
  Autorola = 'AUTOROLA',
  Okcars = 'OKCARS',
  RealFirstCallOwn = 'REAL_FIRST_CALL_OWN',
  RealFirstCallOther = 'REAL_FIRST_CALL_OTHER',
  Ucs = 'UCS',
  Vvj = 'VVJ',
  Vwfs = 'VWFS',
  VwfsItVirt = 'VWFS_IT_VIRT',
  VwfsSkVirt = 'VWFS_SK_VIRT',
  VwnGws = 'VWN_GWS',
  SpotdealsAllmobil = 'SPOTDEALS_ALLMOBIL',
}

/**
 *
 * @export
 * @interface SummaryDTO
 */
export interface SummaryDTO {
  /**
   *
   * @type {Array<EquipmentCodeText>}
   * @memberof SummaryDTO
   */
  equipmentCodeText?: Array<EquipmentCodeText>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryDTO
   */
  normalizedBrand?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryDTO
   */
  normalizedEquipmentLine?: Array<string>;
  /**
   *
   * @type {Array<NormalizedFuel>}
   * @memberof SummaryDTO
   */
  normalizedFuel?: Array<NormalizedFuel>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryDTO
   */
  normalizedModel?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryDTO
   */
  normalizedVersion?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {Array<CountryCode>}
   * @memberof SummaryDTO
   */
  bidCreatorDealerCountry?: Array<CountryCode>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryDTO
   */
  bidCreatorDealerId?: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SummaryDTO
   */
  bidCreatorUserId?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface SummaryRequestDTO
 */
export interface SummaryRequestDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  equipmentCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  normalizedBrand?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  normalizedEquipmentLine?: Array<string>;
  /**
   *
   * @type {Array<NormalizedFuel>}
   * @memberof SummaryRequestDTO
   */
  normalizedFuel?: Array<NormalizedFuel>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  normalizedModel?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  normalizedVersion?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  promotions?: Array<string>;
  /**
   *
   * @type {Array<CountryCode>}
   * @memberof SummaryRequestDTO
   */
  bidCreatorDealerCountry?: Array<CountryCode>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  bidCreatorDealerId?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SummaryRequestDTO
   */
  bidCreatorUserId?: Array<string>;
}
/**
 *
 * @export
 * @interface TaxContainer
 */
export interface TaxContainer {
  /**
   *
   * @type {{ [key: string]: SourceRegisterPotentialTaxDTO; }}
   * @memberof TaxContainer
   */
  tax?: { [key: string]: SourceRegisterPotentialTaxDTO };
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof TaxContainer
   */
  parameters?: { [key: string]: object };
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TaxType {
  Regelbesteuert = 'regelbesteuert',
  Differenzbesteuert = 'differenzbesteuert',
}

/**
 *
 * @export
 * @interface TermsConditionsDTO
 */
export interface TermsConditionsDTO {
  /**
   *
   * @type {TermsConditionsType}
   * @memberof TermsConditionsDTO
   */
  termsType: TermsConditionsType;
  /**
   *
   * @type {number}
   * @memberof TermsConditionsDTO
   */
  version: number;
  /**
   *
   * @type {string}
   * @memberof TermsConditionsDTO
   */
  accepted?: string;
  /**
   *
   * @type {string}
   * @memberof TermsConditionsDTO
   */
  link?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TermsConditionsType {
  TermsConditions = 'TERMS_CONDITIONS',
  VwfsPrivacy = 'VWFS_PRIVACY',
  VwfsTermsConditions = 'VWFS_TERMS_CONDITIONS',
  GwsTermsConditions = 'GWS_TERMS_CONDITIONS',
  AudiGwsTermsConditions = 'AUDI_GWS_TERMS_CONDITIONS',
  VwnGwsTermsConditions = 'VWN_GWS_TERMS_CONDITIONS',
}

/**
 *
 * @export
 * @interface UnitEntry
 */
export interface UnitEntry {
  /**
   *
   * @type {string}
   * @memberof UnitEntry
   */
  unit?: string;
  /**
   *
   * @type {number}
   * @memberof UnitEntry
   */
  value?: number;
}
/**
 *
 * @export
 * @interface UpdateBidAutomationStatusRequestDTO
 */
export interface UpdateBidAutomationStatusRequestDTO {
  /**
   *
   * @type {SourceType}
   * @memberof UpdateBidAutomationStatusRequestDTO
   */
  source: SourceType;
  /**
   *
   * @type {string}
   * @memberof UpdateBidAutomationStatusRequestDTO
   */
  carId: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateBidAutomationStatusRequestDTO
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface UserId
 */
export interface UserId {
  /**
   *
   * @type {AuthenticationProvider}
   * @memberof UserId
   */
  authenticationProvider?: AuthenticationProvider;
  /**
   *
   * @type {string}
   * @memberof UserId
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UserIdAndName
 */
export interface UserIdAndName {
  /**
   *
   * @type {AuthenticationProvider}
   * @memberof UserIdAndName
   */
  authenticationProvider?: AuthenticationProvider;
  /**
   *
   * @type {string}
   * @memberof UserIdAndName
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserIdAndName
   */
  name?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum UsernameStatusBasic {
  Pending = 'PENDING',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

/**
 *
 * @export
 * @interface UsernameStatusDTOBasic
 */
export interface UsernameStatusDTOBasic {
  /**
   *
   * @type {Array<string>}
   * @memberof UsernameStatusDTOBasic
   */
  kvps?: Array<string>;
  /**
   *
   * @type {SourceTypeBasic}
   * @memberof UsernameStatusDTOBasic
   */
  source?: SourceTypeBasic;
  /**
   *
   * @type {UsernameStatusBasic}
   * @memberof UsernameStatusDTOBasic
   */
  status?: UsernameStatusBasic;
  /**
   *
   * @type {string}
   * @memberof UsernameStatusDTOBasic
   */
  username?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum UsernameStatusExtended {
  Pending = 'PENDING',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ValidityType {
  All = 'ALL',
  Current = 'CURRENT',
  Expired = 'EXPIRED',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ValuationCountryCode {
  At = 'AT',
  AtLot = 'AT_LOT',
  De = 'DE',
  Fr = 'FR',
  Es = 'ES',
  It = 'IT',
  Cz = 'CZ',
  Pl = 'PL',
  Ro = 'RO',
  Hu = 'HU',
  Si = 'SI',
  Sk = 'SK',
  Hr = 'HR',
  Pt = 'PT',
  Se = 'SE',
  Bg = 'BG',
  Mk = 'MK',
  Al = 'AL',
  Xk = 'XK',
}

/**
 *
 * @export
 * @interface ValuationPriceDTO
 */
export interface ValuationPriceDTO {
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ValuationPriceDTO
   */
  max?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ValuationPriceDTO
   */
  mean?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ValuationPriceDTO
   */
  min?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ValuationPriceDTO
   */
  q1?: MonetaryAmount;
  /**
   *
   * @type {MonetaryAmount}
   * @memberof ValuationPriceDTO
   */
  recommended?: MonetaryAmount;
}
/**
 *
 * @export
 * @interface ValuationWithIncludedCarsDTO
 */
export interface ValuationWithIncludedCarsDTO {
  /**
   *
   * @type {number}
   * @memberof ValuationWithIncludedCarsDTO
   */
  averageStandingDaysCurrent?: number;
  /**
   *
   * @type {number}
   * @memberof ValuationWithIncludedCarsDTO
   */
  averageStandingDaysHistoric?: number;
  /**
   *
   * @type {number}
   * @memberof ValuationWithIncludedCarsDTO
   */
  numberOfCars?: number;
  /**
   *
   * @type {ValuationPriceDTO}
   * @memberof ValuationWithIncludedCarsDTO
   */
  price?: ValuationPriceDTO;
  /**
   *
   * @type {number}
   * @memberof ValuationWithIncludedCarsDTO
   */
  similarity?: number;
  /**
   *
   * @type {boolean}
   * @memberof ValuationWithIncludedCarsDTO
   */
  valid?: boolean;
  /**
   *
   * @type {ValuationCountryCode}
   * @memberof ValuationWithIncludedCarsDTO
   */
  valuationCountry?: ValuationCountryCode;
  /**
   *
   * @type {string}
   * @memberof ValuationWithIncludedCarsDTO
   */
  valuationDate?: string;
  /**
   *
   * @type {number}
   * @memberof ValuationWithIncludedCarsDTO
   */
  desireFactor?: number;
  /**
   *
   * @type {string}
   * @memberof ValuationWithIncludedCarsDTO
   */
  offerId?: string;
  /**
   *
   * @type {Array<IncludedCarDTO>}
   * @memberof ValuationWithIncludedCarsDTO
   */
  includedCars?: Array<IncludedCarDTO>;
}
/**
 *
 * @export
 * @interface WizardDTO
 */
export interface WizardDTO {
  /**
   *
   * @type {{ [key: string]: WizardStepDTO; }}
   * @memberof WizardDTO
   */
  steps?: { [key: string]: WizardStepDTO };
}
/**
 *
 * @export
 * @enum {string}
 */

export enum WizardStep {
  TermsConditions = 'TERMS_CONDITIONS',
  DealerAdministration = 'DEALER_ADMINISTRATION',
  DealerAddress = 'DEALER_ADDRESS',
  Costs = 'COSTS',
  ConditionalFormatting = 'CONDITIONAL_FORMATTING',
  Instructions = 'INSTRUCTIONS',
}

/**
 *
 * @export
 * @interface WizardStepDTO
 */
export interface WizardStepDTO {
  /**
   *
   * @type {string}
   * @memberof WizardStepDTO
   */
  confirmed?: string;
  /**
   *
   * @type {boolean}
   * @memberof WizardStepDTO
   */
  required?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WizardStepDTO
   */
  show?: boolean;
}

/**
 * AssetsControllerApi - axios parameter creator
 * @export
 */
export const AssetsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {HandbookType} handbookType
     * @param {HandbookLanguage} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assetsControllerGetHandbook: async (
      handbookType: HandbookType,
      lang?: HandbookLanguage,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'handbookType' is not null or undefined
      assertParamExists('assetsControllerGetHandbook', 'handbookType', handbookType);
      const localVarPath = `/api/assets/handbook/{handbookType}.pdf`.replace(
        `{${'handbookType'}}`,
        encodeURIComponent(String(handbookType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {HandbookType} handbookType
     * @param {HandbookLanguage} [lang]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    assetsControllerGetHandbookDeprecatedRoute: async (
      handbookType: HandbookType,
      lang?: HandbookLanguage,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'handbookType' is not null or undefined
      assertParamExists('assetsControllerGetHandbookDeprecatedRoute', 'handbookType', handbookType);
      const localVarPath = `/api/handbook/{handbookType}.pdf`.replace(
        `{${'handbookType'}}`,
        encodeURIComponent(String(handbookType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TermsConditionsType} termsConditions
     * @param {LanguageCode} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assetsControllerGetTermsAndConditions: async (
      termsConditions: TermsConditionsType,
      lang?: LanguageCode,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'termsConditions' is not null or undefined
      assertParamExists('assetsControllerGetTermsAndConditions', 'termsConditions', termsConditions);
      const localVarPath = `/api/assets/terms-conditions/{termsConditions}.pdf`.replace(
        `{${'termsConditions'}}`,
        encodeURIComponent(String(termsConditions)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssetsControllerApi - functional programming interface
 * @export
 */
export const AssetsControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AssetsControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {HandbookType} handbookType
     * @param {HandbookLanguage} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assetsControllerGetHandbook(
      handbookType: HandbookType,
      lang?: HandbookLanguage,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assetsControllerGetHandbook(
        handbookType,
        lang,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {HandbookType} handbookType
     * @param {HandbookLanguage} [lang]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async assetsControllerGetHandbookDeprecatedRoute(
      handbookType: HandbookType,
      lang?: HandbookLanguage,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assetsControllerGetHandbookDeprecatedRoute(
        handbookType,
        lang,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TermsConditionsType} termsConditions
     * @param {LanguageCode} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assetsControllerGetTermsAndConditions(
      termsConditions: TermsConditionsType,
      lang?: LanguageCode,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assetsControllerGetTermsAndConditions(
        termsConditions,
        lang,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AssetsControllerApi - factory interface
 * @export
 */
export const AssetsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssetsControllerApiFp(configuration);
  return {
    /**
     *
     * @param {HandbookType} handbookType
     * @param {HandbookLanguage} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assetsControllerGetHandbook(handbookType: HandbookType, lang?: HandbookLanguage, options?: any): AxiosPromise<any> {
      return localVarFp
        .assetsControllerGetHandbook(handbookType, lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {HandbookType} handbookType
     * @param {HandbookLanguage} [lang]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    assetsControllerGetHandbookDeprecatedRoute(
      handbookType: HandbookType,
      lang?: HandbookLanguage,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .assetsControllerGetHandbookDeprecatedRoute(handbookType, lang, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {TermsConditionsType} termsConditions
     * @param {LanguageCode} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assetsControllerGetTermsAndConditions(
      termsConditions: TermsConditionsType,
      lang?: LanguageCode,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .assetsControllerGetTermsAndConditions(termsConditions, lang, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AssetsControllerApi - object-oriented interface
 * @export
 * @class AssetsControllerApi
 * @extends {BaseAPI}
 */
export class AssetsControllerApi extends BaseAPI {
  /**
   *
   * @param {HandbookType} handbookType
   * @param {HandbookLanguage} [lang]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public assetsControllerGetHandbook(
    handbookType: HandbookType,
    lang?: HandbookLanguage,
    options?: AxiosRequestConfig,
  ) {
    return AssetsControllerApiFp(this.configuration)
      .assetsControllerGetHandbook(handbookType, lang, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {HandbookType} handbookType
   * @param {HandbookLanguage} [lang]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public assetsControllerGetHandbookDeprecatedRoute(
    handbookType: HandbookType,
    lang?: HandbookLanguage,
    options?: AxiosRequestConfig,
  ) {
    return AssetsControllerApiFp(this.configuration)
      .assetsControllerGetHandbookDeprecatedRoute(handbookType, lang, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TermsConditionsType} termsConditions
   * @param {LanguageCode} [lang]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public assetsControllerGetTermsAndConditions(
    termsConditions: TermsConditionsType,
    lang?: LanguageCode,
    options?: AxiosRequestConfig,
  ) {
    return AssetsControllerApiFp(this.configuration)
      .assetsControllerGetTermsAndConditions(termsConditions, lang, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerMe(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedUserDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerMe(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthControllerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerMe(options?: any): AxiosPromise<ExtendedUserDTO> {
      return localVarFp.authControllerMe(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public authControllerMe(options?: AxiosRequestConfig) {
    return AuthControllerApiFp(this.configuration)
      .authControllerMe(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BidNewControllerApi - axios parameter creator
 * @export
 */
export const BidNewControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} bidId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerCancelBid: async (bidId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'bidId' is not null or undefined
      assertParamExists('bidNewControllerCancelBid', 'bidId', bidId);
      const localVarPath = `/api/bids-new/{bidId}`.replace(`{${'bidId'}}`, encodeURIComponent(String(bidId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {boolean} [simulate]
     * @param {number} [secondsBeforeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerHandleFileUpload: async (
      file: any,
      simulate?: boolean,
      secondsBeforeEnd?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('bidNewControllerHandleFileUpload', 'file', file);
      const localVarPath = `/api/bids-new/upload/VWFS`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (simulate !== undefined) {
        localVarQueryParameter['simulate'] = simulate;
      }

      if (secondsBeforeEnd !== undefined) {
        localVarQueryParameter['secondsBeforeEnd'] = secondsBeforeEnd;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerList: async (
      bidsAndCarsRequestDTO: BidsAndCarsRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bidsAndCarsRequestDTO' is not null or undefined
      assertParamExists('bidNewControllerList', 'bidsAndCarsRequestDTO', bidsAndCarsRequestDTO);
      const localVarPath = `/api/bids-new/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(bidsAndCarsRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} bidId
     * @param {boolean} forceResend
     * @param {Array<ResendNotificationReceiverDTO>} resendNotificationReceiverDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerResendNotification: async (
      bidId: string,
      forceResend: boolean,
      resendNotificationReceiverDTO: Array<ResendNotificationReceiverDTO>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bidId' is not null or undefined
      assertParamExists('bidNewControllerResendNotification', 'bidId', bidId);
      // verify required parameter 'forceResend' is not null or undefined
      assertParamExists('bidNewControllerResendNotification', 'forceResend', forceResend);
      // verify required parameter 'resendNotificationReceiverDTO' is not null or undefined
      assertParamExists(
        'bidNewControllerResendNotification',
        'resendNotificationReceiverDTO',
        resendNotificationReceiverDTO,
      );
      const localVarPath = `/api/bids-new/{bidId}/notifications`.replace(
        `{${'bidId'}}`,
        encodeURIComponent(String(bidId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (forceResend !== undefined) {
        localVarQueryParameter['forceResend'] = forceResend;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resendNotificationReceiverDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} forceResend
     * @param {Array<ResendNotificationDTO>} resendNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerResendNotification1: async (
      forceResend: boolean,
      resendNotificationDTO: Array<ResendNotificationDTO>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forceResend' is not null or undefined
      assertParamExists('bidNewControllerResendNotification1', 'forceResend', forceResend);
      // verify required parameter 'resendNotificationDTO' is not null or undefined
      assertParamExists('bidNewControllerResendNotification1', 'resendNotificationDTO', resendNotificationDTO);
      const localVarPath = `/api/bids-new/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (forceResend !== undefined) {
        localVarQueryParameter['forceResend'] = forceResend;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(resendNotificationDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} forceResend
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerResendNotifications: async (
      forceResend: boolean,
      requestBody: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forceResend' is not null or undefined
      assertParamExists('bidNewControllerResendNotifications', 'forceResend', forceResend);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('bidNewControllerResendNotifications', 'requestBody', requestBody);
      const localVarPath = `/api/bids-new/notifications/bid-ids`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (forceResend !== undefined) {
        localVarQueryParameter['forceResend'] = forceResend;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} bidId
     * @param {string} deliveryAddressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSaveDeliveryAddress: async (
      bidId: string,
      deliveryAddressId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bidId' is not null or undefined
      assertParamExists('bidNewControllerSaveDeliveryAddress', 'bidId', bidId);
      // verify required parameter 'deliveryAddressId' is not null or undefined
      assertParamExists('bidNewControllerSaveDeliveryAddress', 'deliveryAddressId', deliveryAddressId);
      const localVarPath = `/api/bids-new/{bidId}/delivery-address/{deliveryAddressId}`
        .replace(`{${'bidId'}}`, encodeURIComponent(String(bidId)))
        .replace(`{${'deliveryAddressId'}}`, encodeURIComponent(String(deliveryAddressId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} bidId
     * @param {string} winningDealerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSetBidStatusToBought: async (
      source: SourceType,
      bidId: string,
      winningDealerId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('bidNewControllerSetBidStatusToBought', 'source', source);
      // verify required parameter 'bidId' is not null or undefined
      assertParamExists('bidNewControllerSetBidStatusToBought', 'bidId', bidId);
      // verify required parameter 'winningDealerId' is not null or undefined
      assertParamExists('bidNewControllerSetBidStatusToBought', 'winningDealerId', winningDealerId);
      const localVarPath = `/api/bids-new/{source}/{bidId}/buy/{winningDealerId}`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'bidId'}}`, encodeURIComponent(String(bidId)))
        .replace(`{${'winningDealerId'}}`, encodeURIComponent(String(winningDealerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSetBidStatusToOutBid: async (
      source: SourceType,
      carId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('bidNewControllerSetBidStatusToOutBid', 'source', source);
      // verify required parameter 'carId' is not null or undefined
      assertParamExists('bidNewControllerSetBidStatusToOutBid', 'carId', carId);
      const localVarPath = `/api/bids-new/{source}/{carId}/reject`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'carId'}}`, encodeURIComponent(String(carId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SummaryRequestDTO} summaryRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSummaryPost: async (
      summaryRequestDTO: SummaryRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'summaryRequestDTO' is not null or undefined
      assertParamExists('bidNewControllerSummaryPost', 'summaryRequestDTO', summaryRequestDTO);
      const localVarPath = `/api/bids-new/list/summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(summaryRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerUpdateAutoBidOnSubsequentOffers: async (
      updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateBidAutomationStatusRequestDTO' is not null or undefined
      assertParamExists(
        'bidNewControllerUpdateAutoBidOnSubsequentOffers',
        'updateBidAutomationStatusRequestDTO',
        updateBidAutomationStatusRequestDTO,
      );
      const localVarPath = `/api/bids-new/updateAutoBidOnSubsequentOffers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBidAutomationStatusRequestDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerUpdateAutoBidOnSubsequentOffersForUser: async (
      userId: string,
      updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('bidNewControllerUpdateAutoBidOnSubsequentOffersForUser', 'userId', userId);
      // verify required parameter 'updateBidAutomationStatusRequestDTO' is not null or undefined
      assertParamExists(
        'bidNewControllerUpdateAutoBidOnSubsequentOffersForUser',
        'updateBidAutomationStatusRequestDTO',
        updateBidAutomationStatusRequestDTO,
      );
      const localVarPath = `/api/bids-new/updateAutoBidOnSubsequentOffersForUser/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBidAutomationStatusRequestDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SetBidRequestDTO} setBidRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerUpsertBid: async (
      setBidRequestDTO: SetBidRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'setBidRequestDTO' is not null or undefined
      assertParamExists('bidNewControllerUpsertBid', 'setBidRequestDTO', setBidRequestDTO);
      const localVarPath = `/api/bids-new`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(setBidRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BidNewControllerApi - functional programming interface
 * @export
 */
export const BidNewControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BidNewControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} bidId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerCancelBid(
      bidId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerCancelBid(bidId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {any} file
     * @param {boolean} [simulate]
     * @param {number} [secondsBeforeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerHandleFileUpload(
      file: any,
      simulate?: boolean,
      secondsBeforeEnd?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CSVBidRequestDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerHandleFileUpload(
        file,
        simulate,
        secondsBeforeEnd,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerList(
      bidsAndCarsRequestDTO: BidsAndCarsRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDataTablesOutputListBidDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerList(bidsAndCarsRequestDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} bidId
     * @param {boolean} forceResend
     * @param {Array<ResendNotificationReceiverDTO>} resendNotificationReceiverDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerResendNotification(
      bidId: string,
      forceResend: boolean,
      resendNotificationReceiverDTO: Array<ResendNotificationReceiverDTO>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerResendNotification(
        bidId,
        forceResend,
        resendNotificationReceiverDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {boolean} forceResend
     * @param {Array<ResendNotificationDTO>} resendNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerResendNotification1(
      forceResend: boolean,
      resendNotificationDTO: Array<ResendNotificationDTO>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerResendNotification1(
        forceResend,
        resendNotificationDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {boolean} forceResend
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerResendNotifications(
      forceResend: boolean,
      requestBody: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerResendNotifications(
        forceResend,
        requestBody,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} bidId
     * @param {string} deliveryAddressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerSaveDeliveryAddress(
      bidId: string,
      deliveryAddressId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerSaveDeliveryAddress(
        bidId,
        deliveryAddressId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} bidId
     * @param {string} winningDealerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerSetBidStatusToBought(
      source: SourceType,
      bidId: string,
      winningDealerId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerSetBidStatusToBought(
        source,
        bidId,
        winningDealerId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerSetBidStatusToOutBid(
      source: SourceType,
      carId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerSetBidStatusToOutBid(
        source,
        carId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SummaryRequestDTO} summaryRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerSummaryPost(
      summaryRequestDTO: SummaryRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummaryDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerSummaryPost(summaryRequestDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerUpdateAutoBidOnSubsequentOffers(
      updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidAutomationStatusResponseDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerUpdateAutoBidOnSubsequentOffers(
        updateBidAutomationStatusRequestDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerUpdateAutoBidOnSubsequentOffersForUser(
      userId: string,
      updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidAutomationStatusResponseDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerUpdateAutoBidOnSubsequentOffersForUser(
        userId,
        updateBidAutomationStatusRequestDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SetBidRequestDTO} setBidRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bidNewControllerUpsertBid(
      setBidRequestDTO: SetBidRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bidNewControllerUpsertBid(setBidRequestDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BidNewControllerApi - factory interface
 * @export
 */
export const BidNewControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BidNewControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} bidId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerCancelBid(bidId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp.bidNewControllerCancelBid(bidId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} file
     * @param {boolean} [simulate]
     * @param {number} [secondsBeforeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerHandleFileUpload(
      file: any,
      simulate?: boolean,
      secondsBeforeEnd?: number,
      options?: any,
    ): AxiosPromise<Array<CSVBidRequestDTO>> {
      return localVarFp
        .bidNewControllerHandleFileUpload(file, simulate, secondsBeforeEnd, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerList(
      bidsAndCarsRequestDTO: BidsAndCarsRequestDTO,
      options?: any,
    ): AxiosPromise<BidDataTablesOutputListBidDTO> {
      return localVarFp
        .bidNewControllerList(bidsAndCarsRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} bidId
     * @param {boolean} forceResend
     * @param {Array<ResendNotificationReceiverDTO>} resendNotificationReceiverDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerResendNotification(
      bidId: string,
      forceResend: boolean,
      resendNotificationReceiverDTO: Array<ResendNotificationReceiverDTO>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .bidNewControllerResendNotification(bidId, forceResend, resendNotificationReceiverDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} forceResend
     * @param {Array<ResendNotificationDTO>} resendNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerResendNotification1(
      forceResend: boolean,
      resendNotificationDTO: Array<ResendNotificationDTO>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .bidNewControllerResendNotification1(forceResend, resendNotificationDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} forceResend
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerResendNotifications(
      forceResend: boolean,
      requestBody: Array<string>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .bidNewControllerResendNotifications(forceResend, requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} bidId
     * @param {string} deliveryAddressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSaveDeliveryAddress(
      bidId: string,
      deliveryAddressId: string,
      options?: any,
    ): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .bidNewControllerSaveDeliveryAddress(bidId, deliveryAddressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} bidId
     * @param {string} winningDealerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSetBidStatusToBought(
      source: SourceType,
      bidId: string,
      winningDealerId: string,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .bidNewControllerSetBidStatusToBought(source, bidId, winningDealerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSetBidStatusToOutBid(source: SourceType, carId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .bidNewControllerSetBidStatusToOutBid(source, carId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SummaryRequestDTO} summaryRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerSummaryPost(summaryRequestDTO: SummaryRequestDTO, options?: any): AxiosPromise<SummaryDTO> {
      return localVarFp
        .bidNewControllerSummaryPost(summaryRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerUpdateAutoBidOnSubsequentOffers(
      updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
      options?: any,
    ): AxiosPromise<BidAutomationStatusResponseDTO> {
      return localVarFp
        .bidNewControllerUpdateAutoBidOnSubsequentOffers(updateBidAutomationStatusRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerUpdateAutoBidOnSubsequentOffersForUser(
      userId: string,
      updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
      options?: any,
    ): AxiosPromise<BidAutomationStatusResponseDTO> {
      return localVarFp
        .bidNewControllerUpdateAutoBidOnSubsequentOffersForUser(userId, updateBidAutomationStatusRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SetBidRequestDTO} setBidRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bidNewControllerUpsertBid(setBidRequestDTO: SetBidRequestDTO, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .bidNewControllerUpsertBid(setBidRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BidNewControllerApi - object-oriented interface
 * @export
 * @class BidNewControllerApi
 * @extends {BaseAPI}
 */
export class BidNewControllerApi extends BaseAPI {
  /**
   *
   * @param {string} bidId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerCancelBid(bidId: string, options?: AxiosRequestConfig) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerCancelBid(bidId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} file
   * @param {boolean} [simulate]
   * @param {number} [secondsBeforeEnd]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerHandleFileUpload(
    file: any,
    simulate?: boolean,
    secondsBeforeEnd?: number,
    options?: AxiosRequestConfig,
  ) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerHandleFileUpload(file, simulate, secondsBeforeEnd, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerList(bidsAndCarsRequestDTO: BidsAndCarsRequestDTO, options?: AxiosRequestConfig) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerList(bidsAndCarsRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} bidId
   * @param {boolean} forceResend
   * @param {Array<ResendNotificationReceiverDTO>} resendNotificationReceiverDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerResendNotification(
    bidId: string,
    forceResend: boolean,
    resendNotificationReceiverDTO: Array<ResendNotificationReceiverDTO>,
    options?: AxiosRequestConfig,
  ) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerResendNotification(bidId, forceResend, resendNotificationReceiverDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} forceResend
   * @param {Array<ResendNotificationDTO>} resendNotificationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerResendNotification1(
    forceResend: boolean,
    resendNotificationDTO: Array<ResendNotificationDTO>,
    options?: AxiosRequestConfig,
  ) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerResendNotification1(forceResend, resendNotificationDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} forceResend
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerResendNotifications(
    forceResend: boolean,
    requestBody: Array<string>,
    options?: AxiosRequestConfig,
  ) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerResendNotifications(forceResend, requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} bidId
   * @param {string} deliveryAddressId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerSaveDeliveryAddress(bidId: string, deliveryAddressId: string, options?: AxiosRequestConfig) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerSaveDeliveryAddress(bidId, deliveryAddressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {string} bidId
   * @param {string} winningDealerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerSetBidStatusToBought(
    source: SourceType,
    bidId: string,
    winningDealerId: string,
    options?: AxiosRequestConfig,
  ) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerSetBidStatusToBought(source, bidId, winningDealerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {string} carId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerSetBidStatusToOutBid(source: SourceType, carId: string, options?: AxiosRequestConfig) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerSetBidStatusToOutBid(source, carId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SummaryRequestDTO} summaryRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerSummaryPost(summaryRequestDTO: SummaryRequestDTO, options?: AxiosRequestConfig) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerSummaryPost(summaryRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerUpdateAutoBidOnSubsequentOffers(
    updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
    options?: AxiosRequestConfig,
  ) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerUpdateAutoBidOnSubsequentOffers(updateBidAutomationStatusRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {UpdateBidAutomationStatusRequestDTO} updateBidAutomationStatusRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerUpdateAutoBidOnSubsequentOffersForUser(
    userId: string,
    updateBidAutomationStatusRequestDTO: UpdateBidAutomationStatusRequestDTO,
    options?: AxiosRequestConfig,
  ) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerUpdateAutoBidOnSubsequentOffersForUser(userId, updateBidAutomationStatusRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SetBidRequestDTO} setBidRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidNewControllerApi
   */
  public bidNewControllerUpsertBid(setBidRequestDTO: SetBidRequestDTO, options?: AxiosRequestConfig) {
    return BidNewControllerApiFp(this.configuration)
      .bidNewControllerUpsertBid(setBidRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CarDetailControllerApi - axios parameter creator
 * @export
 */
export const CarDetailControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SourceType} source
     * @param {ValuationCountryCode} country
     * @param {string} offerId
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carDetailControllerGetOneValuationIncludedCars: async (
      source: SourceType,
      country: ValuationCountryCode,
      offerId: string,
      carId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('carDetailControllerGetOneValuationIncludedCars', 'source', source);
      // verify required parameter 'country' is not null or undefined
      assertParamExists('carDetailControllerGetOneValuationIncludedCars', 'country', country);
      // verify required parameter 'offerId' is not null or undefined
      assertParamExists('carDetailControllerGetOneValuationIncludedCars', 'offerId', offerId);
      // verify required parameter 'carId' is not null or undefined
      assertParamExists('carDetailControllerGetOneValuationIncludedCars', 'carId', carId);
      const localVarPath = `/api/car-detail/valuation/{source}/{carId}/{offerId}/{country}`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'country'}}`, encodeURIComponent(String(country)))
        .replace(`{${'offerId'}}`, encodeURIComponent(String(offerId)))
        .replace(`{${'carId'}}`, encodeURIComponent(String(carId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RequestCarValuationDto} requestCarValuationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carDetailControllerGetValuationsIncludedCars: async (
      requestCarValuationDto: RequestCarValuationDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestCarValuationDto' is not null or undefined
      assertParamExists(
        'carDetailControllerGetValuationsIncludedCars',
        'requestCarValuationDto',
        requestCarValuationDto,
      );
      const localVarPath = `/api/car-detail/valuation/included-cars`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestCarValuationDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CarDetailControllerApi - functional programming interface
 * @export
 */
export const CarDetailControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CarDetailControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {ValuationCountryCode} country
     * @param {string} offerId
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async carDetailControllerGetOneValuationIncludedCars(
      source: SourceType,
      country: ValuationCountryCode,
      offerId: string,
      carId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValuationWithIncludedCarsDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.carDetailControllerGetOneValuationIncludedCars(
        source,
        country,
        offerId,
        carId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RequestCarValuationDto} requestCarValuationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async carDetailControllerGetValuationsIncludedCars(
      requestCarValuationDto: RequestCarValuationDto,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValuationWithIncludedCarsDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.carDetailControllerGetValuationsIncludedCars(
        requestCarValuationDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CarDetailControllerApi - factory interface
 * @export
 */
export const CarDetailControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CarDetailControllerApiFp(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {ValuationCountryCode} country
     * @param {string} offerId
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carDetailControllerGetOneValuationIncludedCars(
      source: SourceType,
      country: ValuationCountryCode,
      offerId: string,
      carId: string,
      options?: any,
    ): AxiosPromise<ValuationWithIncludedCarsDTO> {
      return localVarFp
        .carDetailControllerGetOneValuationIncludedCars(source, country, offerId, carId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RequestCarValuationDto} requestCarValuationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carDetailControllerGetValuationsIncludedCars(
      requestCarValuationDto: RequestCarValuationDto,
      options?: any,
    ): AxiosPromise<Array<ValuationWithIncludedCarsDTO>> {
      return localVarFp
        .carDetailControllerGetValuationsIncludedCars(requestCarValuationDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CarDetailControllerApi - object-oriented interface
 * @export
 * @class CarDetailControllerApi
 * @extends {BaseAPI}
 */
export class CarDetailControllerApi extends BaseAPI {
  /**
   *
   * @param {SourceType} source
   * @param {ValuationCountryCode} country
   * @param {string} offerId
   * @param {string} carId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarDetailControllerApi
   */
  public carDetailControllerGetOneValuationIncludedCars(
    source: SourceType,
    country: ValuationCountryCode,
    offerId: string,
    carId: string,
    options?: AxiosRequestConfig,
  ) {
    return CarDetailControllerApiFp(this.configuration)
      .carDetailControllerGetOneValuationIncludedCars(source, country, offerId, carId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RequestCarValuationDto} requestCarValuationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarDetailControllerApi
   */
  public carDetailControllerGetValuationsIncludedCars(
    requestCarValuationDto: RequestCarValuationDto,
    options?: AxiosRequestConfig,
  ) {
    return CarDetailControllerApiFp(this.configuration)
      .carDetailControllerGetValuationsIncludedCars(requestCarValuationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ConfigControllerApi - axios parameter creator
 * @export
 */
export const ConfigControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AcceptTermsConditionsRequest} acceptTermsConditionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerAcceptTermsAndConditions: async (
      acceptTermsConditionsRequest: AcceptTermsConditionsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'acceptTermsConditionsRequest' is not null or undefined
      assertParamExists(
        'configControllerAcceptTermsAndConditions',
        'acceptTermsConditionsRequest',
        acceptTermsConditionsRequest,
      );
      const localVarPath = `/api/config/terms-and-conditions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        acceptTermsConditionsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WizardStep} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerConfirmWizardStep: async (
      step: WizardStep,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'step' is not null or undefined
      assertParamExists('configControllerConfirmWizardStep', 'step', step);
      const localVarPath = `/api/config/wizard/{step}`.replace(`{${'step'}}`, encodeURIComponent(String(step)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetConditionalFormatting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/config/conditional-formatting`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    configControllerGetConditionalFormattingDummyOnlyForOpenApi: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/config/conditional-formatting-dummy-dont-call-only-for-openapi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetConditionalFormattingForUser: async (
      authenticationProvider: AuthenticationProvider,
      user: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authenticationProvider' is not null or undefined
      assertParamExists(
        'configControllerGetConditionalFormattingForUser',
        'authenticationProvider',
        authenticationProvider,
      );
      // verify required parameter 'user' is not null or undefined
      assertParamExists('configControllerGetConditionalFormattingForUser', 'user', user);
      const localVarPath = `/api/config/{authentication-provider}/{user}/conditional-formatting`
        .replace(`{${'authentication-provider'}}`, encodeURIComponent(String(authenticationProvider)))
        .replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetDetailingAddress: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/config/detailing-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [accepted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetLatestTermsAndConditions: async (
      accepted?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/config/terms-and-conditions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accepted !== undefined) {
        localVarQueryParameter['accepted'] = accepted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {boolean} [accepted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetLatestTermsAndConditionsForUser: async (
      authenticationProvider: AuthenticationProvider,
      user: string,
      accepted?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authenticationProvider' is not null or undefined
      assertParamExists(
        'configControllerGetLatestTermsAndConditionsForUser',
        'authenticationProvider',
        authenticationProvider,
      );
      // verify required parameter 'user' is not null or undefined
      assertParamExists('configControllerGetLatestTermsAndConditionsForUser', 'user', user);
      const localVarPath = `/api/config/{authentication-provider}/{user}/terms-and-conditions`
        .replace(`{${'authentication-provider'}}`, encodeURIComponent(String(authenticationProvider)))
        .replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accepted !== undefined) {
        localVarQueryParameter['accepted'] = accepted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetWizard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/config/wizard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetWizardForUser: async (
      authenticationProvider: AuthenticationProvider,
      user: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authenticationProvider' is not null or undefined
      assertParamExists('configControllerGetWizardForUser', 'authenticationProvider', authenticationProvider);
      // verify required parameter 'user' is not null or undefined
      assertParamExists('configControllerGetWizardForUser', 'user', user);
      const localVarPath = `/api/config/{authentication-provider}/{user}/wizard`
        .replace(`{${'authentication-provider'}}`, encodeURIComponent(String(authenticationProvider)))
        .replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerResetConditionalFormatting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/config/conditional-formatting`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerResetConditionalFormattingForUser: async (
      authenticationProvider: AuthenticationProvider,
      user: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authenticationProvider' is not null or undefined
      assertParamExists(
        'configControllerResetConditionalFormattingForUser',
        'authenticationProvider',
        authenticationProvider,
      );
      // verify required parameter 'user' is not null or undefined
      assertParamExists('configControllerResetConditionalFormattingForUser', 'user', user);
      const localVarPath = `/api/config/{authentication-provider}/{user}/conditional-formatting`
        .replace(`{${'authentication-provider'}}`, encodeURIComponent(String(authenticationProvider)))
        .replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerSaveConditionalFormatting: async (
      conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'conditionalFormattingDTO' is not null or undefined
      assertParamExists(
        'configControllerSaveConditionalFormatting',
        'conditionalFormattingDTO',
        conditionalFormattingDTO,
      );
      const localVarPath = `/api/config/conditional-formatting`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        conditionalFormattingDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerSaveConditionalFormattingForUser: async (
      authenticationProvider: AuthenticationProvider,
      user: string,
      conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authenticationProvider' is not null or undefined
      assertParamExists(
        'configControllerSaveConditionalFormattingForUser',
        'authenticationProvider',
        authenticationProvider,
      );
      // verify required parameter 'user' is not null or undefined
      assertParamExists('configControllerSaveConditionalFormattingForUser', 'user', user);
      // verify required parameter 'conditionalFormattingDTO' is not null or undefined
      assertParamExists(
        'configControllerSaveConditionalFormattingForUser',
        'conditionalFormattingDTO',
        conditionalFormattingDTO,
      );
      const localVarPath = `/api/config/{authentication-provider}/{user}/conditional-formatting`
        .replace(`{${'authentication-provider'}}`, encodeURIComponent(String(authenticationProvider)))
        .replace(`{${'user'}}`, encodeURIComponent(String(user)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        conditionalFormattingDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DetailingAddressDTO} detailingAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerSaveDetailingAddress: async (
      detailingAddressDTO: DetailingAddressDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'detailingAddressDTO' is not null or undefined
      assertParamExists('configControllerSaveDetailingAddress', 'detailingAddressDTO', detailingAddressDTO);
      const localVarPath = `/api/config/detailing-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(detailingAddressDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConfigControllerApi - functional programming interface
 * @export
 */
export const ConfigControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConfigControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AcceptTermsConditionsRequest} acceptTermsConditionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerAcceptTermsAndConditions(
      acceptTermsConditionsRequest: AcceptTermsConditionsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermsConditionsDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerAcceptTermsAndConditions(
        acceptTermsConditionsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {WizardStep} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerConfirmWizardStep(
      step: WizardStep,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WizardStepDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerConfirmWizardStep(step, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerGetConditionalFormatting(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConditionalFormattingDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerGetConditionalFormatting(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async configControllerGetConditionalFormattingDummyOnlyForOpenApi(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionalFormattingDTO>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.configControllerGetConditionalFormattingDummyOnlyForOpenApi(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerGetConditionalFormattingForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConditionalFormattingDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerGetConditionalFormattingForUser(
        authenticationProvider,
        user,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerGetDetailingAddress(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailingAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerGetDetailingAddress(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {boolean} [accepted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerGetLatestTermsAndConditions(
      accepted?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TermsConditionsDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerGetLatestTermsAndConditions(
        accepted,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {boolean} [accepted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerGetLatestTermsAndConditionsForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      accepted?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TermsConditionsDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerGetLatestTermsAndConditionsForUser(
        authenticationProvider,
        user,
        accepted,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerGetWizard(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WizardDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerGetWizard(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerGetWizardForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WizardDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerGetWizardForUser(
        authenticationProvider,
        user,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerResetConditionalFormatting(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConditionalFormattingDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerResetConditionalFormatting(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerResetConditionalFormattingForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConditionalFormattingDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerResetConditionalFormattingForUser(
        authenticationProvider,
        user,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerSaveConditionalFormatting(
      conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConditionalFormattingDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerSaveConditionalFormatting(
        conditionalFormattingDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerSaveConditionalFormattingForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConditionalFormattingDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerSaveConditionalFormattingForUser(
        authenticationProvider,
        user,
        conditionalFormattingDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DetailingAddressDTO} detailingAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configControllerSaveDetailingAddress(
      detailingAddressDTO: DetailingAddressDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailingAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configControllerSaveDetailingAddress(
        detailingAddressDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ConfigControllerApi - factory interface
 * @export
 */
export const ConfigControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ConfigControllerApiFp(configuration);
  return {
    /**
     *
     * @param {AcceptTermsConditionsRequest} acceptTermsConditionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerAcceptTermsAndConditions(
      acceptTermsConditionsRequest: AcceptTermsConditionsRequest,
      options?: any,
    ): AxiosPromise<TermsConditionsDTO> {
      return localVarFp
        .configControllerAcceptTermsAndConditions(acceptTermsConditionsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {WizardStep} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerConfirmWizardStep(step: WizardStep, options?: any): AxiosPromise<WizardStepDTO> {
      return localVarFp.configControllerConfirmWizardStep(step, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetConditionalFormatting(options?: any): AxiosPromise<Array<ConditionalFormattingDTO>> {
      return localVarFp.configControllerGetConditionalFormatting(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    configControllerGetConditionalFormattingDummyOnlyForOpenApi(options?: any): AxiosPromise<ConditionalFormattingDTO> {
      return localVarFp
        .configControllerGetConditionalFormattingDummyOnlyForOpenApi(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetConditionalFormattingForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      options?: any,
    ): AxiosPromise<Array<ConditionalFormattingDTO>> {
      return localVarFp
        .configControllerGetConditionalFormattingForUser(authenticationProvider, user, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetDetailingAddress(options?: any): AxiosPromise<DetailingAddressDTO> {
      return localVarFp.configControllerGetDetailingAddress(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [accepted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetLatestTermsAndConditions(
      accepted?: boolean,
      options?: any,
    ): AxiosPromise<Array<TermsConditionsDTO>> {
      return localVarFp
        .configControllerGetLatestTermsAndConditions(accepted, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {boolean} [accepted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetLatestTermsAndConditionsForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      accepted?: boolean,
      options?: any,
    ): AxiosPromise<Array<TermsConditionsDTO>> {
      return localVarFp
        .configControllerGetLatestTermsAndConditionsForUser(authenticationProvider, user, accepted, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetWizard(options?: any): AxiosPromise<WizardDTO> {
      return localVarFp.configControllerGetWizard(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerGetWizardForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      options?: any,
    ): AxiosPromise<WizardDTO> {
      return localVarFp
        .configControllerGetWizardForUser(authenticationProvider, user, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerResetConditionalFormatting(options?: any): AxiosPromise<Array<ConditionalFormattingDTO>> {
      return localVarFp.configControllerResetConditionalFormatting(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerResetConditionalFormattingForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      options?: any,
    ): AxiosPromise<Array<ConditionalFormattingDTO>> {
      return localVarFp
        .configControllerResetConditionalFormattingForUser(authenticationProvider, user, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerSaveConditionalFormatting(
      conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
      options?: any,
    ): AxiosPromise<Array<ConditionalFormattingDTO>> {
      return localVarFp
        .configControllerSaveConditionalFormatting(conditionalFormattingDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AuthenticationProvider} authenticationProvider
     * @param {string} user
     * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerSaveConditionalFormattingForUser(
      authenticationProvider: AuthenticationProvider,
      user: string,
      conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
      options?: any,
    ): AxiosPromise<Array<ConditionalFormattingDTO>> {
      return localVarFp
        .configControllerSaveConditionalFormattingForUser(
          authenticationProvider,
          user,
          conditionalFormattingDTO,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DetailingAddressDTO} detailingAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configControllerSaveDetailingAddress(
      detailingAddressDTO: DetailingAddressDTO,
      options?: any,
    ): AxiosPromise<DetailingAddressDTO> {
      return localVarFp
        .configControllerSaveDetailingAddress(detailingAddressDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ConfigControllerApi - object-oriented interface
 * @export
 * @class ConfigControllerApi
 * @extends {BaseAPI}
 */
export class ConfigControllerApi extends BaseAPI {
  /**
   *
   * @param {AcceptTermsConditionsRequest} acceptTermsConditionsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerAcceptTermsAndConditions(
    acceptTermsConditionsRequest: AcceptTermsConditionsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerAcceptTermsAndConditions(acceptTermsConditionsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WizardStep} step
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerConfirmWizardStep(step: WizardStep, options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerConfirmWizardStep(step, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetConditionalFormatting(options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetConditionalFormatting(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetConditionalFormattingDummyOnlyForOpenApi(options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetConditionalFormattingDummyOnlyForOpenApi(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthenticationProvider} authenticationProvider
   * @param {string} user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetConditionalFormattingForUser(
    authenticationProvider: AuthenticationProvider,
    user: string,
    options?: AxiosRequestConfig,
  ) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetConditionalFormattingForUser(authenticationProvider, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetDetailingAddress(options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetDetailingAddress(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [accepted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetLatestTermsAndConditions(accepted?: boolean, options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetLatestTermsAndConditions(accepted, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthenticationProvider} authenticationProvider
   * @param {string} user
   * @param {boolean} [accepted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetLatestTermsAndConditionsForUser(
    authenticationProvider: AuthenticationProvider,
    user: string,
    accepted?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetLatestTermsAndConditionsForUser(authenticationProvider, user, accepted, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetWizard(options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetWizard(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthenticationProvider} authenticationProvider
   * @param {string} user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerGetWizardForUser(
    authenticationProvider: AuthenticationProvider,
    user: string,
    options?: AxiosRequestConfig,
  ) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerGetWizardForUser(authenticationProvider, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerResetConditionalFormatting(options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerResetConditionalFormatting(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthenticationProvider} authenticationProvider
   * @param {string} user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerResetConditionalFormattingForUser(
    authenticationProvider: AuthenticationProvider,
    user: string,
    options?: AxiosRequestConfig,
  ) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerResetConditionalFormattingForUser(authenticationProvider, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerSaveConditionalFormatting(
    conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
    options?: AxiosRequestConfig,
  ) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerSaveConditionalFormatting(conditionalFormattingDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuthenticationProvider} authenticationProvider
   * @param {string} user
   * @param {Array<ConditionalFormattingDTO>} conditionalFormattingDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerSaveConditionalFormattingForUser(
    authenticationProvider: AuthenticationProvider,
    user: string,
    conditionalFormattingDTO: Array<ConditionalFormattingDTO>,
    options?: AxiosRequestConfig,
  ) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerSaveConditionalFormattingForUser(authenticationProvider, user, conditionalFormattingDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DetailingAddressDTO} detailingAddressDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public configControllerSaveDetailingAddress(detailingAddressDTO: DetailingAddressDTO, options?: AxiosRequestConfig) {
    return ConfigControllerApiFp(this.configuration)
      .configControllerSaveDetailingAddress(detailingAddressDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DealerControllerApi - axios parameter creator
 * @export
 */
export const DealerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerAllowDealerToLogin: async (
      dealer: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerAllowDealerToLogin', 'dealer', dealer);
      const localVarPath = `/api/dealer/{dealer}/login/enable`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {string} packet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerAssignPacket: async (
      dealer: string,
      packet: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerAssignPacket', 'dealer', dealer);
      // verify required parameter 'packet' is not null or undefined
      assertParamExists('dealerControllerAssignPacket', 'packet', packet);
      const localVarPath = `/api/dealer/{dealer}/packet/{packet}`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'packet'}}`, encodeURIComponent(String(packet)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {CarDetailing} detailing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerChangeDetailing: async (
      dealer: string,
      detailing: CarDetailing,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerChangeDetailing', 'dealer', dealer);
      // verify required parameter 'detailing' is not null or undefined
      assertParamExists('dealerControllerChangeDetailing', 'detailing', detailing);
      const localVarPath = `/api/dealer/{dealer}/detailing/{detailing}`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'detailing'}}`, encodeURIComponent(String(detailing)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerChangeName: async (
      dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealerChangeNameRequestDTO' is not null or undefined
      assertParamExists('dealerControllerChangeName', 'dealerChangeNameRequestDTO', dealerChangeNameRequestDTO);
      const localVarPath = `/api/dealer/name`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dealerChangeNameRequestDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerChangeNameForDealer: async (
      dealer: string,
      dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerChangeNameForDealer', 'dealer', dealer);
      // verify required parameter 'dealerChangeNameRequestDTO' is not null or undefined
      assertParamExists(
        'dealerControllerChangeNameForDealer',
        'dealerChangeNameRequestDTO',
        dealerChangeNameRequestDTO,
      );
      const localVarPath = `/api/dealer/{dealer}/name`.replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dealerChangeNameRequestDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerCreatePlatformCredentials: async (
      platformCredentialsDTO: PlatformCredentialsDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'platformCredentialsDTO' is not null or undefined
      assertParamExists('dealerControllerCreatePlatformCredentials', 'platformCredentialsDTO', platformCredentialsDTO);
      const localVarPath = `/api/dealer/V2/platform-credentials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformCredentialsDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerCreatePlatformCredentialsForDealer: async (
      dealer: string,
      platformCredentialsDTO: PlatformCredentialsDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerCreatePlatformCredentialsForDealer', 'dealer', dealer);
      // verify required parameter 'platformCredentialsDTO' is not null or undefined
      assertParamExists(
        'dealerControllerCreatePlatformCredentialsForDealer',
        'platformCredentialsDTO',
        platformCredentialsDTO,
      );
      const localVarPath = `/api/dealer/{dealer}/platform-credentials`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformCredentialsDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeleteDeliveryAddress: async (
      addressId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerDeleteDeliveryAddress', 'addressId', addressId);
      const localVarPath = `/api/dealer/delivery-address/{addressId}`.replace(
        `{${'addressId'}}`,
        encodeURIComponent(String(addressId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeleteDeliveryAddressForDealer: async (
      dealer: string,
      addressId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerDeleteDeliveryAddressForDealer', 'dealer', dealer);
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerDeleteDeliveryAddressForDealer', 'addressId', addressId);
      const localVarPath = `/api/dealer/{dealer}/delivery-address/{addressId}`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'addressId'}}`, encodeURIComponent(String(addressId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeletePlatformCredentials: async (
      source: SourceType,
      deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('dealerControllerDeletePlatformCredentials', 'source', source);
      // verify required parameter 'deletePlatformCredentialRequest' is not null or undefined
      assertParamExists(
        'dealerControllerDeletePlatformCredentials',
        'deletePlatformCredentialRequest',
        deletePlatformCredentialRequest,
      );
      const localVarPath = `/api/dealer/V2/platform-credentials/{source}`.replace(
        `{${'source'}}`,
        encodeURIComponent(String(source)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deletePlatformCredentialRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {SourceType} source
     * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeletePlatformCredentialsForDealer: async (
      dealer: string,
      source: SourceType,
      deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerDeletePlatformCredentialsForDealer', 'dealer', dealer);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('dealerControllerDeletePlatformCredentialsForDealer', 'source', source);
      // verify required parameter 'deletePlatformCredentialRequest' is not null or undefined
      assertParamExists(
        'dealerControllerDeletePlatformCredentialsForDealer',
        'deletePlatformCredentialRequest',
        deletePlatformCredentialRequest,
      );
      const localVarPath = `/api/dealer/{dealer}/platform-credentials/{source}`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'source'}}`, encodeURIComponent(String(source)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deletePlatformCredentialRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDisallowDealerToLogin: async (
      dealer: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerDisallowDealerToLogin', 'dealer', dealer);
      const localVarPath = `/api/dealer/{dealer}/login/disable`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetAllowedSourceTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/dealer/source`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetAllowedSourceTypesForDealer: async (
      dealer: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerGetAllowedSourceTypesForDealer', 'dealer', dealer);
      const localVarPath = `/api/dealer/{dealer}/source`.replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetCosts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/dealer/V2/costs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDealers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/dealer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddress: async (
      addressId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerGetDeliveryAddress', 'addressId', addressId);
      const localVarPath = `/api/dealer/delivery-address/{addressId}`.replace(
        `{${'addressId'}}`,
        encodeURIComponent(String(addressId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddressForDealer: async (
      dealer: string,
      addressId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerGetDeliveryAddressForDealer', 'dealer', dealer);
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerGetDeliveryAddressForDealer', 'addressId', addressId);
      const localVarPath = `/api/dealer/{dealer}/delivery-address/{addressId}`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'addressId'}}`, encodeURIComponent(String(addressId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddresses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/dealer/delivery-addresses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddressesForDealer: async (
      dealer: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerGetDeliveryAddressesForDealer', 'dealer', dealer);
      const localVarPath = `/api/dealer/{dealer}/delivery-addresses`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetMyDealer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/dealer/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceTypeBasic} [source]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetPlatformCredentials: async (
      source?: SourceTypeBasic,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/dealer/V2/platform-credentials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (source !== undefined) {
        localVarQueryParameter['source'] = source;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {SourceTypeExtended} [source]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetPlatformCredentialsForDealer: async (
      dealer: string,
      source?: SourceTypeExtended,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerGetPlatformCredentialsForDealer', 'dealer', dealer);
      const localVarPath = `/api/dealer/{dealer}/platform-credentials`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (source !== undefined) {
        localVarQueryParameter['source'] = source;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    dealerControllerGetPlatformCredentialsV1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/dealer/platform-credentials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {Array<SourceType>} sourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveAllowedSourceTypes: async (
      dealer: string,
      sourceType: Array<SourceType>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerSaveAllowedSourceTypes', 'dealer', dealer);
      // verify required parameter 'sourceType' is not null or undefined
      assertParamExists('dealerControllerSaveAllowedSourceTypes', 'sourceType', sourceType);
      const localVarPath = `/api/dealer/{dealer}/source`.replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sourceType, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CostsDTO} costsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveCosts: async (costsDTO: CostsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'costsDTO' is not null or undefined
      assertParamExists('dealerControllerSaveCosts', 'costsDTO', costsDTO);
      const localVarPath = `/api/dealer/V2/costs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(costsDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {DetailedDealerDTO} detailedDealerDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveDealer: async (
      dealer: string,
      detailedDealerDTO: DetailedDealerDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerSaveDealer', 'dealer', dealer);
      // verify required parameter 'detailedDealerDTO' is not null or undefined
      assertParamExists('dealerControllerSaveDealer', 'detailedDealerDTO', detailedDealerDTO);
      const localVarPath = `/api/dealer/{dealer}`.replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(detailedDealerDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveDeliveryAddress: async (
      deliveryAddressDTO: DeliveryAddressDTO,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deliveryAddressDTO' is not null or undefined
      assertParamExists('dealerControllerSaveDeliveryAddress', 'deliveryAddressDTO', deliveryAddressDTO);
      const localVarPath = `/api/dealer/delivery-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(deliveryAddressDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveDeliveryAddressForDealer: async (
      dealer: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerSaveDeliveryAddressForDealer', 'dealer', dealer);
      // verify required parameter 'deliveryAddressDTO' is not null or undefined
      assertParamExists('dealerControllerSaveDeliveryAddressForDealer', 'deliveryAddressDTO', deliveryAddressDTO);
      const localVarPath = `/api/dealer/{dealer}/delivery-address`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(deliveryAddressDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    dealerControllerSavePlatformCredentials: async (
      platformCredentialsDTO: PlatformCredentialsDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'platformCredentialsDTO' is not null or undefined
      assertParamExists('dealerControllerSavePlatformCredentials', 'platformCredentialsDTO', platformCredentialsDTO);
      const localVarPath = `/api/dealer/platform-credentials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformCredentialsDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {string} packet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUnassignPacket: async (
      dealer: string,
      packet: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerUnassignPacket', 'dealer', dealer);
      // verify required parameter 'packet' is not null or undefined
      assertParamExists('dealerControllerUnassignPacket', 'packet', packet);
      const localVarPath = `/api/dealer/{dealer}/packet/{packet}`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'packet'}}`, encodeURIComponent(String(packet)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDefaultDeliveryAddress: async (
      addressId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerUpdateDefaultDeliveryAddress', 'addressId', addressId);
      const localVarPath = `/api/dealer/delivery-address/{addressId}/global`.replace(
        `{${'addressId'}}`,
        encodeURIComponent(String(addressId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDefaultDeliveryAddressForDealer: async (
      dealer: string,
      addressId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerUpdateDefaultDeliveryAddressForDealer', 'dealer', dealer);
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerUpdateDefaultDeliveryAddressForDealer', 'addressId', addressId);
      const localVarPath = `/api/dealer/{dealer}/delivery-address/{addressId}/global`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'addressId'}}`, encodeURIComponent(String(addressId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} addressId
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDeliveryAddress: async (
      addressId: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerUpdateDeliveryAddress', 'addressId', addressId);
      // verify required parameter 'deliveryAddressDTO' is not null or undefined
      assertParamExists('dealerControllerUpdateDeliveryAddress', 'deliveryAddressDTO', deliveryAddressDTO);
      const localVarPath = `/api/dealer/delivery-address/{addressId}`.replace(
        `{${'addressId'}}`,
        encodeURIComponent(String(addressId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(deliveryAddressDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDeliveryAddressForDealer: async (
      dealer: string,
      addressId: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerUpdateDeliveryAddressForDealer', 'dealer', dealer);
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('dealerControllerUpdateDeliveryAddressForDealer', 'addressId', addressId);
      // verify required parameter 'deliveryAddressDTO' is not null or undefined
      assertParamExists('dealerControllerUpdateDeliveryAddressForDealer', 'deliveryAddressDTO', deliveryAddressDTO);
      const localVarPath = `/api/dealer/{dealer}/delivery-address/{addressId}`
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)))
        .replace(`{${'addressId'}}`, encodeURIComponent(String(addressId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(deliveryAddressDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdatePlatformCredentials: async (
      platformCredentialsDTO: PlatformCredentialsDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'platformCredentialsDTO' is not null or undefined
      assertParamExists('dealerControllerUpdatePlatformCredentials', 'platformCredentialsDTO', platformCredentialsDTO);
      const localVarPath = `/api/dealer/V2/platform-credentials`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformCredentialsDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdatePlatformCredentialsForDealer: async (
      dealer: string,
      platformCredentialsDTO: PlatformCredentialsDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('dealerControllerUpdatePlatformCredentialsForDealer', 'dealer', dealer);
      // verify required parameter 'platformCredentialsDTO' is not null or undefined
      assertParamExists(
        'dealerControllerUpdatePlatformCredentialsForDealer',
        'platformCredentialsDTO',
        platformCredentialsDTO,
      );
      const localVarPath = `/api/dealer/{dealer}/platform-credentials`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformCredentialsDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DealerControllerApi - functional programming interface
 * @export
 */
export const DealerControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DealerControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerAllowDealerToLogin(
      dealer: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerAllowDealerToLogin(dealer, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {string} packet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerAssignPacket(
      dealer: string,
      packet: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDealerDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerAssignPacket(dealer, packet, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {CarDetailing} detailing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerChangeDetailing(
      dealer: string,
      detailing: CarDetailing,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerChangeDetailing(
        dealer,
        detailing,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerChangeName(
      dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerChangeName(
        dealerChangeNameRequestDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerChangeNameForDealer(
      dealer: string,
      dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerChangeNameForDealer(
        dealer,
        dealerChangeNameRequestDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerCreatePlatformCredentials(
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerCreatePlatformCredentials(
        platformCredentialsDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerCreatePlatformCredentialsForDealer(
      dealer: string,
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerCreatePlatformCredentialsForDealer(
        dealer,
        platformCredentialsDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerDeleteDeliveryAddress(
      addressId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerDeleteDeliveryAddress(
        addressId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerDeleteDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerDeleteDeliveryAddressForDealer(
        dealer,
        addressId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerDeletePlatformCredentials(
      source: SourceType,
      deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerDeletePlatformCredentials(
        source,
        deletePlatformCredentialRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {SourceType} source
     * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerDeletePlatformCredentialsForDealer(
      dealer: string,
      source: SourceType,
      deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerDeletePlatformCredentialsForDealer(
        dealer,
        source,
        deletePlatformCredentialRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerDisallowDealerToLogin(
      dealer: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerDisallowDealerToLogin(dealer, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetAllowedSourceTypes(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetAllowedSourceTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetAllowedSourceTypesForDealer(
      dealer: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetAllowedSourceTypesForDealer(
        dealer,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetCosts(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostsDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetCosts(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetDealers(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedDealerDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetDealers(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetDeliveryAddress(
      addressId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetDeliveryAddress(addressId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetDeliveryAddressForDealer(
        dealer,
        addressId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetDeliveryAddresses(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<DeliveryAddressDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetDeliveryAddresses(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetDeliveryAddressesForDealer(
      dealer: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<DeliveryAddressDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetDeliveryAddressesForDealer(
        dealer,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetMyDealer(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDealerDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetMyDealer(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceTypeBasic} [source]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetPlatformCredentials(
      source?: SourceTypeBasic,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlatformCredentialsResponseDTOBasic>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetPlatformCredentials(source, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {SourceTypeExtended} [source]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerGetPlatformCredentialsForDealer(
      dealer: string,
      source?: SourceTypeExtended,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlatformCredentialsResponseDTOExtended>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetPlatformCredentialsForDealer(
        dealer,
        source,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async dealerControllerGetPlatformCredentialsV1(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsernameStatusDTOBasic>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerGetPlatformCredentialsV1(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {Array<SourceType>} sourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerSaveAllowedSourceTypes(
      dealer: string,
      sourceType: Array<SourceType>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerSaveAllowedSourceTypes(
        dealer,
        sourceType,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CostsDTO} costsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerSaveCosts(
      costsDTO: CostsDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostsDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerSaveCosts(costsDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {DetailedDealerDTO} detailedDealerDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerSaveDealer(
      dealer: string,
      detailedDealerDTO: DetailedDealerDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDealerDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerSaveDealer(
        dealer,
        detailedDealerDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerSaveDeliveryAddress(
      deliveryAddressDTO: DeliveryAddressDTO,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerSaveDeliveryAddress(
        deliveryAddressDTO,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerSaveDeliveryAddressForDealer(
      dealer: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerSaveDeliveryAddressForDealer(
        dealer,
        deliveryAddressDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async dealerControllerSavePlatformCredentials(
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerSavePlatformCredentials(
        platformCredentialsDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {string} packet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerUnassignPacket(
      dealer: string,
      packet: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedDealerDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerUnassignPacket(dealer, packet, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerUpdateDefaultDeliveryAddress(
      addressId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerUpdateDefaultDeliveryAddress(
        addressId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerUpdateDefaultDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerUpdateDefaultDeliveryAddressForDealer(
        dealer,
        addressId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} addressId
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerUpdateDeliveryAddress(
      addressId: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerUpdateDeliveryAddress(
        addressId,
        deliveryAddressDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerUpdateDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryAddressDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerUpdateDeliveryAddressForDealer(
        dealer,
        addressId,
        deliveryAddressDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerUpdatePlatformCredentials(
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerUpdatePlatformCredentials(
        platformCredentialsDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealerControllerUpdatePlatformCredentialsForDealer(
      dealer: string,
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealerControllerUpdatePlatformCredentialsForDealer(
        dealer,
        platformCredentialsDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DealerControllerApi - factory interface
 * @export
 */
export const DealerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DealerControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerAllowDealerToLogin(dealer: string, options?: any): AxiosPromise<void> {
      return localVarFp.dealerControllerAllowDealerToLogin(dealer, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {string} packet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerAssignPacket(dealer: string, packet: string, options?: any): AxiosPromise<DetailedDealerDTO> {
      return localVarFp
        .dealerControllerAssignPacket(dealer, packet, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {CarDetailing} detailing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerChangeDetailing(dealer: string, detailing: CarDetailing, options?: any): AxiosPromise<void> {
      return localVarFp
        .dealerControllerChangeDetailing(dealer, detailing, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerChangeName(
      dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerChangeName(dealerChangeNameRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerChangeNameForDealer(
      dealer: string,
      dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerChangeNameForDealer(dealer, dealerChangeNameRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerCreatePlatformCredentials(
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerCreatePlatformCredentials(platformCredentialsDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerCreatePlatformCredentialsForDealer(
      dealer: string,
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerCreatePlatformCredentialsForDealer(dealer, platformCredentialsDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeleteDeliveryAddress(addressId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .dealerControllerDeleteDeliveryAddress(addressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeleteDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerDeleteDeliveryAddressForDealer(dealer, addressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeletePlatformCredentials(
      source: SourceType,
      deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerDeletePlatformCredentials(source, deletePlatformCredentialRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {SourceType} source
     * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDeletePlatformCredentialsForDealer(
      dealer: string,
      source: SourceType,
      deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerDeletePlatformCredentialsForDealer(dealer, source, deletePlatformCredentialRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerDisallowDealerToLogin(dealer: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .dealerControllerDisallowDealerToLogin(dealer, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetAllowedSourceTypes(options?: any): AxiosPromise<Array<SourceType>> {
      return localVarFp.dealerControllerGetAllowedSourceTypes(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetAllowedSourceTypesForDealer(dealer: string, options?: any): AxiosPromise<Array<SourceType>> {
      return localVarFp
        .dealerControllerGetAllowedSourceTypesForDealer(dealer, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetCosts(options?: any): AxiosPromise<CostsDTO> {
      return localVarFp.dealerControllerGetCosts(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDealers(options?: any): AxiosPromise<Array<DetailedDealerDTO>> {
      return localVarFp.dealerControllerGetDealers(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddress(addressId: string, options?: any): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerGetDeliveryAddress(addressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      options?: any,
    ): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerGetDeliveryAddressForDealer(dealer, addressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddresses(options?: any): AxiosPromise<Set<DeliveryAddressDTO>> {
      return localVarFp.dealerControllerGetDeliveryAddresses(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetDeliveryAddressesForDealer(
      dealer: string,
      options?: any,
    ): AxiosPromise<Set<DeliveryAddressDTO>> {
      return localVarFp
        .dealerControllerGetDeliveryAddressesForDealer(dealer, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetMyDealer(options?: any): AxiosPromise<DetailedDealerDTO> {
      return localVarFp.dealerControllerGetMyDealer(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceTypeBasic} [source]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetPlatformCredentials(
      source?: SourceTypeBasic,
      options?: any,
    ): AxiosPromise<Array<PlatformCredentialsResponseDTOBasic>> {
      return localVarFp
        .dealerControllerGetPlatformCredentials(source, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {SourceTypeExtended} [source]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerGetPlatformCredentialsForDealer(
      dealer: string,
      source?: SourceTypeExtended,
      options?: any,
    ): AxiosPromise<Array<PlatformCredentialsResponseDTOExtended>> {
      return localVarFp
        .dealerControllerGetPlatformCredentialsForDealer(dealer, source, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    dealerControllerGetPlatformCredentialsV1(options?: any): AxiosPromise<Array<UsernameStatusDTOBasic>> {
      return localVarFp.dealerControllerGetPlatformCredentialsV1(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {Array<SourceType>} sourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveAllowedSourceTypes(
      dealer: string,
      sourceType: Array<SourceType>,
      options?: any,
    ): AxiosPromise<Array<SourceType>> {
      return localVarFp
        .dealerControllerSaveAllowedSourceTypes(dealer, sourceType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CostsDTO} costsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveCosts(costsDTO: CostsDTO, options?: any): AxiosPromise<CostsDTO> {
      return localVarFp.dealerControllerSaveCosts(costsDTO, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {DetailedDealerDTO} detailedDealerDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveDealer(
      dealer: string,
      detailedDealerDTO: DetailedDealerDTO,
      options?: any,
    ): AxiosPromise<DetailedDealerDTO> {
      return localVarFp
        .dealerControllerSaveDealer(dealer, detailedDealerDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveDeliveryAddress(
      deliveryAddressDTO: DeliveryAddressDTO,
      version?: number,
      options?: any,
    ): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerSaveDeliveryAddress(deliveryAddressDTO, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerSaveDeliveryAddressForDealer(
      dealer: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options?: any,
    ): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerSaveDeliveryAddressForDealer(dealer, deliveryAddressDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    dealerControllerSavePlatformCredentials(
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerSavePlatformCredentials(platformCredentialsDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {string} packet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUnassignPacket(dealer: string, packet: string, options?: any): AxiosPromise<DetailedDealerDTO> {
      return localVarFp
        .dealerControllerUnassignPacket(dealer, packet, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDefaultDeliveryAddress(addressId: string, options?: any): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerUpdateDefaultDeliveryAddress(addressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDefaultDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      options?: any,
    ): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerUpdateDefaultDeliveryAddressForDealer(dealer, addressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} addressId
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDeliveryAddress(
      addressId: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options?: any,
    ): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerUpdateDeliveryAddress(addressId, deliveryAddressDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {string} addressId
     * @param {DeliveryAddressDTO} deliveryAddressDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdateDeliveryAddressForDealer(
      dealer: string,
      addressId: string,
      deliveryAddressDTO: DeliveryAddressDTO,
      options?: any,
    ): AxiosPromise<DeliveryAddressDTO> {
      return localVarFp
        .dealerControllerUpdateDeliveryAddressForDealer(dealer, addressId, deliveryAddressDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdatePlatformCredentials(
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerUpdatePlatformCredentials(platformCredentialsDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {PlatformCredentialsDTO} platformCredentialsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealerControllerUpdatePlatformCredentialsForDealer(
      dealer: string,
      platformCredentialsDTO: PlatformCredentialsDTO,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dealerControllerUpdatePlatformCredentialsForDealer(dealer, platformCredentialsDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DealerControllerApi - object-oriented interface
 * @export
 * @class DealerControllerApi
 * @extends {BaseAPI}
 */
export class DealerControllerApi extends BaseAPI {
  /**
   *
   * @param {string} dealer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerAllowDealerToLogin(dealer: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerAllowDealerToLogin(dealer, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {string} packet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerAssignPacket(dealer: string, packet: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerAssignPacket(dealer, packet, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {CarDetailing} detailing
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerChangeDetailing(dealer: string, detailing: CarDetailing, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerChangeDetailing(dealer, detailing, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerChangeName(
    dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerChangeName(dealerChangeNameRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {DealerChangeNameRequestDTO} dealerChangeNameRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerChangeNameForDealer(
    dealer: string,
    dealerChangeNameRequestDTO: DealerChangeNameRequestDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerChangeNameForDealer(dealer, dealerChangeNameRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PlatformCredentialsDTO} platformCredentialsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerCreatePlatformCredentials(
    platformCredentialsDTO: PlatformCredentialsDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerCreatePlatformCredentials(platformCredentialsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {PlatformCredentialsDTO} platformCredentialsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerCreatePlatformCredentialsForDealer(
    dealer: string,
    platformCredentialsDTO: PlatformCredentialsDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerCreatePlatformCredentialsForDealer(dealer, platformCredentialsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} addressId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerDeleteDeliveryAddress(addressId: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerDeleteDeliveryAddress(addressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {string} addressId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerDeleteDeliveryAddressForDealer(
    dealer: string,
    addressId: string,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerDeleteDeliveryAddressForDealer(dealer, addressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerDeletePlatformCredentials(
    source: SourceType,
    deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerDeletePlatformCredentials(source, deletePlatformCredentialRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {SourceType} source
   * @param {DeletePlatformCredentialRequest} deletePlatformCredentialRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerDeletePlatformCredentialsForDealer(
    dealer: string,
    source: SourceType,
    deletePlatformCredentialRequest: DeletePlatformCredentialRequest,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerDeletePlatformCredentialsForDealer(dealer, source, deletePlatformCredentialRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerDisallowDealerToLogin(dealer: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerDisallowDealerToLogin(dealer, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetAllowedSourceTypes(options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetAllowedSourceTypes(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetAllowedSourceTypesForDealer(dealer: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetAllowedSourceTypesForDealer(dealer, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetCosts(options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetCosts(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetDealers(options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetDealers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} addressId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetDeliveryAddress(addressId: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetDeliveryAddress(addressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {string} addressId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetDeliveryAddressForDealer(dealer: string, addressId: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetDeliveryAddressForDealer(dealer, addressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetDeliveryAddresses(options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetDeliveryAddresses(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetDeliveryAddressesForDealer(dealer: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetDeliveryAddressesForDealer(dealer, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetMyDealer(options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetMyDealer(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceTypeBasic} [source]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetPlatformCredentials(source?: SourceTypeBasic, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetPlatformCredentials(source, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {SourceTypeExtended} [source]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetPlatformCredentialsForDealer(
    dealer: string,
    source?: SourceTypeExtended,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetPlatformCredentialsForDealer(dealer, source, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerGetPlatformCredentialsV1(options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerGetPlatformCredentialsV1(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {Array<SourceType>} sourceType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerSaveAllowedSourceTypes(
    dealer: string,
    sourceType: Array<SourceType>,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerSaveAllowedSourceTypes(dealer, sourceType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CostsDTO} costsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerSaveCosts(costsDTO: CostsDTO, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerSaveCosts(costsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {DetailedDealerDTO} detailedDealerDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerSaveDealer(
    dealer: string,
    detailedDealerDTO: DetailedDealerDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerSaveDealer(dealer, detailedDealerDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeliveryAddressDTO} deliveryAddressDTO
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerSaveDeliveryAddress(
    deliveryAddressDTO: DeliveryAddressDTO,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerSaveDeliveryAddress(deliveryAddressDTO, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {DeliveryAddressDTO} deliveryAddressDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerSaveDeliveryAddressForDealer(
    dealer: string,
    deliveryAddressDTO: DeliveryAddressDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerSaveDeliveryAddressForDealer(dealer, deliveryAddressDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PlatformCredentialsDTO} platformCredentialsDTO
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerSavePlatformCredentials(
    platformCredentialsDTO: PlatformCredentialsDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerSavePlatformCredentials(platformCredentialsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {string} packet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerUnassignPacket(dealer: string, packet: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerUnassignPacket(dealer, packet, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} addressId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerUpdateDefaultDeliveryAddress(addressId: string, options?: AxiosRequestConfig) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerUpdateDefaultDeliveryAddress(addressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {string} addressId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerUpdateDefaultDeliveryAddressForDealer(
    dealer: string,
    addressId: string,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerUpdateDefaultDeliveryAddressForDealer(dealer, addressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} addressId
   * @param {DeliveryAddressDTO} deliveryAddressDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerUpdateDeliveryAddress(
    addressId: string,
    deliveryAddressDTO: DeliveryAddressDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerUpdateDeliveryAddress(addressId, deliveryAddressDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {string} addressId
   * @param {DeliveryAddressDTO} deliveryAddressDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerUpdateDeliveryAddressForDealer(
    dealer: string,
    addressId: string,
    deliveryAddressDTO: DeliveryAddressDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerUpdateDeliveryAddressForDealer(dealer, addressId, deliveryAddressDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PlatformCredentialsDTO} platformCredentialsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerUpdatePlatformCredentials(
    platformCredentialsDTO: PlatformCredentialsDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerUpdatePlatformCredentials(platformCredentialsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {PlatformCredentialsDTO} platformCredentialsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealerControllerApi
   */
  public dealerControllerUpdatePlatformCredentialsForDealer(
    dealer: string,
    platformCredentialsDTO: PlatformCredentialsDTO,
    options?: AxiosRequestConfig,
  ) {
    return DealerControllerApiFp(this.configuration)
      .dealerControllerUpdatePlatformCredentialsForDealer(dealer, platformCredentialsDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExportControllerApi - axios parameter creator
 * @export
 */
export const ExportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportControllerListBids: async (
      bidsAndCarsRequestDTO: BidsAndCarsRequestDTO,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bidsAndCarsRequestDTO' is not null or undefined
      assertParamExists('exportControllerListBids', 'bidsAndCarsRequestDTO', bidsAndCarsRequestDTO);
      const localVarPath = `/api/V1/export/bids`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(bidsAndCarsRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ExportCarsRequestDTO} exportCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportControllerListCars: async (
      exportCarsRequestDTO: ExportCarsRequestDTO,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'exportCarsRequestDTO' is not null or undefined
      assertParamExists('exportControllerListCars', 'exportCarsRequestDTO', exportCarsRequestDTO);
      const localVarPath = `/api/V1/export/cars`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(exportCarsRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExportControllerApi - functional programming interface
 * @export
 */
export const ExportControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExportControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportControllerListBids(
      bidsAndCarsRequestDTO: BidsAndCarsRequestDTO,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportControllerListBids(
        bidsAndCarsRequestDTO,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExportCarsRequestDTO} exportCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportControllerListCars(
      exportCarsRequestDTO: ExportCarsRequestDTO,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportControllerListCars(
        exportCarsRequestDTO,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ExportControllerApi - factory interface
 * @export
 */
export const ExportControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExportControllerApiFp(configuration);
  return {
    /**
     *
     * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportControllerListBids(
      bidsAndCarsRequestDTO: BidsAndCarsRequestDTO,
      version?: number,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportControllerListBids(bidsAndCarsRequestDTO, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ExportCarsRequestDTO} exportCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportControllerListCars(
      exportCarsRequestDTO: ExportCarsRequestDTO,
      version?: number,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportControllerListCars(exportCarsRequestDTO, version, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ExportControllerApi - object-oriented interface
 * @export
 * @class ExportControllerApi
 * @extends {BaseAPI}
 */
export class ExportControllerApi extends BaseAPI {
  /**
   *
   * @param {BidsAndCarsRequestDTO} bidsAndCarsRequestDTO
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportControllerApi
   */
  public exportControllerListBids(
    bidsAndCarsRequestDTO: BidsAndCarsRequestDTO,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return ExportControllerApiFp(this.configuration)
      .exportControllerListBids(bidsAndCarsRequestDTO, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExportCarsRequestDTO} exportCarsRequestDTO
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportControllerApi
   */
  public exportControllerListCars(
    exportCarsRequestDTO: ExportCarsRequestDTO,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return ExportControllerApiFp(this.configuration)
      .exportControllerListCars(exportCarsRequestDTO, version, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExportImagesControllerApi - axios parameter creator
 * @export
 */
export const ExportImagesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportImagesControllerGetGalleryData: async (
      vin: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('exportImagesControllerGetGalleryData', 'vin', vin);
      const localVarPath = `/api/images/export/{vin}`.replace(`{${'vin'}}`, encodeURIComponent(String(vin)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExportImagesControllerApi - functional programming interface
 * @export
 */
export const ExportImagesControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExportImagesControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportImagesControllerGetGalleryData(
      vin: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GalleryDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportImagesControllerGetGalleryData(vin, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ExportImagesControllerApi - factory interface
 * @export
 */
export const ExportImagesControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExportImagesControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportImagesControllerGetGalleryData(vin: string, options?: any): AxiosPromise<GalleryDTO> {
      return localVarFp.exportImagesControllerGetGalleryData(vin, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ExportImagesControllerApi - object-oriented interface
 * @export
 * @class ExportImagesControllerApi
 * @extends {BaseAPI}
 */
export class ExportImagesControllerApi extends BaseAPI {
  /**
   *
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportImagesControllerApi
   */
  public exportImagesControllerGetGalleryData(vin: string, options?: AxiosRequestConfig) {
    return ExportImagesControllerApiFp(this.configuration)
      .exportImagesControllerGetGalleryData(vin, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ImageControllerApi - axios parameter creator
 * @export
 */
export const ImageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CountryCode} country
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imageControllerGetCarImage: async (
      country: CountryCode,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'country' is not null or undefined
      assertParamExists('imageControllerGetCarImage', 'country', country);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('imageControllerGetCarImage', 'id', id);
      const localVarPath = `/api/cars/image/{country}/{id}`
        .replace(`{${'country'}}`, encodeURIComponent(String(country)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ImageControllerApi - functional programming interface
 * @export
 */
export const ImageControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ImageControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CountryCode} country
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async imageControllerGetCarImage(
      country: CountryCode,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.imageControllerGetCarImage(country, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ImageControllerApi - factory interface
 * @export
 */
export const ImageControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ImageControllerApiFp(configuration);
  return {
    /**
     *
     * @param {CountryCode} country
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imageControllerGetCarImage(country: CountryCode, id: string, options?: any): AxiosPromise<any> {
      return localVarFp.imageControllerGetCarImage(country, id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ImageControllerApi - object-oriented interface
 * @export
 * @class ImageControllerApi
 * @extends {BaseAPI}
 */
export class ImageControllerApi extends BaseAPI {
  /**
   *
   * @param {CountryCode} country
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageControllerApi
   */
  public imageControllerGetCarImage(country: CountryCode, id: string, options?: AxiosRequestConfig) {
    return ImageControllerApiFp(this.configuration)
      .imageControllerGetCarImage(country, id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ListCarsControllerApi - axios parameter creator
 * @export
 */
export const ListCarsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {number} [version]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerDetail: async (
      source: SourceType,
      carId: string,
      version?: number,
      valuationCountry?: ValuationCountryCode,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('listCarsControllerDetail', 'source', source);
      // verify required parameter 'carId' is not null or undefined
      assertParamExists('listCarsControllerDetail', 'carId', carId);
      const localVarPath = `/api/cars/{source}/{carId}`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'carId'}}`, encodeURIComponent(String(carId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      if (valuationCountry !== undefined) {
        localVarQueryParameter['valuationCountry'] = valuationCountry;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} vin
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerDetailByVin: async (
      vin: string,
      valuationCountry?: ValuationCountryCode,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('listCarsControllerDetailByVin', 'vin', vin);
      const localVarPath = `/api/cars/{vin}`.replace(`{${'vin'}}`, encodeURIComponent(String(vin)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (valuationCountry !== undefined) {
        localVarQueryParameter['valuationCountry'] = valuationCountry;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OnlineCarsRequestDTO} onlineCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerList: async (
      onlineCarsRequestDTO: OnlineCarsRequestDTO,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'onlineCarsRequestDTO' is not null or undefined
      assertParamExists('listCarsControllerList', 'onlineCarsRequestDTO', onlineCarsRequestDTO);
      const localVarPath = `/api/cars/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(onlineCarsRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SummaryRequestDTO} summaryRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerSummaryPost: async (
      summaryRequestDTO: SummaryRequestDTO,
      version?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'summaryRequestDTO' is not null or undefined
      assertParamExists('listCarsControllerSummaryPost', 'summaryRequestDTO', summaryRequestDTO);
      const localVarPath = `/api/cars/list/summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(summaryRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {ListCarDTO} listCarDTO
     * @param {number} [version]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerUpdateCar: async (
      source: SourceType,
      carId: string,
      listCarDTO: ListCarDTO,
      version?: number,
      valuationCountry?: ValuationCountryCode,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('listCarsControllerUpdateCar', 'source', source);
      // verify required parameter 'carId' is not null or undefined
      assertParamExists('listCarsControllerUpdateCar', 'carId', carId);
      // verify required parameter 'listCarDTO' is not null or undefined
      assertParamExists('listCarsControllerUpdateCar', 'listCarDTO', listCarDTO);
      const localVarPath = `/api/cars/{source}/{carId}`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'carId'}}`, encodeURIComponent(String(carId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      if (valuationCountry !== undefined) {
        localVarQueryParameter['valuationCountry'] = valuationCountry;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(listCarDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ListCarsControllerApi - functional programming interface
 * @export
 */
export const ListCarsControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ListCarsControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {number} [version]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCarsControllerDetail(
      source: SourceType,
      carId: string,
      version?: number,
      valuationCountry?: ValuationCountryCode,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailCarDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCarsControllerDetail(
        source,
        carId,
        version,
        valuationCountry,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} vin
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCarsControllerDetailByVin(
      vin: string,
      valuationCountry?: ValuationCountryCode,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailCarDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCarsControllerDetailByVin(
        vin,
        valuationCountry,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {OnlineCarsRequestDTO} onlineCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCarsControllerList(
      onlineCarsRequestDTO: OnlineCarsRequestDTO,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTablesOutputListCarDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCarsControllerList(
        onlineCarsRequestDTO,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SummaryRequestDTO} summaryRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCarsControllerSummaryPost(
      summaryRequestDTO: SummaryRequestDTO,
      version?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummaryDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCarsControllerSummaryPost(
        summaryRequestDTO,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {ListCarDTO} listCarDTO
     * @param {number} [version]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCarsControllerUpdateCar(
      source: SourceType,
      carId: string,
      listCarDTO: ListCarDTO,
      version?: number,
      valuationCountry?: ValuationCountryCode,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailCarDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCarsControllerUpdateCar(
        source,
        carId,
        listCarDTO,
        version,
        valuationCountry,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ListCarsControllerApi - factory interface
 * @export
 */
export const ListCarsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ListCarsControllerApiFp(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {number} [version]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerDetail(
      source: SourceType,
      carId: string,
      version?: number,
      valuationCountry?: ValuationCountryCode,
      options?: any,
    ): AxiosPromise<DetailCarDTO> {
      return localVarFp
        .listCarsControllerDetail(source, carId, version, valuationCountry, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} vin
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerDetailByVin(
      vin: string,
      valuationCountry?: ValuationCountryCode,
      options?: any,
    ): AxiosPromise<DetailCarDTO> {
      return localVarFp
        .listCarsControllerDetailByVin(vin, valuationCountry, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {OnlineCarsRequestDTO} onlineCarsRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerList(
      onlineCarsRequestDTO: OnlineCarsRequestDTO,
      version?: number,
      options?: any,
    ): AxiosPromise<DataTablesOutputListCarDTO> {
      return localVarFp
        .listCarsControllerList(onlineCarsRequestDTO, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SummaryRequestDTO} summaryRequestDTO
     * @param {number} [version]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerSummaryPost(
      summaryRequestDTO: SummaryRequestDTO,
      version?: number,
      options?: any,
    ): AxiosPromise<SummaryDTO> {
      return localVarFp
        .listCarsControllerSummaryPost(summaryRequestDTO, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {ListCarDTO} listCarDTO
     * @param {number} [version]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCarsControllerUpdateCar(
      source: SourceType,
      carId: string,
      listCarDTO: ListCarDTO,
      version?: number,
      valuationCountry?: ValuationCountryCode,
      options?: any,
    ): AxiosPromise<DetailCarDTO> {
      return localVarFp
        .listCarsControllerUpdateCar(source, carId, listCarDTO, version, valuationCountry, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ListCarsControllerApi - object-oriented interface
 * @export
 * @class ListCarsControllerApi
 * @extends {BaseAPI}
 */
export class ListCarsControllerApi extends BaseAPI {
  /**
   *
   * @param {SourceType} source
   * @param {string} carId
   * @param {number} [version]
   * @param {ValuationCountryCode} [valuationCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListCarsControllerApi
   */
  public listCarsControllerDetail(
    source: SourceType,
    carId: string,
    version?: number,
    valuationCountry?: ValuationCountryCode,
    options?: AxiosRequestConfig,
  ) {
    return ListCarsControllerApiFp(this.configuration)
      .listCarsControllerDetail(source, carId, version, valuationCountry, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} vin
   * @param {ValuationCountryCode} [valuationCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListCarsControllerApi
   */
  public listCarsControllerDetailByVin(
    vin: string,
    valuationCountry?: ValuationCountryCode,
    options?: AxiosRequestConfig,
  ) {
    return ListCarsControllerApiFp(this.configuration)
      .listCarsControllerDetailByVin(vin, valuationCountry, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OnlineCarsRequestDTO} onlineCarsRequestDTO
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListCarsControllerApi
   */
  public listCarsControllerList(
    onlineCarsRequestDTO: OnlineCarsRequestDTO,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return ListCarsControllerApiFp(this.configuration)
      .listCarsControllerList(onlineCarsRequestDTO, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SummaryRequestDTO} summaryRequestDTO
   * @param {number} [version]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListCarsControllerApi
   */
  public listCarsControllerSummaryPost(
    summaryRequestDTO: SummaryRequestDTO,
    version?: number,
    options?: AxiosRequestConfig,
  ) {
    return ListCarsControllerApiFp(this.configuration)
      .listCarsControllerSummaryPost(summaryRequestDTO, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {string} carId
   * @param {ListCarDTO} listCarDTO
   * @param {number} [version]
   * @param {ValuationCountryCode} [valuationCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListCarsControllerApi
   */
  public listCarsControllerUpdateCar(
    source: SourceType,
    carId: string,
    listCarDTO: ListCarDTO,
    version?: number,
    valuationCountry?: ValuationCountryCode,
    options?: AxiosRequestConfig,
  ) {
    return ListCarsControllerApiFp(this.configuration)
      .listCarsControllerUpdateCar(source, carId, listCarDTO, version, valuationCountry, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ManualImportControllerApi - axios parameter creator
 * @export
 */
export const ManualImportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SourceType} source
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    manualImportControllerImportCars: async (
      source: SourceType,
      file: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('manualImportControllerImportCars', 'source', source);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('manualImportControllerImportCars', 'file', file);
      const localVarPath = `/api/manual-import/{source}`.replace(`{${'source'}}`, encodeURIComponent(String(source)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} offerId
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    manualImportControllerInactivateOffer: async (
      source: SourceType,
      offerId: string,
      carId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('manualImportControllerInactivateOffer', 'source', source);
      // verify required parameter 'offerId' is not null or undefined
      assertParamExists('manualImportControllerInactivateOffer', 'offerId', offerId);
      // verify required parameter 'carId' is not null or undefined
      assertParamExists('manualImportControllerInactivateOffer', 'carId', carId);
      const localVarPath = `/api/manual-import/{source}/inactivate`.replace(
        `{${'source'}}`,
        encodeURIComponent(String(source)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (offerId !== undefined) {
        localVarQueryParameter['offerId'] = offerId;
      }

      if (carId !== undefined) {
        localVarQueryParameter['carId'] = carId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManualImportControllerApi - functional programming interface
 * @export
 */
export const ManualImportControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ManualImportControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async manualImportControllerImportCars(
      source: SourceType,
      file: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.manualImportControllerImportCars(source, file, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} offerId
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async manualImportControllerInactivateOffer(
      source: SourceType,
      offerId: string,
      carId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.manualImportControllerInactivateOffer(
        source,
        offerId,
        carId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ManualImportControllerApi - factory interface
 * @export
 */
export const ManualImportControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ManualImportControllerApiFp(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    manualImportControllerImportCars(source: SourceType, file: any, options?: any): AxiosPromise<Array<string>> {
      return localVarFp
        .manualImportControllerImportCars(source, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} offerId
     * @param {string} carId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    manualImportControllerInactivateOffer(
      source: SourceType,
      offerId: string,
      carId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .manualImportControllerInactivateOffer(source, offerId, carId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ManualImportControllerApi - object-oriented interface
 * @export
 * @class ManualImportControllerApi
 * @extends {BaseAPI}
 */
export class ManualImportControllerApi extends BaseAPI {
  /**
   *
   * @param {SourceType} source
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManualImportControllerApi
   */
  public manualImportControllerImportCars(source: SourceType, file: any, options?: AxiosRequestConfig) {
    return ManualImportControllerApiFp(this.configuration)
      .manualImportControllerImportCars(source, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {string} offerId
   * @param {string} carId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManualImportControllerApi
   */
  public manualImportControllerInactivateOffer(
    source: SourceType,
    offerId: string,
    carId: string,
    options?: AxiosRequestConfig,
  ) {
    return ManualImportControllerApiFp(this.configuration)
      .manualImportControllerInactivateOffer(source, offerId, carId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PacketControllerApi - axios parameter creator
 * @export
 */
export const PacketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {PacketCreateDTO} packetCreateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerCreatePacket: async (
      packetCreateDTO: PacketCreateDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'packetCreateDTO' is not null or undefined
      assertParamExists('packetControllerCreatePacket', 'packetCreateDTO', packetCreateDTO);
      const localVarPath = `/api/packet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(packetCreateDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} packetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerDeletePacket: async (packetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'packetId' is not null or undefined
      assertParamExists('packetControllerDeletePacket', 'packetId', packetId);
      const localVarPath = `/api/packet/{packetId}`.replace(`{${'packetId'}}`, encodeURIComponent(String(packetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} packetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerGetPacket: async (packetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'packetId' is not null or undefined
      assertParamExists('packetControllerGetPacket', 'packetId', packetId);
      const localVarPath = `/api/packet/{packetId}`.replace(`{${'packetId'}}`, encodeURIComponent(String(packetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerGetPackets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/packet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CountryCode} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerPacketsByCountry: async (
      country: CountryCode,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'country' is not null or undefined
      assertParamExists('packetControllerPacketsByCountry', 'country', country);
      const localVarPath = `/api/packet/country/{country}`.replace(
        `{${'country'}}`,
        encodeURIComponent(String(country)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} packetId
     * @param {PacketUpdateDTO} packetUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerUpdatePacket: async (
      packetId: string,
      packetUpdateDTO: PacketUpdateDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'packetId' is not null or undefined
      assertParamExists('packetControllerUpdatePacket', 'packetId', packetId);
      // verify required parameter 'packetUpdateDTO' is not null or undefined
      assertParamExists('packetControllerUpdatePacket', 'packetUpdateDTO', packetUpdateDTO);
      const localVarPath = `/api/packet/{packetId}`.replace(`{${'packetId'}}`, encodeURIComponent(String(packetId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(packetUpdateDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PacketControllerApi - functional programming interface
 * @export
 */
export const PacketControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PacketControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {PacketCreateDTO} packetCreateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packetControllerCreatePacket(
      packetCreateDTO: PacketCreateDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packetControllerCreatePacket(packetCreateDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} packetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packetControllerDeletePacket(
      packetId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packetControllerDeletePacket(packetId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} packetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packetControllerGetPacket(
      packetId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packetControllerGetPacket(packetId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packetControllerGetPackets(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PacketDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packetControllerGetPackets(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CountryCode} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packetControllerPacketsByCountry(
      country: CountryCode,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PacketDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packetControllerPacketsByCountry(country, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} packetId
     * @param {PacketUpdateDTO} packetUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packetControllerUpdatePacket(
      packetId: string,
      packetUpdateDTO: PacketUpdateDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packetControllerUpdatePacket(
        packetId,
        packetUpdateDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PacketControllerApi - factory interface
 * @export
 */
export const PacketControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PacketControllerApiFp(configuration);
  return {
    /**
     *
     * @param {PacketCreateDTO} packetCreateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerCreatePacket(packetCreateDTO: PacketCreateDTO, options?: any): AxiosPromise<PacketDTO> {
      return localVarFp
        .packetControllerCreatePacket(packetCreateDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} packetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerDeletePacket(packetId: string, options?: any): AxiosPromise<PacketDTO> {
      return localVarFp.packetControllerDeletePacket(packetId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} packetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerGetPacket(packetId: string, options?: any): AxiosPromise<PacketDTO> {
      return localVarFp.packetControllerGetPacket(packetId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerGetPackets(options?: any): AxiosPromise<Array<PacketDTO>> {
      return localVarFp.packetControllerGetPackets(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CountryCode} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerPacketsByCountry(country: CountryCode, options?: any): AxiosPromise<Array<PacketDTO>> {
      return localVarFp.packetControllerPacketsByCountry(country, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} packetId
     * @param {PacketUpdateDTO} packetUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packetControllerUpdatePacket(
      packetId: string,
      packetUpdateDTO: PacketUpdateDTO,
      options?: any,
    ): AxiosPromise<PacketDTO> {
      return localVarFp
        .packetControllerUpdatePacket(packetId, packetUpdateDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PacketControllerApi - object-oriented interface
 * @export
 * @class PacketControllerApi
 * @extends {BaseAPI}
 */
export class PacketControllerApi extends BaseAPI {
  /**
   *
   * @param {PacketCreateDTO} packetCreateDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PacketControllerApi
   */
  public packetControllerCreatePacket(packetCreateDTO: PacketCreateDTO, options?: AxiosRequestConfig) {
    return PacketControllerApiFp(this.configuration)
      .packetControllerCreatePacket(packetCreateDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} packetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PacketControllerApi
   */
  public packetControllerDeletePacket(packetId: string, options?: AxiosRequestConfig) {
    return PacketControllerApiFp(this.configuration)
      .packetControllerDeletePacket(packetId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} packetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PacketControllerApi
   */
  public packetControllerGetPacket(packetId: string, options?: AxiosRequestConfig) {
    return PacketControllerApiFp(this.configuration)
      .packetControllerGetPacket(packetId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PacketControllerApi
   */
  public packetControllerGetPackets(options?: AxiosRequestConfig) {
    return PacketControllerApiFp(this.configuration)
      .packetControllerGetPackets(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CountryCode} country
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PacketControllerApi
   */
  public packetControllerPacketsByCountry(country: CountryCode, options?: AxiosRequestConfig) {
    return PacketControllerApiFp(this.configuration)
      .packetControllerPacketsByCountry(country, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} packetId
   * @param {PacketUpdateDTO} packetUpdateDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PacketControllerApi
   */
  public packetControllerUpdatePacket(
    packetId: string,
    packetUpdateDTO: PacketUpdateDTO,
    options?: AxiosRequestConfig,
  ) {
    return PacketControllerApiFp(this.configuration)
      .packetControllerUpdatePacket(packetId, packetUpdateDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PriceCalculationControllerApi - axios parameter creator
 * @export
 */
export const PriceCalculationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {PriceType} fixedVariable
     * @param {number} fixedValue
     * @param {CurrencyCode} [fixedValueCurrency]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    priceCalculationControllerCalculatePrice: async (
      source: SourceType,
      carId: string,
      fixedVariable: PriceType,
      fixedValue: number,
      fixedValueCurrency?: CurrencyCode,
      valuationCountry?: ValuationCountryCode,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePrice', 'source', source);
      // verify required parameter 'carId' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePrice', 'carId', carId);
      // verify required parameter 'fixedVariable' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePrice', 'fixedVariable', fixedVariable);
      // verify required parameter 'fixedValue' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePrice', 'fixedValue', fixedValue);
      const localVarPath = `/api/calculation/{source}/{carId}`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'carId'}}`, encodeURIComponent(String(carId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fixedVariable !== undefined) {
        localVarQueryParameter['fixedVariable'] = fixedVariable;
      }

      if (fixedValue !== undefined) {
        localVarQueryParameter['fixedValue'] = fixedValue;
      }

      if (fixedValueCurrency !== undefined) {
        localVarQueryParameter['fixedValueCurrency'] = fixedValueCurrency;
      }

      if (valuationCountry !== undefined) {
        localVarQueryParameter['valuationCountry'] = valuationCountry;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {PriceType} fixedVariable
     * @param {number} fixedValue
     * @param {CurrencyCode} [fixedValueCurrency]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculationControllerCalculatePriceV2: async (
      source: SourceType,
      carId: string,
      fixedVariable: PriceType,
      fixedValue: number,
      fixedValueCurrency?: CurrencyCode,
      valuationCountry?: ValuationCountryCode,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePriceV2', 'source', source);
      // verify required parameter 'carId' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePriceV2', 'carId', carId);
      // verify required parameter 'fixedVariable' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePriceV2', 'fixedVariable', fixedVariable);
      // verify required parameter 'fixedValue' is not null or undefined
      assertParamExists('priceCalculationControllerCalculatePriceV2', 'fixedValue', fixedValue);
      const localVarPath = `/api/V2/calculation/{source}/{carId}`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'carId'}}`, encodeURIComponent(String(carId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fixedVariable !== undefined) {
        localVarQueryParameter['fixedVariable'] = fixedVariable;
      }

      if (fixedValue !== undefined) {
        localVarQueryParameter['fixedValue'] = fixedValue;
      }

      if (fixedValueCurrency !== undefined) {
        localVarQueryParameter['fixedValueCurrency'] = fixedValueCurrency;
      }

      if (valuationCountry !== undefined) {
        localVarQueryParameter['valuationCountry'] = valuationCountry;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculationControllerGetExchangeRates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/calculation/exchange-rates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PriceCalculationControllerApi - functional programming interface
 * @export
 */
export const PriceCalculationControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PriceCalculationControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {PriceType} fixedVariable
     * @param {number} fixedValue
     * @param {CurrencyCode} [fixedValueCurrency]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async priceCalculationControllerCalculatePrice(
      source: SourceType,
      carId: string,
      fixedVariable: PriceType,
      fixedValue: number,
      fixedValueCurrency?: CurrencyCode,
      valuationCountry?: ValuationCountryCode,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceRegisterPotentialContainerDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.priceCalculationControllerCalculatePrice(
        source,
        carId,
        fixedVariable,
        fixedValue,
        fixedValueCurrency,
        valuationCountry,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {PriceType} fixedVariable
     * @param {number} fixedValue
     * @param {CurrencyCode} [fixedValueCurrency]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async priceCalculationControllerCalculatePriceV2(
      source: SourceType,
      carId: string,
      fixedVariable: PriceType,
      fixedValue: number,
      fixedValueCurrency?: CurrencyCode,
      valuationCountry?: ValuationCountryCode,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceRegisterPotentialDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.priceCalculationControllerCalculatePriceV2(
        source,
        carId,
        fixedVariable,
        fixedValue,
        fixedValueCurrency,
        valuationCountry,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async priceCalculationControllerGetExchangeRates(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: number } }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.priceCalculationControllerGetExchangeRates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PriceCalculationControllerApi - factory interface
 * @export
 */
export const PriceCalculationControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PriceCalculationControllerApiFp(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {PriceType} fixedVariable
     * @param {number} fixedValue
     * @param {CurrencyCode} [fixedValueCurrency]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    priceCalculationControllerCalculatePrice(
      source: SourceType,
      carId: string,
      fixedVariable: PriceType,
      fixedValue: number,
      fixedValueCurrency?: CurrencyCode,
      valuationCountry?: ValuationCountryCode,
      options?: any,
    ): AxiosPromise<SourceRegisterPotentialContainerDTO> {
      return localVarFp
        .priceCalculationControllerCalculatePrice(
          source,
          carId,
          fixedVariable,
          fixedValue,
          fixedValueCurrency,
          valuationCountry,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} carId
     * @param {PriceType} fixedVariable
     * @param {number} fixedValue
     * @param {CurrencyCode} [fixedValueCurrency]
     * @param {ValuationCountryCode} [valuationCountry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculationControllerCalculatePriceV2(
      source: SourceType,
      carId: string,
      fixedVariable: PriceType,
      fixedValue: number,
      fixedValueCurrency?: CurrencyCode,
      valuationCountry?: ValuationCountryCode,
      options?: any,
    ): AxiosPromise<SourceRegisterPotentialDTO> {
      return localVarFp
        .priceCalculationControllerCalculatePriceV2(
          source,
          carId,
          fixedVariable,
          fixedValue,
          fixedValueCurrency,
          valuationCountry,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    priceCalculationControllerGetExchangeRates(
      options?: any,
    ): AxiosPromise<{ [key: string]: { [key: string]: number } }> {
      return localVarFp.priceCalculationControllerGetExchangeRates(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * PriceCalculationControllerApi - object-oriented interface
 * @export
 * @class PriceCalculationControllerApi
 * @extends {BaseAPI}
 */
export class PriceCalculationControllerApi extends BaseAPI {
  /**
   *
   * @param {SourceType} source
   * @param {string} carId
   * @param {PriceType} fixedVariable
   * @param {number} fixedValue
   * @param {CurrencyCode} [fixedValueCurrency]
   * @param {ValuationCountryCode} [valuationCountry]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof PriceCalculationControllerApi
   */
  public priceCalculationControllerCalculatePrice(
    source: SourceType,
    carId: string,
    fixedVariable: PriceType,
    fixedValue: number,
    fixedValueCurrency?: CurrencyCode,
    valuationCountry?: ValuationCountryCode,
    options?: AxiosRequestConfig,
  ) {
    return PriceCalculationControllerApiFp(this.configuration)
      .priceCalculationControllerCalculatePrice(
        source,
        carId,
        fixedVariable,
        fixedValue,
        fixedValueCurrency,
        valuationCountry,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {string} carId
   * @param {PriceType} fixedVariable
   * @param {number} fixedValue
   * @param {CurrencyCode} [fixedValueCurrency]
   * @param {ValuationCountryCode} [valuationCountry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceCalculationControllerApi
   */
  public priceCalculationControllerCalculatePriceV2(
    source: SourceType,
    carId: string,
    fixedVariable: PriceType,
    fixedValue: number,
    fixedValueCurrency?: CurrencyCode,
    valuationCountry?: ValuationCountryCode,
    options?: AxiosRequestConfig,
  ) {
    return PriceCalculationControllerApiFp(this.configuration)
      .priceCalculationControllerCalculatePriceV2(
        source,
        carId,
        fixedVariable,
        fixedValue,
        fixedValueCurrency,
        valuationCountry,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceCalculationControllerApi
   */
  public priceCalculationControllerGetExchangeRates(options?: AxiosRequestConfig) {
    return PriceCalculationControllerApiFp(this.configuration)
      .priceCalculationControllerGetExchangeRates(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReportControllerApi - axios parameter creator
 * @export
 */
export const ReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} [carId]
     * @param {number} [monthsToDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportControllerGetSendingDealers: async (
      source: SourceType,
      carId?: string,
      monthsToDate?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('reportControllerGetSendingDealers', 'source', source);
      const localVarPath = `/api/bids/reports/sending-dealers/{source}`.replace(
        `{${'source'}}`,
        encodeURIComponent(String(source)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (carId !== undefined) {
        localVarQueryParameter['carId'] = carId;
      }

      if (monthsToDate !== undefined) {
        localVarQueryParameter['monthsToDate'] = monthsToDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} [carId]
     * @param {number} [monthsToDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportControllerGetSendingDealers(
      source: SourceType,
      carId?: string,
      monthsToDate?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricBidsReportDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetSendingDealers(
        source,
        carId,
        monthsToDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReportControllerApiFp(configuration);
  return {
    /**
     *
     * @param {SourceType} source
     * @param {string} [carId]
     * @param {number} [monthsToDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportControllerGetSendingDealers(
      source: SourceType,
      carId?: string,
      monthsToDate?: number,
      options?: any,
    ): AxiosPromise<HistoricBidsReportDTO> {
      return localVarFp
        .reportControllerGetSendingDealers(source, carId, monthsToDate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
  /**
   *
   * @param {SourceType} source
   * @param {string} [carId]
   * @param {number} [monthsToDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportControllerApi
   */
  public reportControllerGetSendingDealers(
    source: SourceType,
    carId?: string,
    monthsToDate?: number,
    options?: AxiosRequestConfig,
  ) {
    return ReportControllerApiFp(this.configuration)
      .reportControllerGetSendingDealers(source, carId, monthsToDate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SearchAgentControllerApi - axios parameter creator
 * @export
 */
export const SearchAgentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SearchAgentRequestDTO} searchAgentRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerCreateSearchAgent: async (
      searchAgentRequestDTO: SearchAgentRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchAgentRequestDTO' is not null or undefined
      assertParamExists('searchAgentControllerCreateSearchAgent', 'searchAgentRequestDTO', searchAgentRequestDTO);
      const localVarPath = `/api/cars/search-agent`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(searchAgentRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerDeleteSearchAgent: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('searchAgentControllerDeleteSearchAgent', 'id', id);
      const localVarPath = `/api/cars/search-agent/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalList: async (
      source: SourceType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('searchAgentControllerExternalList', 'source', source);
      const localVarPath = `/api/cars/search-agent/external/{source}`.replace(
        `{${'source'}}`,
        encodeURIComponent(String(source)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalListAdmin: async (
      source: SourceType,
      dealer: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('searchAgentControllerExternalListAdmin', 'source', source);
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('searchAgentControllerExternalListAdmin', 'dealer', dealer);
      const localVarPath = `/api/cars/search-agent/external/{source}/{dealer}`
        .replace(`{${'source'}}`, encodeURIComponent(String(source)))
        .replace(`{${'dealer'}}`, encodeURIComponent(String(dealer)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalUpsertSearchAgent: async (
      externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalSearchAgentWithNotificationDTO' is not null or undefined
      assertParamExists(
        'searchAgentControllerExternalUpsertSearchAgent',
        'externalSearchAgentWithNotificationDTO',
        externalSearchAgentWithNotificationDTO,
      );
      const localVarPath = `/api/cars/search-agent/external`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalSearchAgentWithNotificationDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealer
     * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalUpsertSearchAgentAdmin: async (
      dealer: string,
      externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealer' is not null or undefined
      assertParamExists('searchAgentControllerExternalUpsertSearchAgentAdmin', 'dealer', dealer);
      // verify required parameter 'externalSearchAgentWithNotificationDTO' is not null or undefined
      assertParamExists(
        'searchAgentControllerExternalUpsertSearchAgentAdmin',
        'externalSearchAgentWithNotificationDTO',
        externalSearchAgentWithNotificationDTO,
      );
      const localVarPath = `/api/cars/search-agent/external/{dealer}`.replace(
        `{${'dealer'}}`,
        encodeURIComponent(String(dealer)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        externalSearchAgentWithNotificationDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerListSearchAgent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/cars/search-agent`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {SearchAgentRequestDTO} searchAgentRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerUpdateSearchAgent: async (
      id: string,
      searchAgentRequestDTO: SearchAgentRequestDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('searchAgentControllerUpdateSearchAgent', 'id', id);
      // verify required parameter 'searchAgentRequestDTO' is not null or undefined
      assertParamExists('searchAgentControllerUpdateSearchAgent', 'searchAgentRequestDTO', searchAgentRequestDTO);
      const localVarPath = `/api/cars/search-agent/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(searchAgentRequestDTO, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchAgentControllerApi - functional programming interface
 * @export
 */
export const SearchAgentControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchAgentControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SearchAgentRequestDTO} searchAgentRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerCreateSearchAgent(
      searchAgentRequestDTO: SearchAgentRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerCreateSearchAgent(
        searchAgentRequestDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerDeleteSearchAgent(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerDeleteSearchAgent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerExternalList(
      source: SourceType,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalSearchAgentWithNotificationDTO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerExternalList(source, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerExternalListAdmin(
      source: SourceType,
      dealer: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalSearchAgentWithNotificationDTO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerExternalListAdmin(
        source,
        dealer,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerExternalUpsertSearchAgent(
      externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalSearchAgentWithNotificationDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerExternalUpsertSearchAgent(
        externalSearchAgentWithNotificationDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealer
     * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerExternalUpsertSearchAgentAdmin(
      dealer: string,
      externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalSearchAgentWithNotificationDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerExternalUpsertSearchAgentAdmin(
        dealer,
        externalSearchAgentWithNotificationDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerListSearchAgent(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchAgentDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerListSearchAgent(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {SearchAgentRequestDTO} searchAgentRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchAgentControllerUpdateSearchAgent(
      id: string,
      searchAgentRequestDTO: SearchAgentRequestDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentControllerUpdateSearchAgent(
        id,
        searchAgentRequestDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SearchAgentControllerApi - factory interface
 * @export
 */
export const SearchAgentControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SearchAgentControllerApiFp(configuration);
  return {
    /**
     *
     * @param {SearchAgentRequestDTO} searchAgentRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerCreateSearchAgent(
      searchAgentRequestDTO: SearchAgentRequestDTO,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .searchAgentControllerCreateSearchAgent(searchAgentRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerDeleteSearchAgent(id: string, options?: any): AxiosPromise<boolean> {
      return localVarFp.searchAgentControllerDeleteSearchAgent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalList(
      source: SourceType,
      options?: any,
    ): AxiosPromise<Array<ExternalSearchAgentWithNotificationDTO>> {
      return localVarFp.searchAgentControllerExternalList(source, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SourceType} source
     * @param {string} dealer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalListAdmin(
      source: SourceType,
      dealer: string,
      options?: any,
    ): AxiosPromise<Array<ExternalSearchAgentWithNotificationDTO>> {
      return localVarFp
        .searchAgentControllerExternalListAdmin(source, dealer, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalUpsertSearchAgent(
      externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
      options?: any,
    ): AxiosPromise<ExternalSearchAgentWithNotificationDTO> {
      return localVarFp
        .searchAgentControllerExternalUpsertSearchAgent(externalSearchAgentWithNotificationDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealer
     * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerExternalUpsertSearchAgentAdmin(
      dealer: string,
      externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
      options?: any,
    ): AxiosPromise<ExternalSearchAgentWithNotificationDTO> {
      return localVarFp
        .searchAgentControllerExternalUpsertSearchAgentAdmin(dealer, externalSearchAgentWithNotificationDTO, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerListSearchAgent(options?: any): AxiosPromise<Array<SearchAgentDTO>> {
      return localVarFp.searchAgentControllerListSearchAgent(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {SearchAgentRequestDTO} searchAgentRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchAgentControllerUpdateSearchAgent(
      id: string,
      searchAgentRequestDTO: SearchAgentRequestDTO,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .searchAgentControllerUpdateSearchAgent(id, searchAgentRequestDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SearchAgentControllerApi - object-oriented interface
 * @export
 * @class SearchAgentControllerApi
 * @extends {BaseAPI}
 */
export class SearchAgentControllerApi extends BaseAPI {
  /**
   *
   * @param {SearchAgentRequestDTO} searchAgentRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerCreateSearchAgent(
    searchAgentRequestDTO: SearchAgentRequestDTO,
    options?: AxiosRequestConfig,
  ) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerCreateSearchAgent(searchAgentRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerDeleteSearchAgent(id: string, options?: AxiosRequestConfig) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerDeleteSearchAgent(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerExternalList(source: SourceType, options?: AxiosRequestConfig) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerExternalList(source, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SourceType} source
   * @param {string} dealer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerExternalListAdmin(source: SourceType, dealer: string, options?: AxiosRequestConfig) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerExternalListAdmin(source, dealer, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerExternalUpsertSearchAgent(
    externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
    options?: AxiosRequestConfig,
  ) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerExternalUpsertSearchAgent(externalSearchAgentWithNotificationDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealer
   * @param {ExternalSearchAgentWithNotificationDTO} externalSearchAgentWithNotificationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerExternalUpsertSearchAgentAdmin(
    dealer: string,
    externalSearchAgentWithNotificationDTO: ExternalSearchAgentWithNotificationDTO,
    options?: AxiosRequestConfig,
  ) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerExternalUpsertSearchAgentAdmin(dealer, externalSearchAgentWithNotificationDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerListSearchAgent(options?: AxiosRequestConfig) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerListSearchAgent(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {SearchAgentRequestDTO} searchAgentRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchAgentControllerApi
   */
  public searchAgentControllerUpdateSearchAgent(
    id: string,
    searchAgentRequestDTO: SearchAgentRequestDTO,
    options?: AxiosRequestConfig,
  ) {
    return SearchAgentControllerApiFp(this.configuration)
      .searchAgentControllerUpdateSearchAgent(id, searchAgentRequestDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
